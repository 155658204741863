import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

export function useAuthenticateDashboard() {
  function checkToken(): boolean {
    let token = sessionStorage.getItem('accessToken');

    if (token === null || token === undefined) {
      return false;
    } else {
    let decodeToken: { groups: string[] } = jwtDecode(token as string);
      if (decodeToken.groups.includes('d11d1727-ed7f-48ca-af35-d02ff4cb60b8')) {
        return true;
      } else {
        return false;
      }
  }
  }
  return checkToken;
}
