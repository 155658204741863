import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Reporting.css";

const Top10Sales = () => {
  const [Sellers, setSellers] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/sales/Top10Sellers`,{
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`}})
      .then((res) => {
        console.log(res);
        setSellers(res.data.data);
      });
  }, []);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Sales</th>
          </tr>
        </thead>
        <tbody>
          {Sellers.map((x: any) => {
            return (
              <tr>
                <td>{x.clientName}</td>
                <td>{x.purchases}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Top10Sales;
