import { urlContacts } from '../../../endpoints';
import axios, { AxiosResponse } from 'axios';
import { clientDTO } from '../../../DTOs/clients.model';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/utils/Pagination/Pagination';
import React, { useEffect, useRef, useState } from 'react';
import './Contacts.css';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import Table from 'react-bootstrap/Table';
import ContactModel from '../ContactModel/ContactModel';
import { accessTokenRequest } from '../../../auth/authConfig';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import ContactNoteModel from '../ContactNoteModel/ContactNoteModal';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useContactList, useGetContacts } from '../../../context/ContactContext';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Button from 'react-bootstrap/Button';
import TableNumberSelect from '../../../components/common/TableNumberSelect/TableNumberSelect';

export default function Contacts() {
  let PageSize = 10;
  const [visibleForm, setVisibleForm] = useState(false);
  const [contactData, setContactData] = useState<any[]>([]);
  const [contactId, setContactId] = React.useState();
  const [open, setOpen] = useState(false);
  const [SortDialogopen, setSortDialogopen] = useState(false);
  const [totalAmountOfPages, setTotalAmountOfPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [PhoneNumber, SetPhoneNumber] = useState();
  const [ActiveSortBtnValue1, SetSortBtnValue1] = useState(false);
  const [ActiveSortBtnValue2, SetSortBtnValue2] = useState(false);
  const [ActiveSortBtnValue3, SetSortBtnValue3] = useState(false);
  const [ActiveSortBtnValue4, SetSortBtnValue4] = useState(false);
  const [ActiveSortBtnValue5, SetSortBtnValue5] = useState(false);
  const [ActiveSortBtnValue6, SetSortBtnValue6] = useState(false);
  const [ActiveSortBtnValue7, SetSortBtnValue7] = useState(false);
  const [render, setRender] = useState(0);
  const navigate = useNavigate();

  const [FilterArray, SetFilterArray] = useState([]);
  const [filterFirstName, SetfilterFirstName] = useState('');
  const [filterPhoneNumber, SetfilterPhoneNumber] = useState('');
  const [filterLastName, SetfilterLastName] = useState('');
  const [operator, SetOperator] = useState('OR');
  const [client, SetClient] = useState('');

  const [sortWith, SetSortWith] = useState('');
  const [sortBy, SetSortBy] = useState('dec');
  const [search, setSearch] = useState('');

  const [modalShow, setModalShow] = React.useState(false);

  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState(null);
  // const [accessToken, setAccessToken] = useState('');
  const [commentModalShow, setCommentModalShow] = useState(false);
  const [clear, SetClear] = useState(true);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastHeaderMessage, setToastHeaderMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const [modalTitle, setModalTitle] = React.useState('Add New Contact');
  let count = useRef(0);
  let buttonText = 'New Contact';

  const contactList = useContactList();
  // const getContacts: () => Promise<void> = useGetContacts();

  // useEffect(() => {
  //   getContacts();
  // }, [page, recordsPerPage, totalAmountOfPages, sortBy, sortWith, search]);

  function HandleRecordsPerPage(value: number) {
    setRecordsPerPage(value);
  }

  function handleToast(response: any) {
    switch (response) {
      case 'Success':
        setToastHeaderMessage('Success!');
        setToastMessage('Contact Saved Successfully');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Note Added':
        setToastHeaderMessage('Success!');
        setToastMessage('Note Saved Successfully');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Note Deleted':
        setToastHeaderMessage('Success!');
        setToastMessage('Note Deleted Successfully');
        setToastType('secondary');
        setToastShow(true);
        break;
      case 'Note Updated':
        setToastHeaderMessage('Success!');
        setToastMessage('Note Updated Successfully');
        setToastType('success');
        setToastShow(true);
        break;
      default:
        setToastHeaderMessage('Failed!');
        setToastMessage('An Error Occured.');
        setToastType('danger');
        setToastShow(true);
    }
  }

  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: [`${process.env.REACT_APP_API_PROTECTED_RESOURCE_API_SCOPES}`]
        })
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          // setAccessToken(accessToken);
          getContacts();
        })
        .then((response: any) => {
          setContactData(response.data.data.data);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              account: accounts[0],
              scopes: [`${process.env.REACT_APP_API_PROTECTED_RESOURCE_API_SCOPES}`]
            });
          }
          console.log(error);
        });
    }
  }, [
    instance,
    accounts,
    inProgress,
    apiData,
    page,
    recordsPerPage,
    totalAmountOfPages,
    sortBy,
    sortWith,
    search
  ]);

  const SearchPhoneNumber = (e: any) => {
    const value = e.target.value.replace(/\D/g, '');
    SetPhoneNumber(value);
  };

  function getContacts() {
    var params = {
      page,
      recordsPerPage,
      filters: '',
      sortBy: 'asc',
      sortWith: '',
      search: ''
    };
    if (FilterArray.length > 0) {
      params.filters = JSON.stringify(FilterArray);
    }
    if (sortWith !== '') {
      params.sortWith = sortWith;
    }
    if (search !== '') {
      params.search = search;
    }
    params.sortBy = sortBy;

    axios
      .get(urlContacts, {
        params: params,
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        const totalAmountOfRecords = response.data.data.totalRecords;
        setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
        setContactData(response.data.data.data);
        setRender((count.current = count.current + 1));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function deleteItem(id: any) {
    axios.delete(`${urlContacts}/${id}`).then((res) => {
      if (res.status === 204) {
        getContacts();
      }
    });
  }

  function editItem(id: any) {
    setModalShow(true);
    setModalTitle('Edit Contact');
    setContactId(id);
  }

  function GetFilterArray(key: string, value: string) {
    if (value !== '') {
      var isexist = FilterArray.find((x: any) => x.key === key);
      if (isexist !== undefined) {
        var fa: any = FilterArray.map((x: any) => {
          if (x.key === key) {
            x.value = value;
          }
          return x;
        });
        SetFilterArray(fa);
      } else {
        var obj = {
          key: key,
          value: value
        };
        SetFilterArray([...FilterArray, obj] as any);
      }
    } else {
      SetFilterArray(FilterArray.filter((x: any) => x.key !== key));
    }
  }

  function ApplyFilter() {
    setOpen(() => !open);
    getContacts();
    // SetfilterFirstName('');
    // SetfilterPhoneNumber('');
    // SetfilterLastName('');
    // SetClient('');
    // SetOperator('OR');
  }

  function ClearFilter() {
    SetFilterArray([]);
    SetfilterFirstName('');
    SetfilterPhoneNumber('');
    SetfilterLastName('');
    SetClient('');
    SetOperator('OR');
    setOpen(() => !open);
    axios
      .get(urlContacts, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        const totalAmountOfRecords = response.data.data.totalRecords;
        setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
        setContactData(response.data.data.data);
        setRender((count.current = count.current + 1));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleComment(id: any) {
    setContactId(id);
    setCommentModalShow(true);
  }

  function handleCommentModalShow(fromChild: boolean) {
    setCommentModalShow(fromChild);
  }

  useEffect(() => {
    if (
      FilterArray.length > 0 ||
      filterFirstName !== '' ||
      filterPhoneNumber !== '' ||
      filterLastName !== '' ||
      client !== ''
    ) {
      SetClear(false);
    } else {
      SetClear(true);
    }
  }, [FilterArray.length, open]);

  return (
    <>
      <div className='col-12'>
        <div className='row m-0'>
          <div>
            <div className='container-fluid'>
              <div className='py-4 heading'>Contacts </div>
              <div className='col-12 pt-4'>
                <div className='row'>
                  <div className='col-8'>
                    <div className='row'>
                      <div className='col-4'>
                        <div className='position-relative'>
                          <input
                            type='text'
                            className='form-control paddingLeft border-0'
                            placeholder='Search'
                            required
                            style={{ marginTop: '3%' }}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                          <svg
                            className='icon-input'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                          >
                            <path d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z' />
                          </svg>
                        </div>
                      </div>
                      <div
                        className='col-2 d-flex align-items-start'
                        style={{ position: 'relative', marginTop: '1%' }}
                      >
                        <>
                          <button
                            onClick={async () => {
                              setOpen(() => !open);
                              setSortDialogopen(() => false);
                            }}
                            aria-expanded={open}
                            style={{
                              backgroundColor: 'white',
                              padding: '2%',
                              color: 'black',
                              borderColor: 'white',
                              border: '0px'
                            }}
                          >
                            <div className='d-flex align-item-center'>
                              <img src='/assets/img/filter.svg' alt='Filter' />

                              <div>
                                <p className='px-2 mb-0'>Filters</p>
                              </div>
                              <div
                                style={{ marginLeft: '5px', marginTop: '3%' }}
                                className={open === true ? 'fa fa-caret-up' : 'fa fa-caret-down'}
                              ></div>
                            </div>
                          </button>
                          <div style={{ minHeight: '80px' }}>
                            <Collapse in={open} dimension='width'>
                              <div id='example-collapse-text'>
                                <Card
                                  body
                                  style={{
                                    width: '900px',
                                    borderRadius: '24px',
                                    position: 'absolute',
                                    boxShadow: '0px 0px 83px -48px black',
                                    top: '40%',
                                    left: '0%',
                                    zIndex: '1'
                                  }}
                                >
                                  <>
                                    <div className='container modal_form'>
                                      <div className='row' style={{ width: '100%' }}>
                                        <br />

                                        {/*---------------------------------- try new dropdown of filter ---------------------------------- */}

                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              First Name
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetfilterFirstName(e.target.value);
                                                GetFilterArray('first name', e.target.value);
                                              }}
                                              value={filterFirstName}
                                              className='form-select'
                                              aria-label='Default select example'
                                            >
                                              <option
                                                selected
                                                value=''
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {[
                                                ...new Set(
                                                  contactList?.map((value) => value.clientName)
                                                )
                                              ]
                                                .filter((firstName) => firstName !== null)
                                                .sort((a, b) => (a || '').localeCompare(b || ''))
                                                .map((clientName, index) => (
                                                  <option key={index}>
                                                    <div>{clientName}</div>
                                                  </option>
                                                ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Last Name
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetfilterLastName(e.target.value);
                                                GetFilterArray('last name', e.target.value);
                                              }}
                                              value={filterLastName}
                                              className='form-select'
                                              aria-label='Default select example'
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {[
                                                ...new Set(
                                                  contactList?.map((value) => value.lastName)
                                                )
                                              ]
                                                .filter((lastName) => lastName !== null)
                                                .sort((a, b) => (a || '').localeCompare(b || ''))
                                                .map((lastName, index) => (
                                                  <option key={index}>
                                                    <div>{lastName}</div>
                                                  </option>
                                                ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Client Name
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetClient(e.target.value);
                                                GetFilterArray('client id', e.target.value);
                                              }}
                                              value={client}
                                              className='form-select'
                                              aria-label='Default select example'
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {[
                                                ...new Set(
                                                  contactList?.map((value) => value.clientName)
                                                )
                                              ]
                                                .filter((clientName) => clientName !== null)
                                                .sort((a, b) => (a || '').localeCompare(b || ''))
                                                .map((clientName, index) => (
                                                  <option key={index}>
                                                    <div>{clientName}</div>
                                                  </option>
                                                ))}
                                            </select>
                                          </div>
                                        </div>
                                        {/* <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Phone Number
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetfilterPhoneNumber(e.target.value);
                                                GetFilterArray('phone number', e.target.value);
                                              }}
                                              value={filterPhoneNumber}
                                              className='form-select'
                                              aria-label='Default select example'
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {ContactData?.map((value, index) => (
                                                <option key={index}>{value.phoneNumber}</option>
                                              ))}
                                            </select>
                                          </div>
                                        </div> */}
                                      </div>
                                      <div
                                        className='row'
                                        style={{
                                          width: '100%',
                                          marginTop: '1.5%'
                                        }}
                                      >
                                        <br />

                                        {/*---------------------------------- 2nd row dropdown of filter ---------------------------------- */}
                                        {/* <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Modified Date
                                            </p>
                                            <input
                                              style={{
                                                width: '100%'
                                              }}
                                              type='date'
                                              className='form-control'
                                              placeholder='Spec-1'
                                            />
                                          </div>
                                        </div> */}
                                      </div>
                                      <div
                                        className='row'
                                        style={{
                                          width: '100%',
                                          marginTop: '1.5%'
                                        }}
                                      >
                                        <br />

                                        {/*---------------------------------- 3rd row dropdown of filter ---------------------------------- */}
                                        <div className='col-4'>
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Filter Type
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetOperator(e.target.value);
                                                GetFilterArray('operator', e.target.value);
                                              }}
                                              className='form-select'
                                              aria-label='Default select example'
                                              value={operator}
                                            >
                                              {/* <option
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                                value=''
                                              >
                                                None
                                              </option> */}
                                              <option value='And'>AND</option>
                                              <option value='OR'>OR</option>
                                            </select>
                                          </div>
                                          <br />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className='row justify-content-end'
                                      style={{ margin: '2% 1% 0 0' }}
                                    >
                                      <div className=' d-flex justify-content-end'>
                                        <ToggleButton
                                          id='toggle-clear'
                                          type='checkbox'
                                          variant='outline-secondary'
                                          checked={!clear}
                                          value='1'
                                          onClick={() => {
                                            ClearFilter();
                                          }}
                                          className='btn primary-bg-color-sp-secondary px-4 mx-4'
                                        >
                                          Clear
                                        </ToggleButton>
                                        <button
                                          type='button'
                                          onClick={() => setOpen(() => !open)}
                                          className='btn primary-bg-color-sp-ouline px-4 mx-4'
                                        >
                                          Close
                                        </button>
                                        <button
                                          type='button'
                                          onClick={() => ApplyFilter()}
                                          className='btn primary-bg-color-sp text-white'
                                        >
                                          Filters
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                </Card>
                              </div>
                            </Collapse>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='row justify-content-end'>
                      <div className='col-5 d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn primary-bg-color-sp text-white'
                          onClick={() => setModalShow(true)}
                        >
                          New Contact
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Table responsive bordered hover size='sm' className='shadow-none'>
                <thead className='shadow-none table-bordercontacts'>
                  <tr>
                    {/* <th>
                      <div className='d-flex justify-content-between'>
                        <div> Contact Id </div>
                        <div className='ShortButtonTH'>
                          <i
                            className='fa fa-solid fa-sort'
                            onClick={() => {
                              SetSortBtnValue1(() => !ActiveSortBtnValue1);
                              if (!ActiveSortBtnValue1) {
                                SetSortWith((pre) => 'contactId');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              } else {
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                                SetSortWith((pre) => 'contactId');
                              }
                            }}
                          ></i>
                        </div>
                      </div>
                    </th> */}
                    <th>
                      <div className='d-flex justify-content-between'>
                        <div> First Name</div>
                        {/* <div className='ShortButtonTH'>
                          <i
                            className='fa fa-solid fa-sort'
                            onClick={() => {
                              SetSortBtnValue2(() => !ActiveSortBtnValue2);
                              if (!ActiveSortBtnValue2) {
                                SetSortWith((pre) => 'firstName');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              } else {
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                                SetSortWith((pre) => 'firstName');
                              }
                            }}
                          ></i>
                        </div> */}
                      </div>
                    </th>
                    <th>
                      <div className='d-flex justify-content-between'>
                        <div> Last Name</div>
                        {/* <div className='ShortButtonTH'>
                          <i
                            className='fa fa-solid fa-sort'
                            onClick={() => {
                              SetSortBtnValue3(() => !ActiveSortBtnValue3);
                              if (!ActiveSortBtnValue3) {
                                SetSortWith((pre) => 'lastName');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              } else {
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                                SetSortWith((pre) => 'lastName');
                              }
                            }}
                          ></i>
                        </div> */}
                      </div>
                    </th>
                    <th>
                      <div className='d-flex justify-content-between'>
                        <div> Phone Number</div>
                        {/* <div className='ShortButtonTH'>
                          <i
                            className='fa fa-solid fa-sort'
                            onClick={() => {
                              SetSortBtnValue4(() => !ActiveSortBtnValue4);
                              if (!ActiveSortBtnValue4) {
                                SetSortWith((pre) => 'phoneNumber');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              } else {
                                SetSortWith((pre) => 'phoneNumber');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              }
                            }}
                          ></i>
                        </div> */}
                      </div>
                    </th>
                    <th>
                      <div className='d-flex justify-content-between'>
                        <div> Email</div>
                        {/* <div className='ShortButtonTH'>
                          <i
                            className='fa fa-solid fa-sort'
                            onClick={() => {
                              SetSortBtnValue5(() => !ActiveSortBtnValue5);
                              if (!ActiveSortBtnValue5) {
                                SetSortWith((pre) => 'email');

                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              } else {
                                SetSortWith((pre) => 'email');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              }
                            }}
                          ></i>
                        </div> */}
                      </div>
                    </th>
                    {/* <th>
                      <div className="d-flex justify-content-between">
                        <div> Contact Notes</div>
                        <div className='ShortButtonTH'>
                          <i
                            className='fa fa-solid fa-sort'
                            onClick={() => {
                              SetSortBtnValue6(() => !ActiveSortBtnValue6);
                              if (!ActiveSortBtnValue6) {
                                SetSortWith((pre) => 'contactNotes');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              } else {
                                SetSortWith((pre) => 'contactNotes');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              }
                            }}
                          ></i>
                        </div>
                      </div>
                    </th> */}
                    <th>
                      <div className='d-flex justify-content-between'>
                        <div> Client</div>
                        {/* <div className='ShortButtonTH'>
                          <i
                            className='fa fa-solid fa-sort'
                            onClick={() => {
                              SetSortBtnValue7(() => !ActiveSortBtnValue7);
                              if (!ActiveSortBtnValue7) {
                                SetSortWith((pre) => 'clientId');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              } else {
                                SetSortWith((pre) => 'clientId');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              }
                            }}
                          ></i>
                        </div> */}
                      </div>
                    </th>
                    {/* <th>Modified Date</th> */}
                    <th>Valid?</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className='table-bordercontacts'>
                  {contactData?.map((value: any, index: any) => (
                    <tr key={index}>
                      {/* <td>{value.contactId}</td> */}
                      <td className='align-middle px-1'>{value.firstName}</td>
                      <td className='align-middle px-1'>{value.lastName}</td>
                      <td className='align-middle px-1'>{value.phoneNumber}</td>
                      <td className='align-middle px-1'>{value.email}</td>
                      {/* <td>{value.contactNotes}</td> */}
                      <td className='align-middle px-1'>{value.clientName}</td>
                      <td className='align-middle px-1'>
                        {value.valid.toString() === 'true' ? 'Yes' : 'No'}
                      </td>
                      {/* <td>{new Date(value.modifiedDateTime).toDateString()}</td> */}
                      <td className='align-middle px-1'>
                        <span
                          // className='d-flex justify-content-center align-items-center my-1'
                          data-bs-placement='right'
                          title='Edit'
                          data-bs-toggle='tooltip'
                        >
                          <svg
                            role='button'
                            // className='action-btn action-btn-edit mx-2'
                            className='action-btn action-btn-edit mx-2'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                            onClick={() => editItem(value.contactId)}
                          >
                            <path d='M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z' />
                          </svg>
                        </span>
                        {/* <span data-bs-toggle='tooltip' data-bs-placement='right' title='Delete'>
                          <svg
                            role='button'
                            className='action-btn action-btn-delete'
                            onClick={() => deleteItem(value.contactId)}
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 448 512'
                          >
                            <path d='M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z' />
                          </svg>
                        </span> */}
                        <span data-bs-toggle='tooltip' data-bs-placement='right' title='Notes'>
                          <svg
                            role='button'
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 13.835 13.835'
                            onClick={() => handleComment(value.contactId)}
                          >
                            <g
                              id='Group_702'
                              data-name='Group 702'
                              transform='translate(-409 -429.164)'
                            >
                              <g
                                id='Group_702-2'
                                data-name='Group 702'
                                transform='translate(2 0.576)'
                              >
                                <path
                                  id='comment'
                                  d='M6.918,12.106a7.881,7.881,0,0,1-1.81-.216,7.693,7.693,0,0,1-.939.939,6.011,6.011,0,0,1-.811.588,3.471,3.471,0,0,1-.642.291,3,3,0,0,1-.412.115,1.259,1.259,0,0,1-.142.013,4.687,4.687,0,0,0,.405-1.682,3.619,3.619,0,0,0-.189-1.533A6.293,6.293,0,0,1,.635,8.579,5.334,5.334,0,0,1,.547,3.7,6.092,6.092,0,0,1,2.02,1.77,7.075,7.075,0,0,1,4.229.48a7.776,7.776,0,0,1,5.377,0,7.059,7.059,0,0,1,2.209,1.29A6.1,6.1,0,0,1,13.288,3.7a5.323,5.323,0,0,1,0,4.7,6.1,6.1,0,0,1-1.473,1.932,7.071,7.071,0,0,1-2.209,1.291A7.607,7.607,0,0,1,6.918,12.106Z'
                                  transform='translate(407 428.588)'
                                  fill='#2B9191'
                                />
                                <path
                                  id='plus-round'
                                  d='M9.4,6.718H7.605V4.924a.444.444,0,0,0-.887,0V6.718H4.924a.444.444,0,0,0,0,.887H6.718V9.4a.444.444,0,0,0,.887,0V7.605H9.4a.444.444,0,0,0,0-.887Z'
                                  transform='translate(406.756 427.603)'
                                  fill='#fff'
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                pageSize={PageSize}
                currentPage={page}
                totalAmountOfPages={totalAmountOfPages}
                onChange={(newPage) => setPage(newPage)}
              />
              <div className='col-12 d-flex align-items-center justify-content-end'>
                <TableNumberSelect handleRecordsPerPage={HandleRecordsPerPage} />
              </div>
            </div>
            <ContactModel
              name={modalTitle}
              show={modalShow}
              handleToast={handleToast}
              // accessToken={accessToken}
              contactId={contactId}
              onHide={() => {
                setModalShow(false);
                setModalTitle('Add New Contact');
                setContactId(undefined);
                getContacts();
              }}
            />

            <ContactNoteModel
              show={commentModalShow}
              handleToast={handleToast}
              handleCommentModalShow={handleCommentModalShow}
              contactId={contactId}
              onHide={() => {
                setCommentModalShow(false);
                // setContactId(undefined);
                getContacts();
              }}
            />

            <ToastContainer className='p-3' position='top-center'>
              <Toast
                bg={toastType}
                onClose={() => {
                  setToastShow(false);
                  setToastType('');
                  setToastHeaderMessage('');
                  setToastMessage('');
                }}
                show={toastShow}
                delay={3000}
                autohide
              >
                <Toast.Header closeButton={true}>
                  <strong className='me-auto'>{toastHeaderMessage}</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
              </Toast>
            </ToastContainer>
          </div>
        </div>
      </div>
    </>
  );
}
