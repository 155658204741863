import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

function TableNumberSelect(props: any) {
  const [recordsNumber, setRecordsNumber] = useState(10);

  const tableOptions = [10, 15, 25, 50].map((tableNumber) => ({
    value: tableNumber,
    label: tableNumber
  }));

  useEffect(() => {
    HandleRecordsPerPage();
  }, [recordsNumber]);

  function handleChange(selectedOption: any) {
    setRecordsNumber(selectedOption.value);
  }

  function HandleRecordsPerPage() {
    props.handleRecordsPerPage(recordsNumber);
  }

  return (
    <>
      <span className='d-flex mb-5 pb-5 align-items-center'>
        Records per Page
        <ReactSelect
          className='ms-1'
          options={tableOptions}
          value={tableOptions.find((option) => option.value === recordsNumber)}
          onChange={handleChange}
        />
      </span>
    </>
  );
}

export default TableNumberSelect;
