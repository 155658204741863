import react from 'react';
import { useEffect, useState } from 'react';

function useNewClientValidateEdit() {
  const [editValidated, setEditValidated] = useState(false);
  const [ClientNameValidated, setClientNameValidated] = useState(false);
  const [ClientManagerValidated, setClientManagerValidated] = useState(false);
  const [ClientAddressValidated, setClientAddressValidated] = useState(false);
  const [ClientStatusValidated, setClientStatusValidated] = useState(false);
  const [ClientStateValidated, setClientStateValidated] = useState(false);
  const [ClientCityValidated, setClientCityValidated] = useState(false);
  const [ClientZipValidated, setClientZipValidated] = useState(false);
  const [PoRequiredValidated, setPoRequiredValidated] = useState(false);
  const [ProposalDateValidated, setProposalDateValidated] = useState(false);
  const [ProposalAmountValidated, setProposalAmountValidated] = useState(false);

  function editValidate(field: any) {
    const inputField = document.querySelector(`#${field}`) as HTMLInputElement;
    const value = inputField?.value?.trim() || '';

    switch (field) {
      case 'clientName':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientNameValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientNameValidated(true);
        }
        break;

      case 'clientManager':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientManagerValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientManagerValidated(true);
        }
        break;

      case 'streetAddress':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientAddressValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientAddressValidated(true);
        }
        break;

      case 'city':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientCityValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientCityValidated(true);
        }
        break;

      case 'state':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientStateValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientStateValidated(true);
        }
        break;

      case 'zip':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientZipValidated(false);
        } else if (!/^\d{5}$/.test(value)) {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'Please enter a 5-digit Zip Code.';
          setClientZipValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientZipValidated(true);
        }
        break;

      case 'clientStatus':
        if (value === 'Select...') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientStatusValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientStatusValidated(true);
        }
        break;

      case 'poRequired':
        if (value === 'Select...') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setPoRequiredValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setPoRequiredValidated(true);
        }
        break;
    }
  }

  function EditValidateAll() {
    editValidate('clientName');
    editValidate('clientManager');
    editValidate('streetAddress');
    editValidate('city');
    editValidate('state');
    editValidate('zip');
    editValidate('clientStatus');
    editValidate('poRequired');
  }

  function editResetValidations() {
    setEditValidated(false);
    setClientNameValidated(false);
    setClientManagerValidated(false);
    setClientAddressValidated(false);
    setClientStateValidated(false);
    setClientCityValidated(false);
    setClientZipValidated(false);
    setClientStatusValidated(false);
    setPoRequiredValidated(false);
  }

  useEffect(() => {
    if (
      ClientNameValidated === true &&
      ClientManagerValidated === true &&
      ClientAddressValidated === true &&
      ClientStateValidated === true &&
      ClientCityValidated === true &&
      ClientZipValidated === true &&
      ClientStatusValidated === true &&
      PoRequiredValidated === true
    ) {
      setEditValidated(true);
    } else {
      setEditValidated(false);
    }
  }, [
    ClientNameValidated,
    ClientManagerValidated,
    ClientAddressValidated,
    ClientCityValidated,
    ClientStateValidated,
    ClientZipValidated,
    ClientStatusValidated,
    PoRequiredValidated
  ]);

  return { editValidated, EditValidateAll, editValidate, editResetValidations };
}

export default useNewClientValidateEdit;
