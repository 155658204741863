import React from 'react';
import ClientContextProvider from './ClientContext';
import UserContextProvider from './UserContext';
import ContactContextProvider from './ContactContext';

export default function AppContext({ children }: any) {
  return (
    <ContactContextProvider>
      <UserContextProvider>
        <ClientContextProvider>{children}</ClientContextProvider>
      </UserContextProvider>
    </ContactContextProvider>
  );
}
