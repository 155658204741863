function useExclusionList() {
  const disallowedDisplayNames: any[] = [
    'New Jersey  Reception',
    'ADP Learning',
    'Annie.Barlond-Arrchie',
    'ARC',
    'Asset Management',
    'AV Room Guest',
    'Business',
    'Cisco LDAP',
    'Clare Dus',
    'Conference : 908-376-7094 - 1186',
    'Conference : 908-376-7090 - 5186',
    'Conference : 908-376-7091 - 4186',
    'Conference : 908-376-7092 - 3186',
    'Conference : 908-376-7093 - 2186',
    'Connection UMService',
    'ConsultantEvals',
    'Consumer Scheduling',
    'Consumer Testing',
    'Data Management',
    'Haller, Dana',
    'Derek Reed',
    'Rose, Joanne',
    'Mink, Patricia',
    'Russell Hopkins',
    'Discrimination Testing',
    'Discovery Center',
    'DiscoverySearchMailbox {D919BA05-46A6-415f-80AD-7E09334BB852}',
    'eileenic35@aol.com',
    'Eileen Irving',
    'Erica Larousso',
    'Hiral Patel',
    'Grady, Jennifer',
    'Graham, Mark',
    'Susan-Kellogg',
    'Smith, Zachary',
    'Joey Lu',
    'Exchange Admin',
    'FederatedEmail.4c1f4d8b-8179-4148-93bf-00a95fa1e042',
    'FS User',
    'Ganapathykrishnan, Ragul - Contractor {PEP}',
    'historybee',
    'IL Food Panel Room',
    'Adams, Julie K (Battle Creek)',
    'HR',
    'HR Scans',
    'IT-DG',
    'NC - Associate',
    'NC - Design Center',
    'NC - Observation',
    'NC - Focus Group Room',
    'NC - Food Scheduling',
    'NC Food Scheduling',
    'NC Kitchen',
    'NC Loaner',
    'NC - PCP Room',
    'NJ - Client Lounge #1',
    'NJ - Client Lounge #2',
    'NJ - Consumer Training Room',
    'North Carolina Reception',
    'North Carolina Temporary',
    'NJ ADP User',
    'NC Associate',
    'NJ - Consumer Testing Room',
    'NJ Data Station',
    'NJ - Event Room',
    'NJ - Focus Group Room #1',
    'NJ - Focus Group Room #2',
    'NJ - Food Panel Room',
    'NJ Food Panel Room',
    'NJ - Library',
    'NJ Loaner',
    'NJ - Observation Room',
    'NJ - PCP Room',
    'NJ - Odor Booths',
    'NJ Food Scheduling',
    'NJ - Projector',
    'NJ - Prep Kitchen',
    'NJ - Staging Kitchen',
    'NJ - Sink Room',
    'NJ Shipping & Receiving',
    'Jobs',
    'john@GlobalGrange.world',
    'Josh',
    'lindadandorph@gmail.com',
    'lgtb@aol.com',
    'Live Stream',
    'Meet with me',
    'michelewaldron@aol.com',
    'Michigan Stats',
    'MS OOO Schedule',
    'NC Chiproom',
    'On-Premises Directory Synchronization Service Account',
    'Payments',
    'Personal Care Scheduling',
    'Personnel Schedule',
    'Sales Team',
    'sauciea@gmail.com',
    'Samuel.Remis',
    'scan2email',
    'Sensory Spectrum',
    'Sensory Spectrum Consulting',
    'Sensory Dev',
    'slcgannon@gmail.com',
    'Spectrum',
    'Sensory Spectrum New Jersey',
    'Sensory Spectrum North Carolina',
    'Spectrum Shares-NJ',
    'ss',
    'stestuser',
    'Support Services',
    'Susan Spectrum',
    'SystemMailbox{1f05a927-668a-4c84-a46c-36faff205d28}',
    'SystemMailbox{e0dc1c29-89c3-4034-b678-e6c29d823ed9}',
    'Test User',
    'Valhalla Panel Scheduling',
    'warnjh@aol.com',
    'XLSTAT User',
    'AG1',
    'amkettles@aol.com',
    'amyfiorilla@gmail.com',
    'caspough2020@gmail.com',
    'cheron99@yahoo.com',
    'cwccannon@gmail.com',
    'gazdal.k@gmail.com',
    'hdb1111@yahoo.com',
    'jnawrocki704@icloud.com',
    'judlee29@hotmail.com',
    'kapkhan@yahoo.com',
    'krichline@aol.com',
    'apfiorilla@optonline.net',
    'kristinstewart13@gmail.com',
    'mrjohnsancho84@gmail.com',
    'maa0597@gmail.com',
    'pantherc65@gmail.com',
    'pantherschick75@gmail.com',
    'perry.brown@gmail.com',
    'nikkibcodes',
    'NJ ChromeBooks',
    'nataliestoer',
    'sandi_singer@msn.com',
    'sweete77@hotmail.com',
    'yllkac921@gmail.com',
    'Emergency - IT Service',
    'Sensory Spectrum Accreditation'
  ];

  return disallowedDisplayNames;
}
export default useExclusionList;
