import { useEffect, useRef } from 'react';

export default function useTimeout(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => savedCallback.current?.();

    const id = setTimeout(handler, delay);

    return () => clearTimeout(id);
  }, [delay]);
}
