import react from 'react';
import { useEffect, useState } from 'react';
import { set } from 'react-hook-form';

function useNewContactValidateAll() {
  const [validated, setValidated] = useState(false);
  const [FirstNameValidated, setFirstNameValidated] = useState(false);
  const [LastNameValidated, setLastNameValidated] = useState(false);
  const [PhoneNumberValidated, setPhoneNumberValidated] = useState(false);
  const [EmailValidated, setEmailValidated] = useState(false);
  const [ContactNotesValidated, setContactNotesValidated] = useState(false);
  const [ClientIdValidated, setClientIdValidated] = useState(false);

  function validate(field: any) {
    const inputField = document.querySelector(`#${field}`) as HTMLInputElement;
    const value = inputField?.value?.trim() || '';

    switch (field) {
      case 'FirstName':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
        } else {
          setFirstNameValidated(false);
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setFirstNameValidated(true);
        }
        break;

      case 'LastName':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setLastNameValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setLastNameValidated(true);
        }
        break;

      case 'PhoneNumber':
        const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

        if (value === '') {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setPhoneNumberValidated(true);
          break;
          // inputField.classList.add('invalid');
          // inputField.nextElementSibling!.textContent = 'This field is required.';
          // setPhoneNumberValidated(false);
        } else if (!phoneRegex.test(value)) {
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent =
            'Please enter a valid phone number in the format 123-456-7890.';
          setPhoneNumberValidated(false);
          // } else if (phoneRegex.test(value)) {
          //   inputField.classList.remove('invalid');
          //   inputField.nextElementSibling!.textContent = '';
          //   setPhoneNumberValidated(true);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setPhoneNumberValidated(true);
        }

        break;

      case 'email':
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setEmailValidated(false);
        } else if (!emailPattern.test(value)) {
          //inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'Please enter a valid email address.';
          setEmailValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setEmailValidated(true);
        }
        break;

      // case 'ContactNotes':
      //   if (value === '') {
      //     inputField.reportValidity();
      //     inputField.classList.add('invalid');
      //     inputField.nextElementSibling!.textContent = 'This field is required.';
      //     setContactNotesValidated(false);
      //   } else {
      //     inputField.classList.remove('invalid');
      //     inputField.nextElementSibling!.textContent = '';
      //     setContactNotesValidated(true);
      //   }
      //   break;

      case 'ClientId':
        if (value === 'Select...') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientIdValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientIdValidated(true);
        }
        break;
    }
  }

  function ValidateAll() {
    validate('FirstName');
    validate('LastName');
    validate('PhoneNumber');
    validate('email');
    // validate('ContactNotes');
    validate('ClientId');
  }

  function resetValidations() {
    setValidated(false);
    setFirstNameValidated(false);
    setLastNameValidated(false);
    setPhoneNumberValidated(false);
    setEmailValidated(false);
    // setContactNotesValidated(false);
    setClientIdValidated(false);
  }

  useEffect(() => {
    if (
      FirstNameValidated === true &&
      LastNameValidated === true &&
      PhoneNumberValidated === true &&
      EmailValidated === true &&
      // ContactNotesValidated === true &&
      ClientIdValidated === true
    ) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }, [
    FirstNameValidated,
    LastNameValidated,
    PhoneNumberValidated,
    EmailValidated,
    // ContactNotesValidated,
    ClientIdValidated
  ]);

  return { validated, ValidateAll, validate, resetValidations };
}

export default useNewContactValidateAll;
