import axios from 'axios';
import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyInput from 'react-currency-input-field';
import InvoiceConfirmModal from '../InvoiceConfirmModal/InvoiceConfirmModal';
import { urlClients, urlProjects, urlSales } from '../../../endpoints';
import { set } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { send } from 'process';
import css from './sampleModel.module.css';

function SampleModel(props: any) {
  const [show, setShow] = useState<boolean>(false);
  const [showAddPOButton, setShowAddPOButton] = useState<boolean>(true);
  const [showAddInvoiceSection, setShowAddInvoiceSection] = useState<boolean>(false);
  const [poRequired, setPORequired] = useState<boolean>(false);
  const [sampleList, setSampleList] = useState<any[]>([]);
  const [purchaseOrderList, setPurchaseOrderList] = useState<any[]>([]);
  const [invoiceList, setInvoiceList] = useState<any[]>([]);
  const [clientList, setClientList] = useState<any[]>([]);
  const [poNumber, setPoNumber] = useState(0);
  const [poID, setPoID] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editingAmount, setEditingAmount] = useState(0);
  const [editingInvoiceDate, setEditingInvoiceDate] = useState('');
  const [inputValue, setInputValue] = useState('' as any);
  const [total, setTotal] = useState(0);
  const [amountError, setAmountError] = useState(false);
  const [render, setRender] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState([] as any);
  const [responseReceived, setResponseReceived] = useState(false);
  const [sampleModel, setSampleModel] = useState({
    Amount: '' as any,
    Description: '',
    ProjectId: 0,
    Id: 0,
    PoNumber: '',
    PurchaseOrderId: 0,
    InvoiceNumber: 0,
    InvoiceId: 0,
    InvoiceDate: '',
    IsInvoiced: false
  });
  const [response, setResponse] = useState({
    status: false,
    message: ''
  });
  const currencyInputRef = useRef<HTMLInputElement>(null);
  const currentItemIdRef = useRef() as any;
  const checkBoxRef = useRef() as any;
  const invoiceRecordRef = useRef() as any;

  function setShowConfirm(showFromChild: boolean) {
    props.handleSampleClose(showFromChild);
  }

  function handleInvoiceConfirmModalShow(fromChild: boolean) {
    props.handleInvoiceConfirmModalShow(fromChild);
    if (isEditing === true) {
      setIsEditing(false);
      setEditingAmount(0);
      setEditingInvoiceDate('');
      setRender(render + 1);
    }
  }

  function handleSample() {
    props.handleSample(props.project);
  }

  function handleSampleModalShow(fromChild: boolean) {
    props.handleSampleModalShow(fromChild);
  }

  function handleInvoiceEmail(invoiceAmount: number) {
    if (checkBoxRef.current === true) {
      props.handleInvoiceEmail(true, invoiceAmount);
    }
  }

  function handleCancel() {
    props.onHide();
    props.handleSampleClose(false);
    // setSampleList([]); <-- this was causing a flash of the modal when cancelled
    const x = { ...sampleModel };
    x.Amount = '';
    x.Description = '';
    x.Id = 0;
    x.ProjectId = 0;
    x.PurchaseOrderId = 0;
    x.InvoiceNumber = 0;
    x.InvoiceId = 0;
    x.IsInvoiced = false;
    x.InvoiceDate = '';
    setSampleModel(x);
    if (isEditing === true) {
      setIsEditing(false);
      setEditingAmount(0);
      setEditingInvoiceDate('');
    }
  }

  const editItem = (id: number) => {
    var billing = sampleList.find((x) => x.id === id);
    const c = { ...sampleModel };
    c.Amount = billing.amount;
    c.Description = billing.description;
    c.Id = billing.id;
    c.InvoiceDate = billing.date;
    // c.ProjectId = billing.projectId;
    // c.InvoiceNumber = billing.invoiceNumber;
    setEditingAmount(billing.amount);
    setSampleModel(c);
    setIsEditing(true);
  };

  const editInvoice = (id: number) => {
    var billing = sampleList.find((x) => x.invoiceId === id);
    if (billing.poNumber !== null) {
      const c = { ...sampleModel };
      c.Amount = billing.amount;
      c.Description = billing.description;
      c.Id = billing.id;
      c.InvoiceNumber = billing.invoiceNumber;
      c.PoNumber = billing.poNumber;
      c.InvoiceId = billing.invoiceId;
      c.InvoiceDate = billing.invoiceDate;
      // c.ProjectId = billing.projectId;
      // c.InvoiceNumber = billing.invoiceNumber;
      setEditingInvoiceDate(billing.invoiceDate);
      setEditingAmount(billing.amount);
      setSampleModel(c);
    } else {
      const c = { ...sampleModel };
      c.Amount = billing.amount;
      c.Description = billing.description;
      c.Id = billing.id;
      c.InvoiceNumber = billing.invoiceNumber;
      c.PoNumber = `${'Not Required'}`;
      c.InvoiceId = billing.invoiceId;
      c.InvoiceDate = billing.invoiceDate;
      // c.ProjectId = billing.projectId;
      // c.InvoiceNumber = billing.invoiceNumber;
      setEditingInvoiceDate(billing.invoiceDate);
      setEditingAmount(billing.amount);
      setSampleModel(c);
    }

    setIsEditing(true);
    setShowAddInvoiceSection(true);
  };

  const editPO = (id: number) => {
    var billing = purchaseOrderList.find((x) => x.purchaseOrderId === id);
    const c = { ...sampleModel };
    c.Amount = billing.amount;
    c.Description = billing.description;
    c.ProjectId = billing.projectId;
    c.PurchaseOrderId = billing.purchaseOrderId;
    //c.InvoiceNumber = billing.invoiceNumber;
    c.PoNumber = billing.poNumber;
    setEditingAmount(billing.amount);
    setSampleModel(c);
    setIsEditing(true);
    setShowAddPOButton(false);
  };

  const deleteItem = (id: number) => {
    // console.log(currentItemIdRef.current);
    axios
      .delete(`${urlProjects}/DeleteInvoice?id=${currentItemIdRef.current}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then((res) => {
        // getSamplesByProjectId();
        props.handleToast(res.data.message);
      });
  };

  function validateValue(value: any) {
    setAmountError(false);
    const valueisNaN = isNaN(value);
    let updatedValue = value;
    if (valueisNaN) {
      updatedValue = '0.00';
      setAmountError(false);
    }
    if (parseFloat(updatedValue) > 0 || updatedValue !== '') {
      const x = { ...sampleModel };
      if (
        parseFloat(updatedValue) + total - (isEditing ? editingAmount : 0) <=
        props.proposalAmount
      ) {
        x.Amount = updatedValue;
        setSampleModel(x);
      } else {
        const x = { ...sampleModel };
        x.Amount = updatedValue;
        setSampleModel(x);
        setAmountError(true);
      }
    } else {
      updatedValue = '';
      const x = { ...sampleModel };
      x.Amount = updatedValue === '';

      setSampleModel(x);
    }
  }

  useEffect(() => {
    let tempArray = [] as number[];
    invoiceList.map((item: any) => {
      if (item.isInvoiced) {
        tempArray.push(item.amount);
        let sum = tempArray.reduce((a: any, b: any) => {
          return a + b;
        });
        //console.log(`sum: ${sum}`);
        setTotalInvoice(sum);
        // getProjectInvoices();
      }
      if (tempArray.length === 0) {
        setTotalInvoice(0);
      }
    });
  }, [invoiceList]);

  useEffect(() => {
    getProjectInvoices();
  }, [render]);

  useLayoutEffect(() => {
    if (props.projectId !== 0) {
      const c = { ...sampleModel };
      c.ProjectId = props.projectId;
      setSampleModel(c);
      getSamplesByProjectId();
      getProjectPurchaseOrders();
      getProjectInvoices();
    }
    return () => {
      setAmountError(false);
    };
  }, [props]);

  function getProjectPurchaseOrders() {
    axios
      .get(`${urlProjects}/GetProjectPurchaseOrders/${props.projectId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then((response) => {
        if (props.show === true) {
          setResponseReceived(true);
        } else {
          setResponseReceived(false);
        }
        if (response.data.data.length > 0) {
          var t = 0;
          response.data.data.forEach((x: any) => (t += x.amount));
          // setTotal(parseFloat(t.toFixed(2)));
          setPurchaseOrderList(response.data.data);
        } else {
          setPurchaseOrderList([]);
          // setTotal(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getProjectInvoices() {
    axios
      .get(`${urlProjects}/GetProjectInvoices/${props.projectId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then((response) => {
        if (props.show === true) {
          setResponseReceived(true);
        } else {
          setResponseReceived(false);
        }
        if (response.data.data.length > 0) {
          var t = 0;
          response.data.data.forEach((x: any) => (t += x.amount));
          // setTotal(parseFloat(t.toFixed(2)));
          setInvoiceList(response.data.data);
        } else {
          setInvoiceList([]);
          // setTotal(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getSamplesByProjectId() {
    if (props.projectId === undefined) {
      return;
    }
    axios
      // .get(`${urlSales}/GetBillingSample/${props.billingId}`, {
      .get(`${urlProjects}/GetProjectInvoices/${props.projectId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then((response) => {
        if (props.show === true) {
          setResponseReceived(true);
        } else {
          setResponseReceived(false);
        }
        if (response.data.data.length > 0) {
          var t = 0;
          response.data.data.forEach((x: any) => (t += x.amount));
          setTotal(parseFloat(t.toFixed(2)));
          setSampleList(response.data.data);
        } else {
          setSampleList([]);
          setTotal(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function submitPO() {
    var payload = JSON.stringify(sampleModel);
    if (isEditing) {
      axios
        .put(`${urlProjects}/UpdatePO`, payload, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          getProjectPurchaseOrders();
          if (res.data === 'POUpdateSuccess') {
            const c = { ...sampleModel };
            c.Amount = '';
            c.Description = '';
            c.ProjectId = 0;
            c.PoNumber = '';
            c.Id = 0;
            c.InvoiceNumber = 0;
            c.InvoiceId = 0;
            setSampleModel(c);
            getSamplesByProjectId();
            getProjectPurchaseOrders();
            // props.onHide();
            props.handleToast(res.data);
          } else {
            const c = { ...response };
            c.status = res.data.status;
            c.message = res.data.message;
            setResponse(c);
          }
          getSamplesByProjectId();
          // props.handleSampleClose(true);
          setShowAddPOButton(true);
          cleanUp();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .post(`${urlProjects}/AddPurchaseOrder`, payload, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          if (res.data.status) {
            const c = { ...sampleModel };
            c.Amount = '';
            c.Description = '';
            c.ProjectId = 0;
            c.PoNumber = '';
            c.Id = 0;
            c.InvoiceNumber = 0;
            c.InvoiceId = 0;
            setSampleModel(c);
            getSamplesByProjectId();
            getProjectPurchaseOrders();
            // props.onHide();
            props.handleToast(res.data.message);
          } else {
            const c = { ...response };
            c.status = res.data.status;
            c.message = res.data.message;
            setResponse(c);
          }
          getSamplesByProjectId();
          // props.handleSampleClose(true);
          setShowAddPOButton(true);
          cleanUp();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  function submit() {
    var payload = JSON.stringify(sampleModel);
    if (isEditing) {
      axios
        .put(`${urlProjects}/UpdateInvoice`, payload, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          if (res.data.message === 'InvoiceUpdateSuccess') {
            const c = { ...sampleModel };
            c.Amount = '';
            c.Description = '';
            c.ProjectId = 0;
            c.PoNumber = '';
            c.Id = 0;
            c.PurchaseOrderId = 0;
            c.InvoiceNumber = 0;
            c.InvoiceId = 0;
            c.InvoiceDate = '';
            setSampleModel(c);
            getSamplesByProjectId();
            // props.onHide();
            props.handleToast(res.data.message);
          } else {
            const c = { ...response };
            c.status = res.data.status;
            c.message = res.data.message;
            setResponse(c);
            // props.handleSampleClose(true);
            props.handleToast(res.data);
          }
          getSamplesByProjectId();
          // props.handleSampleClose(true);
          setShowAddInvoiceSection(false);
          cleanUp();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .post(`${urlProjects}/AddInvoice`, payload, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          if (res.data.message === 'InvoiceSuccess') {
            const c = { ...sampleModel };
            c.Amount = '';
            c.Description = '';
            c.ProjectId = 0;
            c.PoNumber = '';
            c.Id = 0;
            c.PurchaseOrderId = 0;
            c.InvoiceNumber = 0;
            c.InvoiceId = 0;
            c.InvoiceDate = '';
            setSampleModel(c);
            getSamplesByProjectId();
            // props.onHide();
            props.handleToast(res.data.message);
          } else {
            const c = { ...response };
            c.status = res.data.status;
            c.message = res.data.message;
            setResponse(c);
          }
          getSamplesByProjectId();
          // props.handleSampleClose(true);
          cleanUp();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function sendInvoice(
    invoiceId: number,
    isInvoiced: boolean,
    amount: number,
    invoiceNumber: number,
    poNumber: number,
    description: string,
    invoiceDate: Date
  ) {
    const payload = {
      InvoiceId: invoiceId,
      IsInvoiced: isInvoiced,
      Amount: amount,
      InvoiceNumber: invoiceNumber,
      PoNumber: poNumber,
      Description: description,
      InvoiceDate: invoiceDate
    };
    // var payload = JSON.stringify(sampleModel);
    axios
      .put(`${urlProjects}/UpdateInvoice`, payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        if (res.data.status) {
          const c = { ...sampleModel };
          c.Amount = '';
          c.Description = '';
          c.ProjectId = 0;
          c.PoNumber = '';
          c.Id = 0;
          c.PurchaseOrderId = 0;
          c.InvoiceNumber = 0;
          c.InvoiceId = 0;
          c.IsInvoiced = false;
          c.InvoiceDate = '';
          setSampleModel(c);
          getSamplesByProjectId();
          // props.onHide();
        } else {
          const c = { ...response };
          c.status = res.data.status;
          c.message = res.data.message;
          setResponse(c);
        }
        getSamplesByProjectId();
        // props.handleSampleClose(true);
        cleanUp();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function cleanUp() {
    const c = { ...sampleModel };
    c.Amount = '';
    c.Description = '';
    c.PoNumber = '';
    c.Id = 0;
    c.ProjectId = 0;
    c.PurchaseOrderId = 0;
    c.InvoiceNumber = 0;
    c.InvoiceId = 0;
    c.InvoiceDate = '';
    setSampleModel(c);
    setRender(render + 1);
    if (isEditing === true) {
      setIsEditing(false);
      setEditingAmount(0);
      setEditingInvoiceDate('');
    }
    setResponseReceived(false);
  }

  return (
    <>
      {/* {responseReceived ? ( */}
      <Modal
        {...props}
        size='xl'
        dialogClassName='width-90vw'
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static'
        keyboard={false}
        centered
      >
        <Modal.Header className='primary-bg-color-sp text-white'>
          <Modal.Title id='contained-modal-title-vcenter'>Billing Details</Modal.Title>
          {/* <h4 className='m-0'>{`Total: ${totalInvoice.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })}`}</h4> */}
          <h4 className='m-0'>{`Total Billed: ${
            props.project.totalBilled === undefined
              ? ''
              : props.project.totalBilled.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })
          }`}</h4>
        </Modal.Header>
        <Modal.Body>
          {showAddPOButton ? null : (
            <div className='col-12'>
              <div className='row'>
                <div className='col-lg-10 col-lg-12'>
                  <div className='mb-10'>
                    <h5 className='mb-3'>Add Purchase Order</h5>
                    <div style={{ border: '2px solid red ', padding: '8px' }}>
                      <Table responsive bordered hover size='sm'>
                        <thead>
                          <tr>
                            <th style={{ width: '20%' }}>PO#</th>
                            <th>PO Description</th>
                            <th>PO Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {' '}
                              {/* <label htmlFor='purchaseOrderNo' className='form-label label-font'>
                                  PO#
                                </label> */}
                              <input
                                title='purchaseOrderNo'
                                type='text'
                                // formNoValidate={true}
                                className='form-control'
                                id='purchaseOrderNo'
                                name='purchaseOrderNo'
                                placeholder={isEditing ? poNumber.toString() : ''}
                                onChange={(e) => {
                                  const x = { ...sampleModel };
                                  x.PoNumber = e.target.value;
                                  setSampleModel(x);
                                }}
                              />
                              <div className='error'></div>
                            </td>
                            <td>
                              {' '}
                              <input
                                title='PO description'
                                type='text'
                                className='form-control'
                                id='POdescription'
                                name='POdescription'
                                placeholder={isEditing ? sampleModel.Description : ''}
                                onChange={(e) => {
                                  const x = { ...sampleModel };
                                  x.Description = e.target.value;
                                  setSampleModel(x);
                                }}
                                // value={sampleModel.Description}
                                // onChange={(e) => {
                                //   const x = { ...sampleModel };
                                //   x.Description = e.target.value;
                                //   setSampleModel(x);
                                // }}
                              />
                            </td>
                            <td>
                              {' '}
                              <CurrencyInput
                                id='amount'
                                // ref={currencyInputRef}
                                placeholder={
                                  isEditing
                                    ? `$${parseFloat(sampleModel.Amount).toFixed(2)}`
                                    : '$0.00'
                                }
                                allowDecimals={true}
                                maxLength={9}
                                decimalsLimit={2}
                                className='form-control'
                                onValueChange={validateValue}
                                prefix={'$'}
                                step={100}
                                onBlur={(e) => {
                                  if (e.target.value === '') {
                                    setAmountError(false);
                                    e.target.placeholder = '$0.00';
                                  }
                                }}
                              />
                              {amountError && (
                                <span style={{ color: 'red', fontSize: '13px' }}>
                                  Warning: Total Charges exceeds Proposal Amount. Contact Project
                                  Manager to update Proposal Amount.
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Modal.Footer> */}
              <div className='d-flex justify-content-end'>
                <Button
                  //className='secondary-color-sp bg-light btn-click-cancel px-4 m-1'
                  className='mx-1 px-4 h-100 align-self-center'
                  variant='outline-secondary'
                  onClick={() => {
                    setShowAddPOButton(true);
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button className='primary-bg-color-sp btn-click px-4 m-1' onClick={submitPO}>
                  Save
                </Button>
              </div>
            </div>
          )}
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-10 col-lg-12'>
                <div className='mb-10'>
                  <div className='d-flex justify-content-end'>
                    {showAddPOButton ? (
                      <div className='d-flex w-100 justify-content-between align-items-center'>
                        <h5>Purchase Orders</h5>
                        <Button
                          data-bs-toggle='tooltip'
                          title='Add PO'
                          className='primary-bg-color-sp btn-click px-4 m-1'
                          onClick={() => setShowAddPOButton(false)}
                        >
                          +
                        </Button>
                      </div>
                    ) : null}
                  </div>
                  {showAddPOButton ? (
                    <Table responsive bordered hover size='sm mt-1'>
                      <thead>
                        <tr>
                          <th className='text-center' style={{ width: '20%' }}>
                            PO#
                          </th>
                          <th className='text-center'>PO Description</th>
                          <th className='text-center'>PO Amount</th>
                          <th className='text-center'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseOrderList.map((value, index) => (
                          <tr key={index}>
                            <td className='text-center'>{value.poNumber}</td>
                            <td className='text-center'>{value.description}</td>
                            <td className='text-center'>
                              {value.amount !== null
                                ? value.amount.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                  })
                                : ''}
                            </td>
                            <td className='d-flex justify-content-center align-items-center'>
                              <span data-bs-toggle='tooltip' title='Add Charge'>
                                <Button
                                  size={'sm'}
                                  data-bs-toggle='tooltip'
                                  title='Add Charge'
                                  style={{ width: '30.33px', height: '30.33px' }}
                                  className='primary-bg-color-sp mx-1'
                                  onClick={() => {
                                    setShowAddInvoiceSection(true);
                                    setPoNumber(value.poNumber);
                                    // setPoID(value.purchaseOrderId);
                                    const x = { ...sampleModel };
                                    x.InvoiceNumber = value.invoiceNumber;
                                    x.PoNumber = value.poNumber;
                                    // if (invoiceRequired!) {
                                    //   x.PurchaseOrderId = value.purchaseOrderId;
                                    // } else {
                                    //   x.PurchaseOrderId = 0;
                                    // }

                                    setPORequired(true);
                                    setSampleModel(x);
                                  }}
                                >
                                  +
                                </Button>
                              </span>
                              <span
                                data-bs-toggle='tooltip'
                                data-bs-placement='right'
                                title='Edit'
                                // className='d-flex align-items-center justify-content-center'
                              >
                                <svg
                                  role='button'
                                  className='action-btn action-btn-edit mx-1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 512 512'
                                  onClick={() => {
                                    editPO(value.purchaseOrderId);
                                    setPoNumber(value.poNumber);
                                  }}
                                >
                                  <path d='M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z' />
                                </svg>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* </Modal.Footer> */}
        <Modal.Body>
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-10 col-lg-12'>
                <div className='mb-10'>
                  {showAddInvoiceSection ? (
                    <>
                      <h5 className='mb-3'>Invoices</h5>
                      <div style={{ border: '2px solid red', padding: '8px' }}>
                        <Table responsive bordered hover size='sm'>
                          <thead>
                            <tr>
                              <th style={{ width: '20%' }}>PO#</th>
                              <th style={{ width: '20%' }}>Invoice#</th>
                              <th style={{ width: '20%' }}>Invoice Amount</th>
                              <th style={{ width: '20%' }}>Invoice Date</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {isEditing ? (
                                  <input
                                    title='Purchase Order'
                                    className='form-control'
                                    id='exampleFormControlSelect1'
                                    value={sampleModel.PoNumber}
                                    // disabled
                                    onChange={(e) => {
                                      const c = { ...sampleModel };
                                      c.PoNumber = e.target.value;
                                      setSampleModel(c);
                                    }}
                                  ></input>
                                ) : showAddInvoiceSection && poRequired ? (
                                  <input
                                    title='Purchase Order'
                                    className='form-control'
                                    id='exampleFormControlSelect1'
                                    value={sampleModel.PoNumber}
                                    disabled
                                    // onChange={(e) => {
                                    //   const c = { ...sampleModel };
                                    //   c.ProjectId = parseInt(e.target.value);
                                    //   setSampleModel(c);
                                    // }}
                                    // value={sampleModel.ProjectId}
                                  ></input>
                                ) : (
                                  <input
                                    title='Purchase Order'
                                    className='form-control'
                                    id='exampleFormControlSelect1'
                                    value='Not Required'
                                    disabled
                                    // onChange={(e) => {
                                    //   const c = { ...sampleModel };
                                    //   c.ProjectId = parseInt(e.target.value);
                                    //   setSampleModel(c);
                                    // }}
                                    // value={sampleModel.ProjectId}
                                  ></input>
                                )}
                              </td>
                              <td>
                                {isEditing ? (
                                  <input
                                    title='Invoice Number'
                                    className='form-control'
                                    disabled
                                    id='invoiceNumber'
                                    name='invoiceNumber'
                                    value={sampleModel.InvoiceNumber}
                                    onChange={(e) => {
                                      const c = { ...sampleModel };
                                      c.InvoiceNumber = parseInt(e.target.value);
                                      setSampleModel(c);
                                    }}
                                    // value={sampleModel.ProjectId}
                                  ></input>
                                ) : (
                                  <input
                                    title='Invoice Number'
                                    className='form-control'
                                    id='invoiceNumber'
                                    name='invoiceNumber'
                                    onChange={(e) => {
                                      const c = { ...sampleModel };
                                      c.InvoiceNumber = parseInt(e.target.value);
                                      setSampleModel(c);
                                    }}
                                    // value={sampleModel.ProjectId}
                                  ></input>
                                )}
                              </td>
                              <td>
                                <CurrencyInput
                                  id='amount'
                                  ref={currencyInputRef}
                                  placeholder={
                                    isEditing
                                      ? `$${parseFloat(sampleModel.Amount).toFixed(2)}`
                                      : '$0.00'
                                  }
                                  allowDecimals={true}
                                  maxLength={9}
                                  decimalsLimit={2}
                                  className='form-control'
                                  onValueChange={validateValue}
                                  prefix={'$'}
                                  step={100}
                                  onBlur={(e) => {
                                    if (e.target.value === '') {
                                      setAmountError(false);
                                      e.target.placeholder = '$0.00';
                                    }
                                  }}
                                />
                                {amountError && (
                                  <span style={{ color: 'red', fontSize: '13px' }}>
                                    Warning: Total Charges exceeds Proposal Amount. Contact Project
                                    Manager to update Proposal Amount.
                                  </span>
                                )}
                              </td>
                              <td>
                                <input
                                  title='Invoice Date'
                                  className='form-control'
                                  placeholder={
                                    isEditing
                                      ? new Date(editingInvoiceDate).toLocaleDateString()
                                      : ''
                                  }
                                  //value={editingInvoiceDate}
                                  id='invoiceDate'
                                  name='invoiceDate'
                                  onChange={(e) => {
                                    const c = { ...sampleModel };
                                    c.InvoiceDate = e.target.value;
                                    setSampleModel(c);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  title='invoice description'
                                  type='text'
                                  className='form-control'
                                  id='NameOrCode'
                                  name='NameOrCode'
                                  value={sampleModel.Description}
                                  onChange={(e) => {
                                    const x = { ...sampleModel };
                                    x.Description = e.target.value;
                                    setSampleModel(x);
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {showAddInvoiceSection ? (
          <Modal.Footer>
            <Button
              //className='secondary-color-sp bg-light btn-click-cancel px-4'
              //onClick={handleCancel}
              className='mx-1 px-4'
              variant='outline-secondary'
              onClick={() => {
                setIsEditing(false);
                setShowAddInvoiceSection(false);
                setPoNumber(0);
                const x = { ...sampleModel };
                x.Description = '';
                x.Amount = 0;
                // x.Id = 0;
                // x.PoNumber = 0;
                setSampleModel(x);
                setPORequired(false);
              }}
            >
              Cancel
            </Button>
            {/* {!isEditing ? (
              <Button
              onClick={() => {
                submit();
                // console.log(currencyInputRef.current);
                // if (currencyInputRef.current !== null && currencyInputRef.current !== undefined) {
                  //   currencyInputRef.current.value = '$0.00';
                  //   console.log(currencyInputRef.current.value);
                  // }
                }}
                className='primary-bg-color-sp btn-click px-4'
                >
                Save
                </Button>
                ) : (
                  <Button onClick={submit} className='primary-bg-color-sp btn-click px-4'>
                  Edit
                  </Button>
                )} */}
            <Button
              className='primary-bg-color-sp btn-click px-4'
              onClick={() => {
                submit();
                setShowAddInvoiceSection(false);
                setPORequired(false);
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        ) : null}
        <Modal.Body>
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='d-flex justify-content-end'>
                  {/* {showAddInvoiceButton ? (
                      <Button className='primary-bg-color-sp btn-click px-4 m-1'>+</Button>
                    ) : null} */}
                </div>
                <div className='d-flex justify-content-end'>
                  {showAddInvoiceSection ? null : (
                    <Button
                      data-bs-toggle='tooltip'
                      title='Add Invoice'
                      className='primary-bg-color-sp btn-click px-4 m-1'
                      onClick={() => setShowAddInvoiceSection(true)}
                    >
                      +
                    </Button>
                  )}
                </div>
                <Table responsive bordered hover size='sm'>
                  <thead>
                    <tr>
                      <th className='text-center'>Invoice#</th>
                      <th className='text-center'>PO#</th>
                      <th className='text-center'>Invoice Amount</th>
                      <th className='text-center'>Description</th>
                      <th className='text-center'>Invoice Date</th>
                      <th className='text-center'>Paid?</th>
                      <th className='text-center'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sampleList?.map((value, index) => (
                      <tr ref={invoiceRecordRef} className='text-center' key={index}>
                        <td className={value.isInvoiced ? `${css.rowColor}` : ''}>
                          {value.invoiceNumber}
                        </td>
                        <td className={value.isInvoiced ? `${css.rowColor}` : ''}>
                          {value.poNumber === null
                            ? 'Not Required'
                            : value.poNumber === ''
                            ? 'Not Required'
                            : value.poNumber}
                        </td>
                        <td className={value.isInvoiced ? `${css.rowColor}` : ''}>
                          {value.amount > 0
                            ? value.amount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                              })
                            : ''}
                        </td>
                        <td className={value.isInvoiced ? `${css.rowColor}` : ''}>
                          {value.description}
                        </td>
                        <td className={value.isInvoiced ? `${css.rowColor}` : ''}>
                          {value.invoiceDate === null
                            ? ''
                            : new Date(value.invoiceDate).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                              })}
                        </td>
                        <td className={value.isInvoiced ? `${css.rowColor}` : ''}>
                          <Form.Check
                            className='d-flex justify-content-center'
                            // label='1'
                            name='group1'
                            type='checkbox'
                            id='checkbox'
                            checked={value.isInvoiced}
                            onLoad={() => {
                              checkBoxRef.current = value.isInvoiced;
                              console.log(checkBoxRef.current);
                            }}
                            onChange={(e) => {
                              // console.log(e.target.checked);
                              checkBoxRef.current = e.target.checked;
                              handleInvoiceEmail(value.amount);
                              // console.log(checkBoxRef.current);
                              // const x = [...sampleList];
                              // x[index].isInvoiced = e.target.checked;
                              // // x[index].Amount = value.invoiceId;

                              // setSampleList(x);
                              sendInvoice(
                                value.invoiceId,
                                e.target.checked,
                                value.amount,
                                value.invoiceNumber,
                                value.poNumber,
                                value.description,
                                value.invoiceDate
                              );
                            }}
                          />
                        </td>
                        <td className={value.isInvoiced ? `${css.rowColor}` : ''}>
                          <td className='d-flex justify-content-center'>
                            <span data-bs-toggle='tooltip' data-bs-placement='right' title='Edit'>
                              <svg
                                role='button'
                                className='action-btn action-btn-edit mx-2'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                                onClick={() => {
                                  editInvoice(value.invoiceId);
                                  // invoiceRecordRef.current = value;
                                  // console.log(invoiceRecordRef.current);
                                }}
                              >
                                <path d='M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z' />
                              </svg>
                            </span>
                            <span data-bs-toggle='tooltip' data-bs-placement='right' title='Delete'>
                              <svg
                                role='button'
                                onClick={() => {
                                  currentItemIdRef.current = value.invoiceId;
                                  setShow(true);
                                  props.handleSampleClose(false);
                                  props.handleInvoiceConfirmModalShow(true);
                                  // if (props.confirmModal !== undefined) {
                                  //   props.confirmModal(true);
                                  // }
                                  // props.onHide();
                                  // deleteItem(value.id);
                                }}
                                className='action-btn action-btn-delete'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 448 512'
                              >
                                <path d='M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z' />
                              </svg>
                            </span>
                          </td>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <h1>Total : {total}</h1> */}
          <Button
            //className='primary-color-sp bg-light btn-click-cancel px-4'
            className='px-4'
            variant='outline-secondary'
            onClick={() => {
              props.onHide();
              props.handleSampleClose(false);
              const c = { ...sampleModel };
              c.Amount = '';
              c.Description = '';
              c.ProjectId = 0;
              c.PoNumber = '';
              c.Id = 0;
              c.InvoiceNumber = 0;
              setSampleModel(c);
              setShowAddInvoiceSection(false);
              setShowAddPOButton(true);
              setIsEditing(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ) : null}{' '} */}
      <InvoiceConfirmModal
        show={show}
        handleSampleClose={setShowConfirm}
        handleSample={handleSample}
        handleInvoiceConfirmModalShow={handleInvoiceConfirmModalShow}
        deleteItem={deleteItem}
        clientId={currentItemIdRef.current}
        setShow={setShow}
        setShowConfirm={setShowConfirm}
        setShowAddInvoiceSection={setShowAddInvoiceSection}
        handleSampleModalShow={handleSampleModalShow}
        // onHide={() => {
        //   ConfirmModelFalse()
        // }}
      />
    </>
  );
}

export default SampleModel;
