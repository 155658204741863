import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './invoiceConfirmModal.css';

function InvoiceConfirmModal(props: any) {
  function handleCancel() {
    props.setShowConfirm(true);
    props.handleInvoiceConfirmModalShow(false);
    props.setShowAddInvoiceSection(false);
    props.handleSampleModalShow(true);
  }

  function handleDelete() {
    props.deleteItem();
    props.handleInvoiceConfirmModalShow(false);
    props.handleSample();
    props.setShowAddInvoiceSection(false);
    // if (props.handleSampleClose !== undefined) {
    //   props.handleSampleClose(false);
    // }

    //props.setShowConfirm(true)
  }

  // useEffect(() => {
  //   return () => {
  //     props.handleConfirmModal(false);
  //   };
  // }, [props]);

  return (
    // <div className='modal show' style={{ display: 'block', position: 'initial' }}>
    <Modal {...props} size='sm' data-backdrop='static' keyboard={false} centered>
      {/* <Modal.Dialog> */}
      <Modal.Header className='primary-bg-color-sp text-white'>
        <Modal.Title className=''>Confirm Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body className='bg-secondary-bg-color-sp'>
        <p>Delete the Invoice?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            handleCancel();
            props.setShow(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={() => {
            handleDelete();
            props.setShow(false);
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
      {/* </Modal.Dialog> */}
    </Modal>
    // </div>
  );
}

export default InvoiceConfirmModal;
