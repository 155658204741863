import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { urlProjects } from '../../../endpoints';
import './CommentModel.css';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

function CommentModel(props: any) {
  const [commentList, setCommentList] = useState<any[]>([]);
  const [charCount, setCharCount] = useState(0);
  const [selectedNote, setSelectedNote] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  //const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [confirmShow, setConfirmShow] = useState<boolean>(false);
  const [projectNoteId, setProjectNoteId] = useState(0);

  const [comment, setComment] = useState({
    note: '',
    projectId: props.projectId,
    Id: 0
  });

  const [response, setResponse] = useState({
    status: false,
    message: ''
  });

  const deleteItemRef = useRef(null);

  let accessToken = sessionStorage.getItem('accessToken');

  useEffect(() => {
    setCharCount(0);
  }, [props.show]);

  useEffect(() => {
    if (isEditing === true) {
      setIsEditing(false);
    }
    if (props.projectId !== undefined) {
      axios
        .get(`${urlProjects}/GetNotesByProjectId?projectId=${props.projectId}`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then((response) => {
          setCommentList(response.data.data);
          const x = { ...comment };
          x.projectId = props.projectId;
          setComment(x);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // if (props.projectId !== undefined) {
    //   setProjectId(props.projectId);
    // }
  }, [props]);

  function handleConfirm() {
    setConfirmShow(true);
    props.onHide(false);
  }

  function handleClose() {
    setIsEditing(false);
    setSelectedNote('');
    const c = { ...comment };
    c.note = '';
    c.projectId = '';
    c.Id = 0;
    setComment(c);
    props.onHide();
  }

  function handleClear() {
    const x = { ...comment };
    x.note = '';
    setComment(x);
    setCharCount(0);
  }

  function setShowConfirm(showFromChild: boolean) {
    props.handleCommentModalShow(showFromChild);
  }

  function handleConfirmModal(fromChild: any) {
    setShow(fromChild);
  }

  function submit() {
    var payload = JSON.stringify(comment);
    if (isEditing) {
      console.log('Implement in API');
      axios
        .put(`${urlProjects}/UpdateProjectNotes`, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
          }
        })
        .then((res) => {
          if (res.data.status) {
            props.onHide(false);

            const c = { ...comment };
            c.note = '';
            c.projectId = props.projectId;
            // c.Id = res.data.data.Id;
            setComment(c);

            props.onHide();
          } else {
            const c = { ...response };
            c.status = res.data.status;
            c.message = res.data.message;
            setResponse(c);
          }
          handleClose();
          props.handleToast(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .post(`${urlProjects}/AddProjectNotes`, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
          }
        })
        .then((res) => {
          if (res.data.status) {
            props.onHide(false);

            const c = { ...comment };
            c.note = '';
            c.projectId = props.projectId;
            // c.projectId = props.projectId;
            // c.Id = res.data.data.Id;
            setComment(c);

            props.onHide();
          } else {
            const c = { ...response };
            c.status = res.data.status;
            c.message = res.data.message;
            setResponse(c);
          }
          handleClose();
          props.handleToast(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function deleteItem() {
    const Id = deleteItemRef.current;
    // console.log(Id);
    axios
      .delete(`${urlProjects}/DeleteProjectNote/${Id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then((res) => {
        props.onHide(false);
        props.handleToast(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton className='primary-bg-color-sp text-white'>
          <Modal.Title id='contained-modal-title-vcenter' className='modeltitle'>
            Notes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-10 col-lg-12'>
                <div className='mb-10'>
                  <label htmlFor='ClientName' className='form-label label-font'>
                    Add Note
                  </label>
                  <textarea
                    className='form-control'
                    id='ClientName'
                    style={{ height: '200px' }}
                    value={comment.note}
                    onChange={(e) => {
                      const x = { ...comment };
                      x.note = e.target.value;
                      setComment(x);
                      setCharCount(e.target.value.length);
                    }}
                  ></textarea>
                  <div
                    className='d-flex justify-content-end'
                    style={{ color: charCount >= 501 ? 'red' : 'inherit' }}
                  >
                    {charCount}/500
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='secondary-color-sp bg-light btn-click-cancel px-4'
            onClick={() => {
              setIsEditing(false);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            className='primary-bg-color-sp btn-click px-4'
            onClick={() => {
              // const c = { ...comment };
              // c.projectId = props.projectId;
              // setComment(c);
              submit();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
        <Modal.Body>
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-12'>
                <ListGroup>
                  {commentList !== null ? <div className='fw-bold mb-2'>Project Notes</div> : <></>}
                  {commentList?.map((value, index) => (
                    <ListGroup.Item
                      key={index}
                      as='li'
                      className='d-flex justify-content-between align-items-start'
                    >
                      <div
                        className='ms-2 me-auto'
                        onClick={() => {
                          deleteItemRef.current = value.projectNoteId;
                          const x = { ...comment };
                          x.note = value.note;
                          x.Id = deleteItemRef.current || 0;
                          // x.billingId = deleteItemRef.current || '';
                          x.projectId = value.projectId;
                          setComment(x);
                          setSelectedNote(value.note);
                          setIsEditing(true);
                        }}
                      >
                        {/* {value.note} */}
                        {value.note !== null
                          ? value.note.length > 50
                            ? `${value.note.substring(0, 50)}...`
                            : value.note
                          : ''}
                      </div>
                      <Badge className='mt-1' bg='secondary' pill>
                        {new Date(value.createdDateTime).toLocaleString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                      </Badge>
                      <span
                        style={{ marginLeft: '10px', paddingBottom: '0px' }}
                        data-bs-toggle='tooltip'
                        data-bs-placement='right'
                        title='Delete'
                      >
                        <svg
                          role='button'
                          onClick={() => {
                            deleteItemRef.current = value.projectNoteId;
                            // deleteItemRef.current = value.projectNoteId;
                            handleConfirm();
                            // deleteItemRef.current = value.id;
                            // deleteItem();
                          }}
                          className='action-btn action-btn-delete'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 448 512'
                        >
                          <path d='M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z' />
                        </svg>
                      </span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ConfirmModal
        show={confirmShow}
        deleteItem={deleteItem}
        projectNoteId={deleteItemRef.current}
        setShow={setConfirmShow}
        setShowConfirm={setShowConfirm}
        handleConfirmModal={handleConfirmModal}
        handleClear={handleClear}
      />
    </>
  );
}

export default CommentModel;
