import axios, { Axios, AxiosResponse } from 'axios';
import { Table } from 'react-bootstrap';
import React, { useEffect, useState, useRef } from 'react';
import { urlSampleProject, urlProjects } from '../../endpoints';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';
import EditProjectSample from '../project/SampleModel/EditProjectSampleModal/EditProjectSample';
import Pagination from '../../components/utils/Pagination/Pagination';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { loginRequest } from '../../auth/authConfig';
import { useMsal } from '@azure/msal-react';
import { useUserList } from '../../context/UserContext';
import { useClientList } from '../../context/ClientContext';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import useExclusionList from '../../hooks/useExclusionList';
import TableNumberSelect from '../../components/common/TableNumberSelect/TableNumberSelect';

export default function ProjectSamples(props: any) {
  const [sampleList, setSampleList] = useState<any[]>([]);
  const { instance, accounts, inProgress } = useMsal();
  const [open, setOpen] = useState(false);
  const [SortDialogopen, setSortDialogopen] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [sampleId, setSampleId] = React.useState();
  const [FilterArray, SetFilterArray] = useState<{ key: string; value: string }[]>([]);
  const [filterProductStatus, SetfilterProductStatus] = useState('');
  const [filterClient, SetfilterClient] = useState('');
  const [filterEnteredBy, SetfilterEnteredBy] = useState('');
  // const [filterProductStatus, SetfilterProductStatus] = useState('');
  const [operator, SetOperator] = useState('OR');

  const [totalAmountOfPages, setTotalAmountOfPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [employeeData, setEmployeeData] = useState<any[]>([]);

  const [ActiveSortValue1, SetSortBtnValue1] = useState(false);
  const [ActiveSortValue2, SetSortBtnValue2] = useState(false);
  const [ActiveSortValue3, SetSortBtnValue3] = useState(false);
  const [ActiveSortValue4, SetSortBtnValue4] = useState(false);
  const [ActiveSortValue5, SetSortBtnValue5] = useState(false);
  const [ActiveSortValue6, SetSortBtnValue6] = useState(false);
  const [ActiveSortValue7, SetSortBtnValue7] = useState(false);
  const [ActiveSortValue8, SetSortBtnValue8] = useState(false);
  const [ActiveSortValue9, SetSortBtnValue9] = useState(false);
  const [ActiveSortValue10, SetSortBtnValue10] = useState(false);

  const [projects, setProjects] = useState<any[]>([]);
  const [render, setRender] = useState(0);

  const [sortWith, SetSortWith] = useState('');
  const [sortBy, SetSortBy] = useState('dec');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [clear, SetClear] = useState(true);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastHeaderMessage, setToastHeaderMessage] = useState('');
  const [toastType, setToastType] = useState('');

  const [response, setResponse] = useState({
    status: false,
    message: ''
  });

  const userList = useUserList();
  const clientList = useClientList();
  const disallowed: any = useExclusionList();

  const filteredEmployeeData = employeeData.filter(
    (value) => !disallowed.includes(value.displayName)
  );

  let count = useRef(0);
  let PageSize = 5;

  function handleModalShow() {
    setModalShow(false);
  }

  function HandleRecordsPerPage(value: number) {
    setRecordsPerPage(value);
  }

  function handleToast(response: any) {
    switch (response) {
      case 'Sample Edit Saved':
        setToastHeaderMessage('Saved!');
        setToastMessage('Sample Updated');
        setToastType('success');
        setToastShow(true);
        break;
      default:
        setToastHeaderMessage('Failed!');
        setToastMessage('An Error Occured.');
        setToastType('danger');
        setToastShow(true);
    }
  }

  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        sessionStorage.setItem('emailToken', response.accessToken);
        getMSGraphUserList(response.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response: any) => {
          getMSGraphUserList(response.accessToken);
        });
      });
  }

  function getMSGraphUserList(accessToken: any) {
    axios
      .get('https://graph.microsoft.com/v1.0/users', {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then((response) => {
        if (response.status === 200) {
          setEmployeeData(response.data.value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getProjectSampleList();
    RequestAccessToken();
  }, [page, recordsPerPage, totalAmountOfPages, sortBy, sortWith, search]);

  function GetFilterArray(key: string, value: string) {
    if (value !== '') {
      var isexist = FilterArray.find((x: any) => x.key === key);
      if (isexist !== undefined) {
        var fa: any = FilterArray.map((x: any) => {
          if (x.key === key) {
            x.value = value;
          }
          return x;
        });
        SetFilterArray(fa);
      } else {
        var obj = {
          key: key,
          value: value
        };
        SetFilterArray([...FilterArray, obj] as any);
      }
    } else {
      SetFilterArray(FilterArray.filter((x: any) => x.key !== key));
    }
  }
  function ApplyFilter() {
    setOpen(() => !open);
    getProjectSampleList();
  }
  function getProjectSampleList() {
    var params = {
      page,
      recordsPerPage,
      filters: '',
      sortBy: 'asc',
      sortWith: '',
      search: ''
    };
    if (FilterArray.length > 0) {
      params.filters = JSON.stringify(FilterArray);
    }
    if (sortWith !== '') {
      params.sortWith = sortWith;
    }
    if (search !== '') {
      params.search = search;
    }

    params.sortBy = sortBy;
    axios
      .get(`${urlProjects}/GetProjectSamples`, {
        params: params,
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        //const totalAmountOfRecords = parseInt(response.headers['totalamountofrecords'], 10);
        const totalAmountOfRecords = response.data.data.totalRecords;
        setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
        setSampleList(response.data.data.data);
        setRender((count.current = count.current + 1));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function ClearFilter() {
    SetFilterArray([]);
    SetfilterProductStatus('');
    SetfilterClient('');
    SetfilterEnteredBy('');
    SetfilterProductStatus('');
    SetOperator('OR');
    setOpen(() => !open);
    axios
      .get(`${urlProjects}/GetProjectSamples`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        //const totalAmountOfRecords = parseInt(response.headers['totalamountofrecords'], 10);
        const totalAmountOfRecords = response.data.data.totalRecords;
        setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
        setSampleList(response.data.data.data);
        setRender((count.current = count.current + 1));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function editItem(sampleId: any) {
    setSampleId(sampleId);
    setModalShow(true);
  }

  useEffect(() => {
    if (
      FilterArray.length > 0 ||
      filterProductStatus !== '' ||
      filterClient !== '' ||
      filterEnteredBy !== ''
    ) {
      SetClear(false);
    } else {
      SetClear(true);
    }
  }, [FilterArray.length, open]);

  return (
    <>
      <div className='col-12'>
        <div className='row m-0'>
          <div>
            <div className='container-fluid'>
              <div className='py-4 heading'>Project Sample </div>
              <div className='col-12 pt-4'>
                <div className='row'>
                  <div className='col-12'>
                    <div style={{ height: '62px' }} className='row'>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '10%' }} className='col-2'>
                          <div className='position-relative'>
                            <input
                              type='text'
                              className='form-control paddingLeft border-0'
                              placeholder='Search'
                              required
                              style={{ marginTop: '3%', marginBottom: '70px' }}
                              value={search}
                              onChange={(e) => {
                                setSearch((pre) => e.target.value);
                              }}
                            />
                            <svg
                              className='icon-input'
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 512 512'
                            >
                              <path d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z' />
                            </svg>
                          </div>
                        </div>
                        <div
                          className='col-2 d-flex align-items-start'
                          style={{ position: 'relative', marginTop: '0.3%' }}
                        >
                          <button
                            onClick={async () => {
                              setOpen(() => !open);
                              setSortDialogopen(() => false);
                            }}
                            aria-expanded={open}
                            style={{
                              backgroundColor: 'white',
                              padding: '2%',
                              color: 'black',
                              borderColor: 'white',
                              border: '0px'
                            }}
                          >
                            <div className='d-flex align-item-center'>
                              <img src='/assets/img/filter.svg' alt='Filter' />

                              <div>
                                <p className='px-2 mb-0'>Filters</p>
                              </div>
                              <div
                                style={{ marginLeft: '5px', marginTop: '3%' }}
                                className={open === true ? 'fa fa-caret-up' : 'fa fa-caret-down'}
                              ></div>
                            </div>
                          </button>
                          <div style={{ minHeight: '150px' }}>
                            <Collapse in={open} dimension='width'>
                              <div id='example-collapse-text'>
                                <Card
                                  body
                                  style={{
                                    width: '900px',
                                    borderRadius: '24px',
                                    position: 'absolute',
                                    boxShadow: '0px 0px 83px -48px black',
                                    top: '30%',
                                    left: '0%',
                                    zIndex: '1'
                                  }}
                                >
                                  <>
                                    <div className='container modal_form'>
                                      <div className='row' style={{ width: '100%' }}>
                                        <br />

                                        {/*---------------------------------- try new dropdown of filter ---------------------------------- */}
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Product Status
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetfilterProductStatus(e.target.value);
                                                GetFilterArray('product status', e.target.value);
                                              }}
                                              value={filterProductStatus}
                                              className='form-select'
                                              aria-label='Default select example'
                                            >
                                              <option
                                                selected
                                                value=''
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              <option>Select type</option>
                                              <option value='In House'>In House</option>
                                              <option value='Disposed'>Disposed</option>
                                              <option value='Returned to Client'>
                                                Returned to Client
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Client
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetfilterClient(e.target.value);
                                                GetFilterArray('client name', e.target.value);
                                              }}
                                              value={filterClient}
                                              className='form-select'
                                              aria-label='Default select example'
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {clientList?.map((value, index) => (
                                                <option key={index}>
                                                  <div>{value.clientName}</div>
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Entered By
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetfilterEnteredBy(e.target.value);
                                                GetFilterArray('enteredBy', e.target.value);
                                              }}
                                              value={filterEnteredBy}
                                              className='form-select'
                                              aria-label='Default select example'
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {filteredEmployeeData.map
                                                ? filteredEmployeeData.map((value, index) => (
                                                    <option key={index}>
                                                      <div>{value.displayName}</div>
                                                    </option>
                                                  ))
                                                : null}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className='row'
                                        style={{
                                          width: '100%',
                                          marginTop: '1.5%'
                                        }}
                                      >
                                        <br />

                                        {/*---------------------------------- 2nd row dropdown of filter ---------------------------------- */}
                                        {/* <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Modified Date
                                            </p>
                                            <input
                                              style={{
                                                width: '100%'
                                              }}
                                              type='date'
                                              className='form-control'
                                              placeholder='Spec-1'
                                            />
                                          </div>
                                        </div> */}
                                        {/* <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Product Status
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetfilterProductStatus(e.target.value);
                                                GetFilterArray('client id', e.target.value);
                                              }}
                                              value={filterProductStatus}
                                              className='form-select'
                                              aria-label='Default select example'
                                            >
                                              <option
                                                value=''
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                              >
                                                None
                                              </option>
                                              {sampleList?.map((value, index) => (
                                                <option key={index}>
                                                  <div>{value.productStatus}</div>
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div> */}
                                        <div className='col-4'>
                                          <br />
                                          <div className='position-relative'>
                                            <p
                                              style={{
                                                fontWeight: '500',
                                                textAlign: 'center',
                                                marginLeft: '-30%'
                                              }}
                                            >
                                              Required
                                            </p>
                                            <select
                                              onChange={(e: any) => {
                                                SetOperator(e.target.value);
                                                GetFilterArray('operator', e.target.value);
                                              }}
                                              className='form-select'
                                              aria-label='Default select example'
                                              value={operator}
                                            >
                                              <option
                                                selected
                                                style={{
                                                  color: 'red'
                                                }}
                                                value=''
                                              >
                                                None
                                              </option>
                                              <option value='And'>AND</option>
                                              <option value='OR'>OR</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className='row justify-content-end'
                                      style={{ margin: '2% 1% 0 0' }}
                                    >
                                      <div className=' d-flex justify-content-end'>
                                        <ToggleButton
                                          id='toggle-clear'
                                          type='checkbox'
                                          variant='outline-secondary'
                                          checked={!clear}
                                          value='1'
                                          onClick={() => {
                                            ClearFilter();
                                          }}
                                          className='btn primary-bg-color-sp-secondary px-4 mx-4'
                                        >
                                          Clear
                                        </ToggleButton>
                                        <button
                                          type='button'
                                          onClick={() => setOpen(() => !open)}
                                          className='btn primary-bg-color-sp-ouline px-4 mx-4'
                                        >
                                          Close
                                        </button>
                                        <button
                                          type='button'
                                          onClick={() => ApplyFilter()}
                                          className='btn primary-bg-color-sp text-white'
                                        >
                                          Filter
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                </Card>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Table responsive bordered hover size='sm' className='shadow-none'>
                      <thead className='shadow-none table-borderEmp'>
                        <tr style={{ textAlign: 'center' }}>
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>PID</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue1(() => !ActiveSortValue1);
                                    if (!ActiveSortValue1) {
                                      SetSortWith((pre) => 'projectId');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'projectId');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          <th className='text-start'>SID</th>
                          {/* <tr style={{ textAlign: 'center' }}> */}
                          {/* <th>
                            <div className='d-flex justify-content-between'>
                              <div>Sample Id</div>
                              <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue1(() => !ActiveSortValue1);
                                    if (!ActiveSortValue1) {
                                      SetSortWith((pre) => 'sampleId');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'des');
                                      }
                                      SetSortWith((pre) => 'sampleId');
                                    }
                                  }}
                                ></i>
                              </div>
                            </div>
                          </th> */}
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>Date & Time</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue2(() => !ActiveSortValue2);
                                    if (!ActiveSortValue2) {
                                      SetSortWith((pre) => 'dateTime');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'des');
                                      }
                                      SetSortWith((pre) => 'dateTime');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          {/* <th>
                            <div className='d-flex justify-content-between'>
                              <div>Project Type</div>
                              <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue2(() => !ActiveSortValue2);
                                    if (!ActiveSortValue1) {
                                      SetSortWith((pre) => 'projectType');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'des');
                                      }
                                      SetSortWith((pre) => 'projectType');
                                    }
                                  }}
                                ></i>
                              </div>
                            </div>
                          </th> */}
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>Name Or Code</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue3(() => !ActiveSortValue3);
                                    if (!ActiveSortValue3) {
                                      SetSortWith((pre) => 'nameorCode');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'nameorCode');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>Quantity</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue4(() => !ActiveSortValue4);
                                    if (!ActiveSortValue4) {
                                      SetSortWith((pre) => 'quantity');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'quantity');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>Unit Size</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue5(() => !ActiveSortValue5);
                                    if (!ActiveSortValue5) {
                                      SetSortWith((pre) => 'unitSize');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'unitSize');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>Return Date</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue6(() => !ActiveSortValue6);
                                    if (!ActiveSortValue6) {
                                      SetSortWith((pre) => 'returnDate');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'returnDate');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>Notes</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue7(() => !ActiveSortValue7);
                                    if (!ActiveSortValue7) {
                                      SetSortWith((pre) => 'infoNotes');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'infoNotes');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          {/* <th>
                            <div className='d-flex justify-content-between'>
                              <div>Product Status</div> */}
                          {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue8(() => !ActiveSortValue8);
                                    if (!ActiveSortValue8) {
                                      SetSortWith((pre) => 'productStatus');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'productStatus');
                                    }
                                  }}
                                ></i>
                              </div> */}
                          {/* </div> */}
                          {/* </th> */}
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>Client Name</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue9(() => !ActiveSortValue9);
                                    if (!ActiveSortValue9) {
                                      SetSortWith((pre) => 'clientName');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'clientName');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          {/* <th>
                            <div className='d-flex justify-content-between'>
                              <div>SSContact</div>
                              <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue7(() => !ActiveSortValue7);
                                    if (!ActiveSortValue1) {
                                      SetSortWith((pre) => 'ssContact');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'des');
                                      }
                                      SetSortWith((pre) => 'ssContact');
                                    }
                                  }}
                                ></i>
                              </div>
                            </div>
                          </th> */}
                          <th>
                            <div className='d-flex justify-content-between'>
                              <div>Entered By</div>
                              {/* <div className='ShortButtonTH'>
                                <i
                                  className='fa fa-solid fa-sort'
                                  onClick={() => {
                                    SetSortBtnValue10(() => !ActiveSortValue10);
                                    if (!ActiveSortValue10) {
                                      SetSortWith((pre) => 'enteredByOrOtherInfo');
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                    } else {
                                      if (sortBy === 'dec') {
                                        SetSortBy((pre) => 'asc');
                                      } else {
                                        SetSortBy((pre) => 'dec');
                                      }
                                      SetSortWith((pre) => 'enteredByOrOtherInfo');
                                    }
                                  }}
                                ></i>
                              </div> */}
                            </div>
                          </th>
                          <th style={{ width: '5vw' }}>Action</th>
                        </tr>
                      </thead>
                      {/* <tbody style={{ textAlign: 'center', verticalAlign: 'middle' }}> */}
                      <tbody>
                        {sampleList?.map((value, index) => (
                          <tr key={index} className='align-middle text wrap'>
                            <td>{value.projectId}</td>
                            <td>{value.sampleId}</td>
                            {/* <td>{value.sampleId}</td> */}
                            <td>{new Date(value.dateTime).toDateString()}</td>
                            {/* <td>{value.projectType}</td> */}
                            <td style={{ minWidth: '125.48px', whiteSpace: 'pre-wrap' }}>
                              {value.nameOrCode}
                              {/* {value.nameOrCode.length > 50
                                ? `${value.nameOrCode.substring(0, 50)}...`
                                : value.nameOrCode} */}
                            </td>
                            <td>{value.quantity}</td>
                            <td>{value.unitSize}</td>
                            <td>{new Date(value.returnDate).toDateString()}</td>
                            <td>{value.infoNotes}</td>
                            {/* <td>{value.productStatus}</td> */}
                            <td>{value.clientName}</td>
                            {/* <td>{value.ssContact}</td> */}
                            <td>{value.enteredByOrOtherInfo}</td>
                            <td className='position-relative'>
                              <svg
                                role='button'
                                onClick={() => {
                                  editItem(value.sampleId);
                                }}
                                className='action-btn action-btn-edit mx-2'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                              >
                                <path d='M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z' />
                              </svg>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      pageSize={PageSize}
                      currentPage={page}
                      totalAmountOfPages={totalAmountOfPages}
                      onChange={(newPage) => setPage(newPage)}
                    />
                    <div className='col-12 d-flex align-items-center justify-content-end'>
                      <TableNumberSelect handleRecordsPerPage={HandleRecordsPerPage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditProjectSample
          name={'Edit Project Sample'}
          show={modalShow}
          employeeData={employeeData}
          handleToast={handleToast}
          handleModalShow={handleModalShow}
          sampleId={sampleId}
          onHide={() => {
            setModalShow(false);
            setSampleId(undefined);
            getProjectSampleList();
          }}
        />
        <ToastContainer className='p-3' position='top-center'>
          <Toast
            bg={toastType}
            onClose={() => {
              setToastShow(false);
              setToastType('');
              setToastHeaderMessage('');
              setToastMessage('');
            }}
            show={toastShow}
            delay={3000}
            autohide
          >
            <Toast.Header closeButton={true}>
              <strong className='me-auto'>{toastHeaderMessage}</strong>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </>
  );
}
