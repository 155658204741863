import react from 'react';
import { useEffect, useState } from 'react';

function useNewProjectValidateEdit() {
  const [editValidated, setEditValidated] = useState(false);
  const [ProjectNameValidated, setProjectNameValidated] = useState(false);
  const [ProjectLeadValidated, setProjectLeadValidated] = useState(false);
  const [StatusValidated, setStatusValidated] = useState(false);
  const [OverallCategoryValidated, setOverallCategoryValidated] = useState(false);
  const [ClientValidated, setClientValidated] = useState(false);
  // const [ContactValidated, setContactValidated] = useState(false);
  const [BillingCenterValidated, setBillingCenterValidated] = useState(false);
  const [ProjectTypeValidated, setProjectTypeValidated] = useState(false);
  const [PanelTypeValidated, setPanelTypeValidated] = useState(false);
  const [ProposalDateValidated, setProposalDateValidated] = useState(false);
  // const [ProposalAmountValidated, setProposalAmountValidated] = useState(false);

  function editValidate(field: any) {
    const inputField = document.querySelector(`#${field}`) as HTMLInputElement;
    const value = inputField?.value?.trim() || '';

    switch (field) {
      case 'projectName':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setProjectNameValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setProjectNameValidated(true);
        }
        break;

      case 'projectLead':
        if (value === 'Select...') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setProjectLeadValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setProjectLeadValidated(true);
        }
        break;

      case 'statusSelect':
        if (value === 'Select...') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setStatusValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setStatusValidated(true);
        }
        break;

      case 'overallCategory':
        if (value === 'Select...') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setOverallCategoryValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setOverallCategoryValidated(true);
        }
        break;

      case 'clientIdForm':
        if (value === 'Select...') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setClientValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setClientValidated(true);
        }
        break;

      case 'billingCenter':
        if (value === 'Select...') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setBillingCenterValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setBillingCenterValidated(true);
        }
        break;

      // case 'projectType':
      //   if (value === 'Select...') {
      //     inputField.reportValidity();
      //     inputField.classList.add('invalid');
      //     inputField.nextElementSibling!.textContent = 'This field is required.';
      //     setProjectTypeValidated(false);
      //   } else {
      //     inputField.classList.remove('invalid');
      //     inputField.nextElementSibling!.textContent = '';
      //     setProjectTypeValidated(true);
      //   }
      //   break;

      // case 'panelType':
      //   if (value === 'Select...') {
      //     inputField.reportValidity();
      //     inputField.classList.add('invalid');
      //     inputField.nextElementSibling!.textContent = 'This field is required.';
      //     setPanelTypeValidated(false);
      //   } else {
      //     inputField.classList.remove('invalid');
      //     inputField.nextElementSibling!.textContent = '';
      //     setPanelTypeValidated(true);
      //   }
      //   break;

      // case 'contactIdForm':
      //   if (value === 'Select...') {
      //     inputField.reportValidity();
      //     inputField.classList.add('invalid');
      //     inputField.nextElementSibling!.textContent = 'This field is required.';
      //     setContactValidated(false);
      //   } else {
      //     inputField.classList.remove('invalid');
      //     inputField.nextElementSibling!.textContent = '';
      //     setContactValidated(true);
      //   }
      //   break;

      case 'proposalDate':
        if (value === '') {
          inputField.reportValidity();
          inputField.classList.add('invalid');
          inputField.nextElementSibling!.textContent = 'This field is required.';
          setProposalDateValidated(false);
        } else {
          inputField.classList.remove('invalid');
          inputField.nextElementSibling!.textContent = '';
          setProposalDateValidated(true);
        }
        break;

      // case 'proposalAmount':
      //   if (value === '') {
      //     inputField.reportValidity();
      //     inputField.classList.add('invalid');
      //     inputField.nextElementSibling!.textContent = 'This field is required.';
      //     setProposalAmountValidated(false);
      //   } else {
      //     inputField.classList.remove('invalid');
      //     inputField.nextElementSibling!.textContent = '';
      //     setProposalAmountValidated(true);
      //   }
      //   break;

      default:
        break;
    }
  }

  function EditValidateAll() {
    editValidate('projectName');
    editValidate('projectLead');
    editValidate('statusSelect');
    editValidate('overallCategory');
    editValidate('clientIdForm');
    // editValidate('contactIdForm');
    editValidate('billingCenter');
    // editValidate('projectType');
    // editValidate('panelType');
    editValidate('proposalDate');
    // editValidate('proposalAmount');
  }

  function editResetValidations() {
    setEditValidated(false);
    setProjectNameValidated(false);
    setProjectLeadValidated(false);
    setStatusValidated(false);
    setOverallCategoryValidated(false);
    setClientValidated(false);
    // setContactValidated(false);
    setBillingCenterValidated(false);
    // setProjectTypeValidated(false);
    // setPanelTypeValidated(false);
    setProposalDateValidated(false);
    // setProposalAmountValidated(false);
  }

  //   useEffect(() => {
  //   // Run resetValidations on exit
  //   return () => {
  //     resetValidations();
  //   }
  // }, []);

  useEffect(() => {
    if (
      ProjectNameValidated === true &&
      ProjectLeadValidated === true &&
      StatusValidated === true &&
      OverallCategoryValidated === true &&
      ClientValidated === true &&
      // ContactValidated === true &&
      BillingCenterValidated === true &&
      // ProjectTypeValidated === true &&
      // PanelTypeValidated === true &&
      ProposalDateValidated === true
      // ProposalAmountValidated === true
    ) {
      setEditValidated(true);
    } else {
      setEditValidated(false);
    }
  }, [
    ProjectNameValidated,
    ProjectLeadValidated,
    StatusValidated,
    OverallCategoryValidated,
    ClientValidated,
    // ContactValidated,
    BillingCenterValidated,
    // ProjectTypeValidated,
    // PanelTypeValidated,
    ProposalDateValidated
    // ProposalAmountValidated
  ]);

  return { editValidated, EditValidateAll, editValidate, editResetValidations };
}

export default useNewProjectValidateEdit;
