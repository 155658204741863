import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ModalBody, Toast, ToastContainer } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { urlProjects, urlClients } from '../../../../endpoints';
import { useGetClients, useClientList } from '../../../../context/ClientContext';

function EditProjectSample(props: any) {
  const [model, setModel] = useState({
    sampleId: 0,
    dateTime: '',
    projectType: '',
    nameOrCode: '',
    quantity: 0,
    unitSize: '',
    returnDate: '',
    infoNotes: '',
    productStatus: '',
    ssContact: '',
    enteredByOrOtherInfo: '',
    projectId: 0,
    clientId: 0
  });
  const [response, setResponse] = useState({
    status: false,
    message: ''
  });
  const [show, setShow] = useState(false);
  // const [clientList, setClientList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);

  const accessToken = sessionStorage.getItem('accessToken');

  const clientList = useClientList();

  useEffect(() => {
    if (props.sampleId !== undefined) {
      axios
        .get(`${urlProjects}/GetSamplesBySampleId?sampleId=${props.sampleId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
        })
        .then((res) => {
          if (res.data.status === true) {
            var data = res.data.data;
            const x = { ...model };
            x.sampleId = data.sampleId;
            x.dateTime = data.dateTime;
            x.projectType = data.projectType;
            x.nameOrCode = data.nameOrCode;
            x.quantity = data.quantity;
            x.unitSize = data.unitSize;
            x.returnDate = data.returnDate;
            x.infoNotes = data.infoNotes;
            x.productStatus = data.productStatus;
            x.ssContact = data.ssContact;
            x.enteredByOrOtherInfo = data.enteredByOrOtherInfo;
            x.projectId = data.projectId;
            x.clientId = data.clientId;
            setModel(x);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
    // getClientList(accessToken);
  }, [props]);

  // function getClientList(accessToken: any) {
  //   axios
  //     .get(`${urlClients}/GetClientList`, {
  //       headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
  //     })
  //     .then((response) => {
  //       setClientList(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  function submit() {
    var payload = JSON.stringify(model);
    axios
      .put(`${urlProjects}/EditProjectSample`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then((res) => {
        if (res.status === 204) {
          const c = { ...model };
          c.sampleId = 0;
          c.dateTime = '';
          c.projectType = '';
          c.nameOrCode = '';
          c.quantity = 0;
          c.unitSize = '';
          c.returnDate = '';
          c.infoNotes = '';
          c.productStatus = '';
          c.ssContact = '';
          c.enteredByOrOtherInfo = '';
          c.projectId = 0;
          c.clientId = 0;
          setModel(c);
        } else {
          const c = { ...response };
          c.status = res.data.status;
          c.message = res.data.message;
          setResponse(c);
        }
        props.onHide();
        props.handleToast(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function cancel() {
    const c = { ...model };
    c.sampleId = 0;
    c.dateTime = '';
    c.projectType = '';
    c.nameOrCode = '';
    c.quantity = 0;
    c.unitSize = '';
    c.returnDate = '';
    c.infoNotes = '';
    c.productStatus = '';
    c.ssContact = '';
    c.enteredByOrOtherInfo = '';
    c.projectId = 0;
    c.clientId = 0;
    setModel(c);
    // props.clearProjectId();
    props.onHide();
    // props.handleModalShow();
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header className='primary-bg-color-sp text-white'>
        <Modal.Title id='contained-modal-title-vcenter' className='modeltitle'>
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <ModalBody>
        <div className='row'>
          <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='nameOrCode' className='form-label label-font'>
                Name or Code
              </label>
              <input
                type='text'
                className='form-control'
                id='nameOrCode'
                name='nameOrCode'
                value={model.nameOrCode}
                onChange={(e) => {
                  const x = { ...model };
                  x.nameOrCode = e.target.value;
                  setModel(x);
                }}
              />
            </div>
          </div>
          {/* <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='projectType' className='form-label label-font'>
                Project Type
              </label>
              <input
                type='text'
                className='form-control'
                id='projectType'
                name='projectType'
                value={model.projectType}
                onChange={(e) => {
                  const x = { ...model };
                  x.projectType = e.target.value;
                  setModel(x);
                }}
              />
            </div>
          </div> */}
          <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='quantity' className='form-label label-font'>
                Quantity
              </label>
              <input
                type='number'
                className='form-control'
                id='quantity'
                name='quantity'
                value={model.quantity}
                onChange={(e) => {
                  const x = { ...model };
                  x.quantity = parseInt(e.target.value);
                  setModel(x);
                }}
              />
            </div>
          </div>
          <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='unitSize' className='form-label label-font'>
                Unit Size
              </label>
              <input
                type='text'
                className='form-control'
                id='unitSize'
                name='unitSize'
                value={model.unitSize}
                onChange={(e) => {
                  const x = { ...model };
                  x.unitSize = e.target.value;
                  setModel(x);
                }}
              />
            </div>
          </div>
          <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='infoNotes' className='form-label label-font'>
                Info Notes
              </label>
              <input
                type='text'
                className='form-control'
                id='infoNotes'
                name='infoNotes'
                value={model.infoNotes}
                onChange={(e) => {
                  const x = { ...model };
                  x.infoNotes = e.target.value;
                  setModel(x);
                }}
              />
            </div>
          </div>
          {/* <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='productStatus' className='form-label label-font'>
                Product Status
              </label>
              <select
                title='Product Status'
                className='form-select'
                id='ProductStatus'
                name='ProductStatus'
                value={model.productStatus}
                onChange={(e) => {
                  const x = { ...model };
                  x.productStatus = e.target.value;
                  setModel(x);
                }}
              >
                <option>Select type</option>
                <option value='In House'>In House</option>
                <option value='Disposed'>Disposed</option>
                <option value='Returned to Client'>Returned to Client</option>
              </select>
            </div>
          </div> */}
          {/* <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='ssContact' className='form-label label-font'>
                SS Contact
              </label>
              <input
                type='text'
                className='form-control'
                id='ssContact'
                name='ssContact'
                value={model.ssContact}
                onChange={(e) => {
                  const x = { ...model };
                  x.ssContact = e.target.value;
                  setModel(x);
                }}
              />
            </div>
          </div> */}
          <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='enteredByOrOtherInfo' className='form-label label-font'>
                Entered By
                {/* </label>
              <input
                type='text'
                className='form-control'
                id='enteredByOrOtherInfo'
                name='enteredByOrOtherInfo'
                value={model.enteredByOrOtherInfo}
                onChange={(e) => {
                  const x = { ...model };
                  x.enteredByOrOtherInfo = e.target.value;
                  setModel(x);
                }}
              /> */}
              </label>
              <select
                className='form-select'
                id='enteredByOrOtherInfo'
                name='enteredByOrOtherInfo'
                value={model.enteredByOrOtherInfo}
                onChange={(e) => {
                  const x = { ...model };
                  x.enteredByOrOtherInfo = e.target.value;
                  setModel(x);
                }}
              >
                {props.employeeData?.map((value: any, index: any) => (
                  <option key={index} value={value.displayName}>
                    {value.displayName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='clientId' className='form-label label-font'>
                Client Name
              </label>
              <select
                className='form-select'
                id='clientId'
                name='clientId'
                value={model.clientId}
                onChange={(e) => {
                  const x = { ...model };
                  x.clientId = parseInt(e.target.value);
                  setModel(x);
                }}
              >
                {clientList?.map((value, index) => (
                  <option key={index} value={value.clientId}>
                    {value.clientName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-lg-4 col-sm-12'>
            <div className='mb-3'>
              <label htmlFor='returnDate' className='form-label label-font'>
                Destroy Date
              </label>
              <input
                type='date'
                className='form-control'
                id='returnDate'
                name='returnDate'
                value={model.returnDate !== null ? model.returnDate.split('T')[0] : ''}
                onChange={(e) => {
                  const x = { ...model };
                  x.returnDate = e.target.value;
                  setModel(x);
                }}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <Modal.Footer>
        <Button
          //className='secondary-color-sp bg-light btn-click-cancel px-4'
          className='mx-1 px-4'
          variant='outline-secondary'
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button className='primary-bg-color-sp btn-click px-4' onClick={submit}>
          Save
        </Button>
      </Modal.Footer>

      <ToastContainer className='p-3' position='middle-center'>
        <Toast bg='danger' onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header closeButton={true}>
            <strong className='me-auto'>{response.status}</strong>
          </Toast.Header>
          <Toast.Body>{response.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Modal>
  );
}

export default EditProjectSample;
