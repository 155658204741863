import React, { useState, useRef, useEffect } from 'react';
import styles from './businessClassTable.module.css';
import BusinessClassTableModal from '../BusinessClassTableModal/BusinessClassTableModal';
import axios from 'axios';
import { urlDashboard } from '../../../../endpoints';

function BusinessClassTable(props: any) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [responseModel, setResponseModel] = useState([]);
  const [selectedBusinessClass, setSelectedBusinessClass] = useState('');
  const innerTextRefs = useRef<any>([]); // Use an array to store refs for each cell
  const { dashboard } = props;

  useEffect(() => {
    if (responseModel.length > 0) setModalShow(true);
  }, [responseModel]);

  const handleCellClick = (businessClass: string, month: number) => {
    setSelectedBusinessClass(businessClass);
    setSelectedMonth(month);
    getProposalsByBillingCenter(businessClass, month);
  };

  const formatCurrency = (amount: any) =>
    amount !== 0 && amount
      ? amount.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
        })
      : '-';

  function reset() {
    setSelectedBusinessClass('');
    setSelectedMonth(0);
    setResponseModel([]);
  }

  function getProposalsByBillingCenter(selectedBusinessClass: string, selectedMonth: number) {
    const encodedBusinessComponent = encodeURIComponent(selectedBusinessClass);
    axios
      .get(
        `${urlDashboard}/GetProposalsByBillingCenter?billingCenter=${encodedBusinessComponent}&month=${selectedMonth}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
        }
      )
      .then((response) => {
        console.log(response.data);
        setResponseModel(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return dashboard ? (
    <>
      <table className={styles.businessClassTable}>
        <thead>
          <tr>
            <th>Business Class</th>
            {Array.from({ length: 12 }, (_, i) => (
              <th key={i}>
                {
                  [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                  ][i]
                }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[
            'Consumer Understanding',
            'Downers Grove',
            'Managed Services',
            'New York',
            'North Carolina Consulting',
            'Product Understanding',
            'Texas',
            'Training & Education',
            'Michigan'
          ].map((businessClass, index) => (
            <tr key={index}>
              <td>{businessClass}</td>
              {Array.from({ length: 12 }, (_, i) => (
                <td
                  className={styles.td}
                  onClick={() => {
                    const innerTextValue = innerTextRefs.current[index]?.[i]?.innerText;
                    if (innerTextValue !== '-') {
                      handleCellClick(businessClass, i);
                    } else {
                      return;
                    }
                  }}
                  key={i}
                >
                  <span
                    ref={(el) =>
                      innerTextRefs.current[index]
                        ? (innerTextRefs.current[index][i] = el)
                        : (innerTextRefs.current[index] = [el])
                    }
                  >
                    {formatCurrency(dashboard.finalData?.[index]?.totalAmount?.[i])}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <BusinessClassTableModal
        show={modalShow}
        month={selectedMonth}
        businessClass={selectedBusinessClass}
        responseModal={responseModel}
        onHide={() => {
          setModalShow(false);
          setResponseModel([]);
          reset();
        }}
        //onShow={() => getProposalsByBillingCenter(selectedBusinessClass, selectedMonth)}
      />
    </>
  ) : null;
}
export default BusinessClassTable;
