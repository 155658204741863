import React, { useContext, createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { urlContacts } from '../endpoints';

const ContactContext = createContext({});
const GetContactContext = createContext<any>(() => Promise.resolve());

export function useGetContacts() {
  const getContacts = useContext(GetContactContext);
  return getContacts;
}

export function useContactList() {
  const contactList = useContext(ContactContext) as any[];
  return contactList;
}

export default function ContactContextProvider({ children }: any) {
  const [contactList, setContactList] = useState<any[]>([]);

  function getContacts() {
    axios
      .get(`${urlContacts}/GetAllContacts`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        setContactList(response.data.data);
        // console.log(response.data.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <ContactContext.Provider value={contactList}>
      <GetContactContext.Provider value={getContacts}>{children}</GetContactContext.Provider>
    </ContactContext.Provider>
  );
}
