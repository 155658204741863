import axios from "axios";
import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Chart from "react-apexcharts";
import { urlProjects } from "../../endpoints";
import Table from "react-bootstrap/Table";
const StatusReport = () => {
  const [pending, setPending] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [scheduled, setScheduled] = useState(0);
  const [samplesReceived, setSamplesReceived] = useState(0);
  const [testComplete, setTestComplete] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  useEffect(() => {
    GetStatus();
  }, []);
  const GetStatus = () => {
    axios.get(`${urlProjects}/GetStatusCount`,{
    headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`}}).then((res) => {
      setPending(res.data.data.pending);
      setAccepted(res.data.data.accepted);
      setScheduled(res.data.data.scheduled);
      setSamplesReceived(res.data.data.samplesReceived);
      setTestComplete(res.data.data.testComplete);
      setCancelled(res.data.data.cancelled);
    });
  };
  return (
    <div className="app">
      <div className="row">
        <Card.Title>Project Status</Card.Title>
        <Table
          responsive
          hover
          size="sm"
          style={{ margin: "2% 0", display: "flex" }}
        >
          <thead className="shadow-none table-design" style={{ width: "50%" }}>
            <tr style={{ display: "flex", flexDirection: "column" }}>
              <th>Pending</th>
              <th>Accepted</th>
              <th>Scheduled</th>
              <th>Samples Received</th>
              <th>Test Complete</th>
              <th>Cancelled</th>
            </tr>
          </thead>
          <tbody style={{ width: "50%" }}>
            <tr
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                textAlign: "center",
              }}
            >
              <th>{pending}</th>
              <th>{accepted}</th>
              <th>{scheduled}</th>
              <th>{samplesReceived}</th>
              <th>{testComplete}</th>
              <th>{cancelled}</th>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default StatusReport;
