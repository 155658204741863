import React, { useRef, useState } from 'react';
import { SignInButton } from '../../auth/SignInButton';
import { SignOutButton } from '../../auth/SignOutButton';
import PrimaryButton from '../../components/utils/PrimaryButton/PrimaryButton';
import css from './signInOut.module.css';
import { Link } from 'react-router-dom';
import { loginRequest } from '../../auth/authConfig';
import { useMsal } from '@azure/msal-react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card';
import PrivacyModal from './PrivacyModal';

function SignInOut() {
  const { instance } = useMsal();
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function handleLogin(instance: any) {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(e);
    });
  }

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  function handleModal() {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }

  return (
    <>
      {showModal ? (
        <PrivacyModal showModal={showModal} setShowModal={setShowModal} handleModal={handleModal} />
      ) : (
        <div className='col-12' style={{ height: '100vh' }}>
          <div className='row m-0'>
            <Navbar bg='white' className='shadow-sm' style={{ height: '10vh' }}>
              <Navbar.Brand>
                <img src='/assets/img/LOGO.png' alt='Spectrum' className='py-2 px-4 img-fluid' />
              </Navbar.Brand>
            </Navbar>
            <div className={css['login-bg']}>
              <div className='col-12'>
                <div className='row m-0'>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className={css['center-Item']}>
                      <img src='/assets/img/login-banner.png' alt='Banner' className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12' style={{ maxWidth: '700px' }}>
                    <div className={css['center-Item']}>
                      <Card className={css['login-card']}>
                        <h1 className='primary-color-sp'>Login</h1>

                        <Card.Body className='mt-5'>
                          <Container>
                            <div className='col-12'>
                              <div className='row justify-content-center'>
                                {/* <div className='col-lg-8 col-sm-12 align-self-center'>
                                <div className='mb-5'>
                                  <div className='position-relative'>
                                    <input
                                    type='email'
                                      style={{paddingLeft: "35px"}}
                                      className='form-control'
                                      placeholder='Email'
                                      required
                                      />
                                      
                                      <i
                                      className={`${css["icon-input"]} fa fa-solid fa-envelope`}></i>
                                      </div>
                                </div>
                              </div> */}
                                {/* <div className='col-lg-8 col-sm-12 align-self-center'>
                                <div className='mb-5'>
                                <div className='position-relative'>
                                    <input
                                      type='password'
                                      className='form-control'
                                      placeholder='Password'
                                      required
                                      />
                                    <i
                                      className={`${css["icon-input"]} fa fa-solid fa-lock`}></i>
                                  </div>
                                  </div>
                              </div> */}
                                <div className='col-lg-8 col-sm-12 align-self-center px-5 mb-5'>
                                  <button
                                    className='btn primary-bg-color-sp text-white d-block w-100'
                                    type='button'
                                    disabled={!isChecked}
                                    onClick={() => handleLogin(instance)}
                                  >
                                    Login
                                  </button>
                                </div>
                                <div className='col-lg-8 col-sm-12 align-self-center'>
                                  <div className='mb-0'>
                                    <div className='form-check'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexCheckDefault'
                                        onChange={handleCheckboxChange}
                                      />
                                      <label
                                        className='form-check-label text-start'
                                        htmlFor='flexCheckDefault'
                                      >
                                        I agree to the Sensory Spectrum's Privacy Statement and
                                        Terms of Service.{' '}
                                        <span
                                          className='primary-color-sp text'
                                          onClick={handleModal}
                                        >
                                          View it Here.
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Container>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SignInOut;
