import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { urlClients } from '../endpoints';

const ClientListContext = createContext({});
const ContextGetClients = createContext<() => Promise<void>>(() => Promise.resolve());

export function useGetClients() {
  const getClients = useContext(ContextGetClients);
  return getClients;
}

export function useClientList() {
  const clientList = useContext(ClientListContext) as any[];
  return clientList;
}

export default function ClientContextProvider({ children }: any) {
  const [clientList, setClientList] = useState<any[]>([]);

  async function getClients() {
    //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios
      .get(`${urlClients}/GetClientList`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        setClientList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log('Request Header:', axios.defaults.headers.common['Authorization']);
  }

  return (
    <ClientListContext.Provider value={clientList}>
      <ContextGetClients.Provider value={getClients}>{children}</ContextGetClients.Provider>
    </ClientListContext.Provider>
  );
}
