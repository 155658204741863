import { ResponsiveCalendar } from '@nivo/calendar';

function Calendar(props: any) {
  return (
    <>
      <div style={{ height: '350px' }}>
        <ResponsiveCalendar
          data={props.data}
          from='2023-12-31'
          to='2024-12-31'
          emptyColor='#eeeeee'
          // colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
          maxValue={12000}
          colors={['#f47560', '#e8c1a0', '#97e3d5', '#61cdbb']}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          yearSpacing={40}
          monthBorderColor='#ffffff'
          dayBorderWidth={2}
          dayBorderColor='#ffffff'
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'row',
              translateY: 36,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              itemDirection: 'right-to-left'
            }
          ]}
        />
      </div>
    </>
  );
}

export default Calendar;
