import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './confirmModal.css';
import axios from 'axios';
import { urlProjects } from '../../../endpoints';
import { propTypes } from 'react-bootstrap/esm/Image';

function ConfirmModal(props: any) {
  const [show, setShow] = useState(props.show);

  function handleCancel() {
    props.setShow(false);
    props.setCommentModalShow(true);
    // props.setShowConfirm(true);
    // props.handleConfirmModal(false);
  }

  function handleDelete() {
    deleteItem();
    //props.handleConfirmModal(false);
    //props.handleSample();
    // if (props.handleSampleClose !== undefined) {
    //   props.handleSampleClose(false);
    // }

    //props.setShowConfirm(true)
  }

  function deleteItem() {
    axios
      .delete(`${urlProjects}/DeleteBillingNote/${props.noteId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then((res) => {
        //props.onHide(false);
        props.handleToast(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  // useEffect(() => {
  //   return () => {
  //     props.handleConfirmModal(false);
  //   };
  // }, [props]);

  return (
    // <div className='modal show' style={{ display: 'block', position: 'initial' }}>
    <Modal {...props} size='sm' data-backdrop='static' keyboard={false} centered>
      {/* <Modal.Dialog> */}
      <Modal.Header className='primary-bg-color-sp text-white'>
        <Modal.Title className=''>Confirm Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body className='bg-secondary-bg-color-sp'>
        <p>Delete the Note?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={() => {
            handleDelete();
            props.setShow(false);
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
      {/* </Modal.Dialog> */}
    </Modal>
    // </div>
  );
}

export default ConfirmModal;
