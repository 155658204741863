import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './businessClassTableModal.module.css';

export default function BusinessClassTableModal(props: any) {
  const [month, setMonth] = useState('');

  useEffect(() => {
    findDate(props.month);
  }, [props.month]);

  const findDate = (month: any) => {
    switch (month + 1) {
      case 1:
        setMonth('January');
        return;
      case 2:
        setMonth('February');
        return;
      case 3:
        setMonth('March');
        return;
      case 4:
        setMonth('April');
        return;
      case 5:
        setMonth('May');
        return;
      case 6:
        setMonth('June');
        return;
      case 7:
        setMonth('July');
        return;
      case 8:
        setMonth('August');
        return;
      case 9:
        setMonth('September');
        return;
      case 10:
        setMonth('October');
        return;
      case 11:
        setMonth('November');
        return;
      case 12:
        setMonth('December');
        return;
      default:
        setMonth('January');
        return;
    }
  };

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props.businessClass} - {month}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.responseModal.map((item: any, index: any) => (
          <div className={styles.modalBody} key={index}>
            <h4>{item.projectId}</h4>
            <p>{item.projectName}</p>
            <p>
              {item.proposalAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
              })}
            </p>
            {/* <p>{item.proposalDate}</p> */}
            <p>{new Date(item.proposalDate).toLocaleDateString('en-US')}</p>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
