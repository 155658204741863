import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import css from './ContactModel.module.css';
import { clientDTO } from '../../../DTOs/clients.model';
import { useEffect, useState } from 'react';
import { urlContacts, urlClients } from '../../../endpoints';
import axios, { AxiosResponse } from 'axios';
import useNewContactValidateAll from '../../../components/Validation/ContactValidations/useNewContactValidateAll';
import useNewContactValidateEdit from '../../../components/Validation/ContactValidations/useNewContactValidateEdit';
import { useClientList, useGetClients } from '../../../context/ClientContext';

function ContactModel(props: any) {
  const [model, setModel] = useState({
    contactId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    // contactNotes: '',
    modifiedDateTime: new Date(),
    clientId: 0,
    valid: true
  });

  const [validationState, setValidationState] = useState(false);
  const [editValidationState, setEditValidationState] = useState(false);
  const [show, setShow] = useState(false);
  // const [clientData, setClientData] = useState<any[]>([]);

  const [response, setResponse] = useState({
    status: false,
    message: ''
  });

  // const contactFormValidation: any = useNewContactValidateAll();
  // const contactFormEditValidation: any = useNewContactValidateEdit();
  const { validated, ValidateAll, validate, resetValidations } = useNewContactValidateAll();
  const { editValidated, EditValidateAll, editValidate, editResetValidations } =
    useNewContactValidateEdit();

  const clientData = useClientList();
  const getClients: () => Promise<void> = useGetClients();

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (props.contactId !== undefined) {
      axios
        .get(`${urlContacts}/${props.contactId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
        })
        .then((res) => {
          console.log(res);
          const x = { ...model };
          x.contactId = res.data.contactId;
          x.firstName = res.data.firstName;
          x.lastName = res.data.lastName;
          x.phoneNumber = res.data.phoneNumber;
          x.email = res.data.email;
          // x.contactNotes = res.data.contactNotes;
          x.modifiedDateTime =
            res.data.modifiedDateTime != null
              ? res.data.modifiedDateTime.split('T')[0]
              : res.data.modifiedDateTime;
          x.clientId = res.data.clientId;
          x.valid = res.data.valid;
          setModel(x);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props]);

  // function getClients() {
  //   axios
  //     .get(urlClients, {
  //       headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
  //     })
  //     .then((response: any) => {
  //       const totalAmountOfRecords = response.data.data.totalRecords;
  //       setClientData(response.data.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  function handleSubmit() {
    if (props.name === 'Add New Contact') {
      ValidateAll();
      // if(contactFormValidation.validated){
      //   submit();
      //   contactFormValidation.resetValidations();
      // }
    }
    if (props.name === 'Edit Contact') {
      EditValidateAll();
      // }if (contactFormEditValidation.validated){
      //   submit();
      //   contactFormEditValidation.resetValidations();
      // }
    }
  }

  useEffect(() => {
    setValidationState(validated);
  }, [validated]);

  useEffect(() => {
    setEditValidationState(editValidated);
  }, [editValidated]);

  useEffect(() => {
    if (editValidationState === true) {
      submit();
      editResetValidations();
    }
    if (validationState === true) {
      submit();
      resetValidations();
    }
  }, [validationState, editValidationState]);

  function submit() {
    var payload = JSON.stringify(model);
    if (editValidationState === true || validationState === true) {
      if (model.contactId !== undefined && model.contactId !== '') {
        axios
          .put(`${urlContacts}/${props.contactId}`, payload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
          })
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              props.onHide(false);
              setModel({
                contactId: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                // contactNotes: '',
                modifiedDateTime: new Date(),
                clientId: 0,
                valid: true
              });
              props.handleToast(res.data);
            } else {
              const c = { ...response };
              c.status = res.data.status;
              c.message = res.data.message;
              setResponse(c);
              setShow(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        axios
          .post(`${urlContacts}`, payload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
          })
          .then((res) => {
            if (res.status === 200) {
              props.onHide(false);
              setModel({
                contactId: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                // contactNotes: '',
                modifiedDateTime: new Date(),
                clientId: 0,
                valid: true
              });
              props.handleToast(res.data);
            } else {
              const c = { ...response };
              c.status = res.data.status;
              c.message = res.data.message;
              setResponse(c);
              setShow(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  function cancel() {
    props.onHide(false);
    setModel({
      contactId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      // contactNotes: '',
      modifiedDateTime: new Date(),
      clientId: 0,
      valid: true
    });
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className='primary-bg-color-sp text-white'>
        <Modal.Title id='contained-modal-title-vcenter' className='modeltitle'>
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='col-12'>
          <div className='row'>
            <input
              type='hidden'
              className='form-control'
              id='ContactId'
              value={model.contactId}
              onChange={(e) => {
                const x = { ...model };
                x.contactId = e.target.value;
                setModel(x);
              }}
            />
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='FirstName' className='form-label label-font'>
                  First Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='FirstName'
                  value={model.firstName}
                  onChange={(e) => {
                    const x = { ...model };
                    x.firstName = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='LastName' className='form-label label-font'>
                  Last Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='LastName'
                  value={model.lastName}
                  onChange={(e) => {
                    const x = { ...model };
                    x.lastName = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>

            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='PhoneNumber' className='form-label label-font'>
                  Phone Number
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='PhoneNumber'
                  value={model.phoneNumber}
                  onChange={(e) => {
                    const x = { ...model };
                    x.phoneNumber = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>

            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='email' className='form-label label-font'>
                  Email
                </label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  value={model.email}
                  onChange={(e) => {
                    const x = { ...model };
                    x.email = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
            {/* <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='ContactNotes' className='form-label label-font'>
                  Contact Note
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='ContactNotes'
                  value={model.contactNotes}
                  onChange={(e) => {
                    const x = { ...model };
                    x.contactNotes = e.target.value;
                    setModel(x);
                  }}
                /><div className='error'></div>
              </div>
            </div> */}

            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='ClientId' className='form-label label-font'>
                  Client
                </label>
                <select
                  title='Select Client'
                  className='form-select'
                  id='ClientId'
                  style={{ width: '240px' }}
                  value={model.clientId}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientId = parseInt(e.target.value);
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  {/* {clientData.map((x) => {
                    return (
                      <option key={x.clientId} value={x.clientId}>
                        {x.clientName}
                      </option>
                        <option selected>Select...</option> */}
                  {clientData
                    .slice() // Create a copy of the array to avoid mutating the original
                    .sort((a, b) => a.clientName.localeCompare(b.clientName)) // Sort alphabetically
                    .map((x) => (
                      <option key={x.clientId} value={x.clientId}>
                        {x.clientName}
                      </option>
                    ))}
                </select>
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='ValidClient' className='form-label label-font'>
                  Valid?
                </label>
                <select
                  title='Valid Client'
                  className='form-select'
                  id='ValidClient'
                  style={{ width: '240px' }}
                  value={model.valid ? '1' : '0'}
                  onChange={(e) => {
                    const newValue = e.target.value === '1'; // directly convert to boolean
                    setModel({ ...model, valid: newValue });
                  }}
                >
                  <option selected value='1'>
                    Yes
                  </option>
                  <option value='0'>No</option>
                </select>
                <div className='error'></div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          //className='secondary-color-sp bg-light btn-click-cancel px-4'
          className='mx-1 px-4'
          variant='outline-secondary'
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button className='primary-bg-color-sp btn-click px-4' onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ContactModel;
