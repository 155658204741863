import React from 'react';
import style from './threeYearMetrics.module.css';

function ThreeYearMetrics(props: any) {
  return props.dashboard !== undefined ? (
    <div>
      <table className={style.table}>
        <thead>
          <tr className={style.headingBoarder}>
            <th>Status</th>
            <th>{props.dashboard.threeYearMetrics[1].year}</th>
            <th>{props.dashboard.threeYearMetrics[2].year}</th>
            <th>{props.dashboard.threeYearMetrics[0].year}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.row}>
            <td>Not Accepted</td>
            <td>{props.dashboard.threeYearMetrics[1].cancelledCount}</td>
            <td>{props.dashboard.threeYearMetrics[2].cancelledCount}</td>
            <td>{props.dashboard.threeYearMetrics[0].cancelledCount}</td>
          </tr>
          <tr className={style.row}>
            <td>Proposal</td>
            <td>{props.dashboard.threeYearMetrics[1].proposalCount}</td>
            <td>{props.dashboard.threeYearMetrics[2].proposalCount}</td>
            <td>{props.dashboard.threeYearMetrics[0].proposalCount}</td>
          </tr>
          <tr className={style.row}>
            <td>Planning Execution</td>
            <td>{props.dashboard.threeYearMetrics[1].executionCount}</td>
            <td>{props.dashboard.threeYearMetrics[2].executionCount}</td>
            <td>{props.dashboard.threeYearMetrics[0].executionCount}</td>
          </tr>
          <tr className={style.row}>
            <td>Completed</td>
            <td>{props.dashboard.threeYearMetrics[1].completedCount}</td>
            <td>{props.dashboard.threeYearMetrics[2].completedCount}</td>
            <td>{props.dashboard.threeYearMetrics[0].completedCount}</td>
          </tr>
          <tr className={style.row}>
            <td className={style.total}>Total</td>
            <td className={style.total}>
              {props.dashboard !== undefined
                ? props.dashboard.threeYearMetrics[1].completedCount +
                  props.dashboard.threeYearMetrics[1].proposalCount +
                  props.dashboard.threeYearMetrics[1].executionCount +
                  props.dashboard.threeYearMetrics[1].cancelledCount
                : ''}
            </td>
            <td className={style.total}>
              {props.dashboard !== undefined
                ? props.dashboard.threeYearMetrics[2].completedCount +
                  props.dashboard.threeYearMetrics[2].proposalCount +
                  props.dashboard.threeYearMetrics[2].executionCount +
                  props.dashboard.threeYearMetrics[2].cancelledCount
                : ''}
            </td>
            <td className={style.total}>
              {props.dashboard !== undefined
                ? props.dashboard.threeYearMetrics[0].completedCount +
                  props.dashboard.threeYearMetrics[0].proposalCount +
                  props.dashboard.threeYearMetrics[0].executionCount +
                  props.dashboard.threeYearMetrics[0].cancelledCount
                : ''}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default ThreeYearMetrics;
