import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthenticateDashboard } from '../hooks/useAuthenticateDashboard';
import { jwtDecode } from 'jwt-decode';

function PrivateRoute(props) {
  // const [auth, setAuth] = useState(false);
  // const { checkToken } = useAuthenticateDashboard();
  // const token = sessionStorage.getItem('accessToken');

  // Return Navigate if not authenticated, otherwise render Outlet
  return props.auth ? <Outlet /> : <Navigate to='/' />;
}

export default PrivateRoute;
