import './Reporting.css';
import Card from 'react-bootstrap/Card';
import ReportingColumnChart from './ReportingColumn';
import ReportingChart from './DailyUpdateChart';
import ReportingOverall from './ReportingOverall';
import React, { useEffect, useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { Sales } from './Sales';
//import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DatePicker, Space } from 'antd';
import Top10Sales from './Top10Sales';
import StatusReport from './StatusReport';
import StatusReportTeamLead from './StatusReportTeamLead';
import axios from 'axios';
import { urlProjects, urlSales } from '../../endpoints';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { accessTokenRequest } from '../../auth/authConfig';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';

const { RangePicker } = DatePicker;

export default function ReportingPage() {
  const [open, setOpen] = useState(false);
  const [fromDateLatest, setFromDateLatest] = useState(new Date());
  const [fromDateComparison, setFromDateComparison] = useState(new Date());
  const [toDateLatest, setToDateLatest] = useState(new Date().setMonth(new Date().getMonth() + 1));
  const [TeamLeadsStatus, setTeamLeadsStatus] = useState([]);
  const [toDateComparison, setToDateComparison] = useState(new Date().setMonth(new Date().getMonth() + 1));
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(11))
  });

  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  // useEffect(() => {
  //   axios.get(`${urlSales}/GetProjectStatusByProjectLead`).then((res: any) => {
  //     setTeamLeadsStatus(res.data.data);
  //     console.log(TeamLeadsStatus);
  //   });
  // }, []);

  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken);
          getSales();
        })
        .then((response: any) => {
          setTeamLeadsStatus(response.data.data.data);
        })
        .catch((error: any) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress, apiData]);

  function getSales() {
    axios
      .get(`${urlSales}/GetProjectStatusByProjectLead`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((res: any) => {
        setTeamLeadsStatus(res.data.data);
        console.log(TeamLeadsStatus);
      });
  }

  return (
    <>
      <div className='col-12'>
        <div className='row m-0'>
          <div>
            <div className='container-fluid'>
              <div className='py-4 heading'>Reporting Dashboard</div>

              <div className='col-12 py-5'>
                <div className='row'>
                  <div className=' col-lg-3 col-sm-12'>
                    <div className='mb-3'>
                      <Card>
                        <Card.Body>
                          <Card.Title>
                            <div className='d-flex justify-content-between'>
                              <span>Latest Sales</span>
                              <RangePicker
                                onChange={(e: any) => {
                                  setFromDateLatest(e[0].$d);
                                  setToDateLatest(e[1].$d);
                                }}
                              />
                            </div>
                          </Card.Title>
                          <Card.Text>
                            <Sales SalesType={'Latest'} fromDateTime={fromDateLatest} toDateTime={toDateLatest} />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className='col-lg-3 col-sm-12'>
                    <div className='mb-3'>
                      <Card>
                        <Card.Body>
                          <Card.Title>
                            <div className='d-flex justify-content-between'>
                              <span>Comparisan</span>
                              <RangePicker
                                onChange={(e: any) => {
                                  setFromDateComparison(e[0].$d);
                                  setToDateComparison(e[1].$d);
                                }}
                              />
                            </div>
                          </Card.Title>
                          <Card.Text>
                            <Sales SalesType={'Comparison'} fromDateTime={fromDateComparison} toDateTime={toDateComparison} />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className='col-lg-3 col-sm-12'>
                    <div className='mb-3'>
                      <Card>
                        <Card.Body>
                          <Card.Title>OverAll Categories Count</Card.Title>
                          <Card.Subtitle className='mb-2 text-muted'></Card.Subtitle>
                          <Card.Text>
                            <ReportingOverall />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className='col-lg-3 col-sm-12'>
                    <div className='mb-3'>
                      <Card>
                        <Card.Body>
                          <Card.Title>List of top 10 sellers</Card.Title>
                          <Card.Text>
                            <Top10Sales></Top10Sales>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  {/* <div className='col-lg-6 col-sm-12'>
                    <div className='mb-3'>
                      <Card>
                        <Card.Body>
                          <Card.Title>Overall Performance</Card.Title>
                          <Card.Text>
                            <ReportingColumnChart />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  <div className='col-lg-3 col-sm-12'>
                    <div className='mb-3'>
                      <Card>
                        <Card.Body>
                          <Card.Title>Daily Update</Card.Title>
                          <Card.Subtitle className='mb-2 text-muted'></Card.Subtitle>
                          <Card.Text>
                            <ReportingChart />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div> */}

                  {/* <div className='col-lg-3 col-sm-12'>
                    <div className='mb-3'>
                      <Card>
                        <Card.Body>
                          <Card.Title>OverAll Categories Count</Card.Title>
                          <Card.Subtitle className='mb-2 text-muted'></Card.Subtitle>
                          <Card.Text>
                            <ReportingOverall />
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div> */}
                </div>
              </div>
              <Card>
                <Card.Body>
              <table>
                <tr>
                  <th>
                    Projects
                  </th>
                  <th>
                    Billing Status
                  </th>
                  <th>
                    Billed
                  </th>
                  </tr>
                  <tr>
                  <td>data</td>
                  <td>data</td>
                  <td>data</td>
                  </tr>
              </table>
                </Card.Body>
              </Card>
              <div className='col-12'>
                <div className='row'>
                  <>
                    <h3>All Projects</h3>
                    <div className=' col-lg-3 col-sm-12'>
                      <div className='mb-3'>
                        <Card>
                          <Card.Body>
                            <Card.Text>
                              <StatusReport></StatusReport>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                    <h3>Projects By Team Leads</h3>
                    {TeamLeadsStatus.length > 0 &&
                      TeamLeadsStatus.map((x: any) => {
                        return (
                          <div className=' col-lg-3 col-sm-12' key={x.projectLead}>
                            <div className='mb-3'>
                              <Card>
                                <Card.Body>
                                  <Card.Text>
                                    <StatusReportTeamLead Data={x}></StatusReportTeamLead>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        );
                      })}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
