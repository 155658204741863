// useSessionTimeout.js

import { useEffect, useState } from 'react';

const useSessionTimeout = (timeoutDuration = 10 * 60 * 1000, onTimeout: any) => {
  const [isSessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    let sessionTimeout: any;

    const resetTimeout = () => {
      clearTimeout(sessionTimeout);
      startTimeout();
    };

    const startTimeout = () => {
      sessionTimeout = setTimeout(() => {
        setSessionExpired(true);
        if (typeof onTimeout === 'function') {
          onTimeout();
        }
      }, timeoutDuration);
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    // Start the initial timeout
    startTimeout();

    // Add event listeners to reset the timeout on user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);

    // Clear the timeout and remove event listeners on component unmount
    return () => {
      clearTimeout(sessionTimeout);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
    };
  }, [onTimeout, timeoutDuration]);

  return isSessionExpired;
};

export default useSessionTimeout;
