import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './clientModel.css';
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { urlClients } from '../../../endpoints';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../auth/authConfig';
import useNewClientValidateAll from '../../../components/Validation/ClientValidations/useNewClientValidateAll';
import useNewClientValidateEdit from '../../../components/Validation/ClientValidations/useNewClientValidateEdit';
import { useUserList } from '../../../context/UserContext';

function ClientModel(props: any) {
  const [model, setModel] = useState({
    clientId: '',
    clientName: '',
    clientManager: '',
    poRequired: '',
    clientStatus: '',
    clientAddress: '',
    clientCity: '',
    clientState: '',
    clientZip: '',
    // modifiedDateTime: 0,
    contacts: ''
  });

  // const clientFormValidation: any = useNewClientValidateAll();
  // const clientFormEditValidation: any = useNewClientValidateEdit();
  const [validationState, setValidationState] = useState(false);
  //const [employeeData, setEmployeeData] = useState([]);
  const [employeeOption, setEmployeeOption] = useState<any[]>([]);
  const [editValidationState, setEditValidationState] = useState(false);
  const { instance, accounts } = useMsal();
  const { validated, ValidateAll, validate, resetValidations } = useNewClientValidateAll();
  const { editValidated, EditValidateAll, editValidate, editResetValidations } =
    useNewClientValidateEdit();

  const [show, setShow] = useState(false);

  const [response, setResponse] = useState({
    status: false,
    message: ''
  });

  const employeeData = useUserList();

  const accessToken = sessionStorage.getItem('accessToken');

  useEffect(() => {
    // RequestAccessToken();
    if (props.clientId !== undefined) {
      axios
        .get(`${urlClients}/${props.clientId}`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then((res) => {
          const x = { ...model };
          x.clientId = res.data.clientId;
          x.clientManager = res.data.clientManager;
          x.clientName = res.data.clientName;
          x.poRequired = res.data.poRequired;
          x.clientStatus = res.data.clientStatus;
          x.clientAddress = res.data.clientAddress;
          x.clientCity = res.data.clientCity;
          x.clientState = res.data.clientState;
          x.clientZip = res.data.clientZip;
          x.contacts = res.data.contacts;
          // x.modifiedDateTime =
          //   res.data.modifiedDateTime != null
          //     ? res.data.modifiedDateTime.split('T')[0]
          //     : res.data.modifiedDateTime;
          setModel(x);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props]);

  function handleSetEmployeeOption(data: any) {
    var employeeOptn = [];
    for (var i = 0; i < data.length; i++) {
      var objData = {
        id: data[i].id,
        displayName: data[i].displayName
      };
      employeeOptn.push(objData);
    }
    setEmployeeOption(employeeOptn);
  }

  function handleSubmit() {
    if (props.name === 'Add New Client') {
      ValidateAll();
    } else if (props.name === 'Edit Client') {
      EditValidateAll();
    }
  }

  useEffect(() => {
    setValidationState(validated);
  }, [validated]);

  useEffect(() => {
    setEditValidationState(editValidated);
  }, [editValidated]);

  useEffect(() => {
    if (editValidationState === true) {
      submit();
      editResetValidations();
    }
    if (validationState === true) {
      submit();
      resetValidations();
    }
  }, [validationState, editValidationState]);

  function submit() {
    var payload = JSON.stringify(model);
    if (editValidationState === true || validationState === true) {
      if (model.clientId !== undefined && model.clientId !== '') {
        axios
          .put(`${urlClients}/${model.clientId}`, payload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            }
          })
          .then((res) => {
            //console.log(res);
            if (res.status === 200) {
              const x = { ...model };
              x.clientId = '';
              x.clientManager = '';
              x.clientName = '';
              x.poRequired = '';
              x.clientStatus = '';
              x.clientAddress = '';
              x.clientCity = '';
              x.clientState = '';
              x.clientZip = '';
              x.contacts = '';
              setModel(x);
            } else {
              const c = { ...response };
              c.status = res.status !== 204 ? false : true;
              c.message = res.statusText;
              setResponse(c);
              setShow(true);
              //console.log(payload);
            }
            props.handleToast(res.data);
            props.onHide(false);
            // props.handleModalShow(false);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        axios
          .post(`${urlClients}`, payload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            }
          })
          .then((res) => {
            if (res.status === 200) {
              const x = { ...model };
              x.clientId = '';
              x.clientManager = '';
              x.clientName = '';
              x.poRequired = '';
              x.clientStatus = '';
              x.clientAddress = '';
              x.clientCity = '';
              x.clientState = '';
              x.clientZip = '';
              x.contacts = '';
              setModel(x);
              props.handleToast(res.data);
              props.onHide(false);
              // props.handleModalShow(false);
            } else {
              const c = { ...response };
              c.status = res.status !== 204 ? false : true;
              c.message = res.statusText;
              setResponse(c);
              setShow(true);
            }
            props.handleToast(res.data);
            setShow(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  function cancel() {
    props.onHide(false);
    const x = { ...model };
    x.clientId = '';
    x.clientManager = '';
    x.clientName = '';
    x.poRequired = '';
    x.clientStatus = '';
    x.clientAddress = '';
    x.clientCity = '';
    x.clientState = '';
    x.clientZip = '';
    x.contacts = '';
    // x.modifiedDateTime = 0;
    setModel(x);
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      keyboard={false}
      centered
    >
      <Modal.Header className='primary-bg-color-sp text-white'>
        <Modal.Title id='contained-modal-title-vcenter' className='modeltitle'>
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='col-12'>
          <div className='row'>
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='ClientName' className='form-label label-font'>
                  Client Name
                </label>
                <input
                  title='Client Name'
                  type='text'
                  className='form-control'
                  id='clientName'
                  name='clientName'
                  value={model.clientName}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientName = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='streetAddress' className='form-label label-font'>
                  Street Address
                </label>
                <input
                  title='Street Address'
                  type='text'
                  className='form-control'
                  id='streetAddress'
                  name='streetAddress'
                  value={model.clientAddress}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientAddress = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='city' className='form-label label-font'>
                  City
                </label>
                <input
                  title='City'
                  type='text'
                  className='form-control'
                  id='city'
                  name='city'
                  value={model.clientCity}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientCity = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='clientState' className='form-label label-font'>
                  State
                </label>
                <input
                  title='State'
                  type='text'
                  className='form-control'
                  id='state'
                  name='state'
                  value={model.clientState}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientState = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='zip' className='form-label label-font'>
                  Zip
                </label>
                <input
                  title='Zip'
                  type='text'
                  className='form-control'
                  id='zip'
                  name='zip'
                  value={model.clientZip}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientZip = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div>
          </div>
          <div className='row'>
            {/* <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='clientManager' className='form-label label-font'>
                  Client Manager
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='clientManager'
                  name='clientManager'
                  value={model.clientManager}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientManager = e.target.value;
                    setModel(x);
                  }}
                />
                <div className='error'></div>
              </div>
            </div> */}
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='clientManager' className='form-label label-font'>
                  Client Manager
                </label>
                <select
                  className='form-select'
                  id='clientManager'
                  name='clientManager'
                  value={model.clientManager}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientManager = e.target.value;
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  {employeeData?.map((value: any, index: any) => (
                    <option key={index} value={value.displayName}>
                      {value.displayName}
                    </option>
                  ))}
                </select>
                <div className='error'></div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='clientStatus' className='form-label label-font'>
                  Client status
                </label>
                <select
                  className='form-select'
                  id='clientStatus'
                  name='clientStatus'
                  value={model.clientStatus}
                  onChange={(e) => {
                    const x = { ...model };
                    x.clientStatus = e.target.value;
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  <option value='Inactive'>Inactive</option>
                  <option value='New'>New</option>
                  <option value='Lapsed'>Lapsed</option>
                  <option value='Active'>Active</option>
                </select>
                <div className='error'></div>
              </div>
            </div>

            <div className='col-lg-3 col-sm-12'>
              <div className='mb-3'>
                <label htmlFor='poRequired' className='form-label label-font'>
                  PO Required?
                </label>
                <select
                  className='form-select'
                  id='poRequired'
                  name='poRequired'
                  value={model.poRequired.toString().toLowerCase()}
                  onChange={(e) => {
                    const x = { ...model };
                    x.poRequired = JSON.parse(e.target.value.toString().toLowerCase());
                    setModel(x);
                  }}
                >
                  <option selected>Select...</option>
                  <option value='true'>Yes</option>
                  <option value='false'>No</option>
                </select>
                <div className='error'></div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          //className='secondary-color-sp bg-light btn-click-cancel px-4'
          variant='outline-secondary'
          className='mx-1 px-4'
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button className='primary-bg-color-sp btn-click px-4' onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
      {/* <ToastContainer className='p-3' position='middle-center'>
        <Toast bg='danger' onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header closeButton={true}>
            <strong className='me-auto'>{response.status}</strong>
          </Toast.Header>
          <Toast.Body>{response.message}</Toast.Body>
        </Toast>
      </ToastContainer> */}
    </Modal>
  );
}

export default ClientModel;
