import React, { useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { NavLink } from 'react-router-dom';
import { SignOutButton } from '../../../auth/SignOutButton';
import css from './bottombar.module.css';

export default function BottomBar(isOpenState: any) {
  const { instance } = useMsal();

  return (
    <>
      <div className={css.bottomBar}>
        <div className={css.container}>
          {/* <div className='icon-container-container'> */}
          <div className={css.linkContainer}>
            <NavLink to='/'>Projects</NavLink>
            <NavLink to='/Contacts'>Contacts</NavLink>
            <NavLink to='/clients'>Clients</NavLink>
            <NavLink to='/projectSample'>Samples</NavLink>
            {/* <NavLink to='/Billing'>Billing</NavLink> */}
            {/* <NavLink to='/reporting'>Reports</NavLink> */}
          </div>
        </div>
      </div>
    </>
  );
}
