import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function HelpUs() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDocs() {
    setAnchorEl(null);
    window.open(
      'https://sensoryspectrum.sharepoint.com/sites/SupportServices/SitePages/Project-Management-Database.aspx'
    );
  }

  function handleFeedbackForm() {
    setAnchorEl(null);
    window.open('https://forms.office.com/r/GZe3CTAnv2');
  }

  const handleMail = () => {
    window.open('mailto:jnelson@sensoryspectrum.com?subject=!Project DB Support');
    setAnchorEl(null);
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 5,
      marginTop: -60,
      minWidth: 233,
      // height: 555,
      height: 200,

      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0'
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5)
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
        }
      }
    }
  }));

  return (
    <>
      <div className='helpUs'>
        <svg
          role='button'
          className=' mx-2 buttonstyle'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 62.375 62.375'
          width='62.375'
          height='62.375'
          id='demo-customized-button'
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          variant='contained'
          disableelevation='true'
          onClick={handleClick}
          endicon={<KeyboardArrowDownIcon />}
        >
          <path
            d='M24.187,2A22.187,22.187,0,1,0,46.375,24.187,22.187,22.187,0,0,0,24.187,2Zm-.305,35.846a2.288,2.288,0,1,1,2.288-2.288,2.288,2.288,0,0,1-2.288,2.288Zm1.9-11.177v2.385a1.9,1.9,0,0,1-1.8,1.886h-.153a1.858,1.858,0,0,1-1.928-1.8V25.311a1.65,1.65,0,0,1,1.553-1.817c2.177-.166,5.8-.971,5.8-4.507,0-2.538-1.955-4.257-4.756-4.257a7.363,7.363,0,0,0-5.547,2.662,1.886,1.886,0,0,1-3.259-1.248,1.983,1.983,0,0,1,.6-1.387,10.775,10.775,0,0,1,8.32-3.73c5.131,0,8.709,3.189,8.709,7.766,0,4.229-2.732,7.058-7.53,7.877Z'
            fill='#027e7e'
          />
        </svg>

        <div>
          <StyledMenu
            id='demo-customized-menu'
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDocs} disableRipple style={{ marginLeft: '-7px' }}>
              <svg
                marginLeft='-8px'
                width='30px'
                height='16px'
                viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                class='bi bi-book'
              >
                <path d='M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z' />
              </svg>
              <p style={{ marginTop: '5%', marginLeft: '2%' }}>Help & documentation</p>
            </MenuItem>
            <MenuItem onClick={handleMail} disableRipple>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16px'
                height='16px'
                viewBox='0 0 23.749 21.766'
              >
                <g id='Group_782' data-name='Group 782' transform='translate(-2205.251 -8389.875)'>
                  <path
                    id='message-right-svgrepo-com'
                    d='M24.431,5.25H5.818A2.068,2.068,0,0,0,3.75,7.318V19.727a2.068,2.068,0,0,0,2.068,2.068H7.886L6.843,26.016a13.665,13.665,0,0,0,3.734-1.437,19.83,19.83,0,0,0,3.513-2.783h10.34A2.068,2.068,0,0,0,26.5,19.727V7.318A2.068,2.068,0,0,0,24.431,5.25Z'
                    transform='translate(2202.001 8385.125)'
                    fill='none'
                    stroke='#000'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='1'
                  />
                  <g
                    id='Ellipse_92'
                    data-name='Ellipse 92'
                    transform='translate(2211 8397)'
                    stroke='#000'
                    stroke-width='1'
                  >
                    <circle cx='1.5' cy='1.5' r='1.5' stroke='none' />
                    <circle cx='1.5' cy='1.5' r='1' fill='none' />
                  </g>
                  <g
                    id='Ellipse_93'
                    data-name='Ellipse 93'
                    transform='translate(2216 8397)'
                    stroke='#000'
                    stroke-width='1'
                  >
                    <circle cx='1.5' cy='1.5' r='1.5' stroke='none' />
                    <circle cx='1.5' cy='1.5' r='1' fill='none' />
                  </g>
                  <g
                    id='Ellipse_94'
                    data-name='Ellipse 94'
                    transform='translate(2221 8397)'
                    stroke='#000'
                    stroke-width='1'
                  >
                    <circle cx='1.5' cy='1.5' r='1.5' stroke='none' />
                    <circle cx='1.5' cy='1.5' r='1' fill='none' />
                  </g>
                </g>
              </svg>
              <p style={{ marginTop: '6%', marginLeft: '6%' }}>Email support</p>
            </MenuItem>
            <MenuItem onClick={handleFeedbackForm} disableRipple>
              <svg
                version='1.1'
                id='Layer_1'
                // xmlns='http://www.w3.org/2000/svg'
                // xmlns:xlink='http://www.w3.org/1999/xlink'
                width='24px'
                height='24px'
                viewBox='0 0 64 64'
                enable-background='new 0 0 64 64'
                // xml:space='preserve'
                fill='#000000'
              >
                <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
                <g id='SVGRepo_iconCarrier'>
                  {' '}
                  <polygon
                    fill='none'
                    stroke='#000000'
                    stroke-width='2'
                    stroke-miterlimit='10'
                    points='55,1 55,54 59,62 63,54 63,1 '
                  ></polygon>{' '}
                  <line
                    fill='none'
                    stroke='#000000'
                    stroke-width='2'
                    stroke-miterlimit='10'
                    x1='55'
                    y1='11'
                    x2='63'
                    y2='11'
                  ></line>{' '}
                  <polyline
                    fill='none'
                    stroke='#000000'
                    stroke-width='2'
                    stroke-miterlimit='10'
                    points='14,8 1,8 1,63 45,63 45,8 32,8 '
                  ></polyline>{' '}
                  <polygon
                    fill='none'
                    stroke='#000000'
                    stroke-width='2'
                    stroke-miterlimit='10'
                    points='27,5 27,1 19,1 19,5 15,5 13,13 33,13 31,5 '
                  ></polygon>{' '}
                </g>
              </svg>

              <p style={{ marginTop: '6%', marginLeft: '6%' }}>Feedback Form</p>
            </MenuItem>
            {/* <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleClose} disableRipple>
              Keyboard shortcuts
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              What's new?
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              Join us
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleClose} disableRipple>
              <p style={{ color: "grey" }}>Twitter - @NotionHQ</p>
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              <p style={{ color: "grey" }}>Terms & privacy </p>
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              <p style={{ color: "grey" }}>Status</p>
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleClose} disableRipple>
              <p style={{ color: "grey" }}>Notion 2.18.23.10.26.30</p>
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              <p style={{ color: "grey" }}>Updated 19 hours ago</p>
            </MenuItem> */}
          </StyledMenu>
        </div>
      </div>
    </>
  );
}

export default HelpUs;
