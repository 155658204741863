import React, { useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { SignOutButton } from '../../../auth/SignOutButton';
import './sidebar.css';

export default function Sidebar(props: any) {
  const { instance } = useMsal();

  function handleLogout(instance: any) {
    localStorage.removeItem('msGraphToken');
    instance.logoutRedirect().catch((e: any) => {
      console.error(e);
    });
  }

  const [isOpen, setIsOpen] = useState(false);
  const [storedPosition, setStoredPosition] = useState(
    JSON.parse(sessionStorage.getItem('trianglePosition')!)
  );
  const [position, setPosition] = useState<string[]>([
    '0px',
    '40px',
    '80px',
    '120px',
    '160px',
    '200px',
    '240px',
    '440px',
    '480px'
  ]);

  useEffect(() => {
    trianglePosition(storedPosition);
    sessionStorage.setItem('trianglePosition', JSON.stringify(storedPosition));
  }, [storedPosition]);

  function trianglePosition(position: string) {
    document.querySelector('.triangle')?.setAttribute('style', `top: ${position}`);
  }

  function activateSlideOut() {
    setIsOpen(true);
    document.getElementsByClassName('slider')[0].setAttribute('class', 'sliderOut');
    document
      .getElementsByClassName('slider-content-container')[0]
      .setAttribute('style', 'visibility: visible;');
  }
  function deactivateSlideOut() {
    setIsOpen(false);
    document.getElementsByClassName('sliderOut')[0].setAttribute('class', 'slider');
    document
      .getElementsByClassName('slider-content-container')[0]
      .setAttribute('style', 'visibility: hidden;');
  }

  return (
    <>
      {/* <div className='slider'>
        <div className='slider-content-container'>
          <div className='slider-content'>
            <ul>
              <li>
                <Link
                  onClick={() => {
                    deactivateSlideOut();
                    trianglePosition(position[1]);
                  }}
                  to='/clients'
                >
                  Clients
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    deactivateSlideOut();
                    trianglePosition(position[1]);
                  }}
                  to='/contacts'
                >
                  Contacts
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className='sidebar'>
        <div className='iconMenu'>
          {/* <div className='icon-container-container'> */}
          <div className='icon-container'>
            <div className='triangle'></div>
            <Link
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Projects'
              className='home'
              onClick={() => {
                if (isOpen === true) deactivateSlideOut();
                // trianglePosition(position[0]);
                setStoredPosition(position[0]);
              }}
              to='/'
            />
            {/* <Link
              className='person'
              onClick={() => {
                isOpen === false ? activateSlideOut() : deactivateSlideOut();
                trianglePosition(position[2]);
              }}
              to='/clients'
            /> */}

            <Link
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Clients'
              className='person'
              onClick={() => {
                // trianglePosition(position[1]);
                setStoredPosition(position[1]);
              }}
              to='/clients'
            />

            <Link
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Contacts'
              className='contacts'
              onClick={() => {
                // trianglePosition(position[2]);
                setStoredPosition(position[2]);
              }}
              to='/Contacts'
            />
            <Link
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Billing Center'
              className='billing'
              onClick={() => {
                // trianglePosition(position[3]);
                setStoredPosition(position[3]);
              }}
              to='/Billing'
            />
            <Link
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Project Sample'
              className='projectSample'
              onClick={() => {
                // trianglePosition(position[4]);
                setStoredPosition(position[4]);
              }}
              to='/projectSample'
            />
            {props.auth ? (
              <Link
                data-bs-toggle='tooltip'
                data-bs-placement='right'
                title='Dashboard'
                className='dashboard'
                onClick={() => {
                  // trianglePosition(position[4]);
                  setStoredPosition(position[5]);
                }}
                to='/dashboard'
              />
            ) : null}
            {/* <Link
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            title='Reporting'
            className="reporting"
            onClick={()=>trianglePosition(position[6])}
            to='/reporting'
            /> */}
            {/* <Link
                data-bs-toggle='tooltip'
                data-bs-placement='right'
                title='Employees'
                className='employees'
                onClick={() => trianglePosition(position[3])}
                to='/Employees'
              /> */}
            {/* <a
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Help'
              className='screnout'
              onClick={() => trianglePosition(position[7])}
            /> */}
            {/* <a
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Sign Out'
              className='helpus'
              onClick={() => handleLogout(instance)}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}
