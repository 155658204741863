import axios from "axios";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
export const Sales = ({ SalesType, fromDateTime, toDateTime }) => {
  const [categories, setcategories] = useState(
    SalesType === "Comparison" ? ["This year", "Last Year"] : ["Latest Sales"],
  );
  const [options, SetOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: "Sales",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  });
  const [data, setdata] = useState([]);
  const [series, setseries] = useState([
    {
      name: "Net Profit",
      data: data,
      color: "#2b9191",
    },
  ]);

  useEffect(() => {
    if (SalesType === "Comparison") {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/api/Sales/SalesByRanage?fromDateTime=${new Date(
            fromDateTime,
          ).toISOString()}&toDateTime=${new Date(toDateTime).toISOString()}`,
          {headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`}}
        )
        .then((res) => {
          let arr = [];
          arr.push(res.data.data.sales);
          arr.push(res.data.data.lastYearSale);
          setseries((pre) => [
            {
              name: "Net Profit",
              data: arr,
              color: "#2b9191",
            },
          ]);
        });
    } else {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/api/Sales/LatestSalesByRanage?fromDateTime=${new Date(
            fromDateTime,
          ).toISOString()}&toDateTime=${new Date(toDateTime).toISOString()}`,
          {headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`}}
        )
        .then((res) => {
          console.log(res.data.data);
          let arr = [];
          arr.push(res.data.data);
          setseries((pre) => [
            {
              name: "Net Profit",
              data: arr,
              color: "#2b9191",
            },
          ]);
        });
    }
  }, [SalesType, fromDateTime, toDateTime]);

  return (
    <div className='app'>
      <div className='row'>
        <div id='chart'>
          {series.length !== 0 && (
            <Chart options={options} series={series} type='bar' height={350} />
          )}
        </div>
      </div>
    </div>
  );
};
