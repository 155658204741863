import { useNavigate } from 'react-router-dom';
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import './billing.css';
import Pagination from '../../../components/utils/Pagination/Pagination';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import BillingChart from './billingChart';
import BillingChartlines from './BillingChartlines';
import BillingPieChart from './BillingPieChart';
import BillingColumnChart from './BillingColumnChart';
import BillingModel from '../BillingModel/billingModel';
import axios from 'axios';
import { urlClients, urlProjects, urlSales } from '../../../endpoints';
import { stat } from 'fs/promises';
import { Collapse } from 'react-bootstrap';
import { loginRequest } from '../../../auth/authConfig';
import CommentModel from '../CommentModel/CommentModel';
import ReportModal from '../ReportModal/ReportModal';
import SampleModel from '../SampleModel/sampleModel';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { accessTokenRequest } from '../../../auth/authConfig';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { report } from 'process';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { useUserList } from '../../../context/UserContext';
import { useContactList } from '../../../context/ContactContext';
import { useClientList } from '../../../context/ClientContext';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Button from 'react-bootstrap/Button';
import { set } from 'react-hook-form';
import useExclusionList from '../../../hooks/useExclusionList';
import TableNumberSelect from '../../../components/common/TableNumberSelect/TableNumberSelect';

export default function BillingPage() {
  const [commentModalShow, setCommentModalShow] = React.useState(false);
  const [sampleModalShow, setSampleModalShow] = React.useState(false);
  const [confirmModalShow, setConfirmModalShow] = React.useState(false);
  const [invoiceConfirmModalShow, setInvoiceConfirmModalShow] = React.useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [clientData, setClientData] = useState<any[]>([]);
  const [totalAmountOfPages, setTotalAmountOfPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [render, setRender] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const navigate = useNavigate();
  const [initialStatuses, SetInitialStatuses] = useState(null);
  const [defaultEmail, setDefaultEmail] = useState('JoelNelson@qbsr.onmicrosoft.com');

  const [modalShow, setModalShow] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('Add Billing');

  const [sales, setSales] = useState<any[]>([]);
  const [project, setProject] = useState<any[]>([]);
  //const [clientList, setClientList] = useState<any[]>([]);
  const [checked, setChecked] = useState(true);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [FilterArray, SetFilterArray] = useState([]);
  const [overallCategory, SetOverallCategory] = useState('');
  const [clientFilter, SetClientFilter] = useState('');
  const [billingCenter, SetBillingCenter] = useState('');
  const [projectManager, SetProjectManager] = useState('');
  const [proposalDate, SetProposalDate] = useState('');
  const [projectType, SetProjectType] = useState('');
  const [projectStatus, SetProjectStatus] = useState('');
  const [panelType, SetPanelType] = useState('');
  const [operator, SetOperator] = useState('OR');
  const [projectId, setProjectId] = useState(0);
  const [noteId, setNoteId] = useState(null);
  const [totalCharges, setTotalCharges] = React.useState(0);
  const [totalPurchaseOrders, setTotalPurchaseOrders] = React.useState(0);
  const [proposalAmount, setProposalAmount] = React.useState(0);
  const [sortWith, SetSortWith] = useState('');
  const [sortBy, SetSortBy] = useState('dec');
  const [SortDialogopen, setSortDialogopen] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);
  const [rowValue, setRowValue] = useState<any[]>([]);
  const [sendEmailState, setSendEmailState] = useState(false);
  const { instance, inProgress, accounts } = useMsal();
  const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [accessToken, setAccessToken] = useState('');
  const [apiData, setApiData] = useState(null);
  const [clear, SetClear] = useState(true);
  const [status, setStatus] = useState('');
  const [invoiceEmail, setInvoiceEmail] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [invoicedToDate, setInvoicedToDate] = useState(0);
  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastHeaderMessage, setToastHeaderMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const clientList = useClientList();
  const userList = useUserList();

  const deleteItemRef = useRef(null);
  const classNameRef = useRef('');
  const valueRef = useRef('');
  const disallowed: any = useExclusionList();

  const filteredEmployeeData = employeeData.filter(
    (value) => !disallowed.includes(value.displayName)
  );

  let PageSize = 10;

  let count = useRef(0);
  function handleComment(project: any) {
    setProjectId(project.id);
    setCommentModalShow(true);
  }

  function HandleRecordsPerPage(value: number) {
    setRecordsPerPage(value);
  }

  function handleToast(response: any) {
    switch (response) {
      case 'POSuccess':
        setToastHeaderMessage('Saved!');
        setToastMessage('Purchase Order Saved.');
        setToastType('success');
        setToastShow(true);
        break;
      case 'POUpdateSuccess':
        setToastHeaderMessage('Saved!');
        setToastMessage('Purchase Order Updated.');
        setToastType('success');
        setToastShow(true);
        break;
      case 'InvoiceSuccess':
        setToastHeaderMessage('Saved!');
        setToastMessage('Invoice Saved.');
        setToastType('success');
        setToastShow(true);
        break;
      case 'InvoiceUpdateSuccess':
        setToastHeaderMessage('Saved!');
        setToastMessage('Invoice Updated.');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Invoice Deleted':
        setToastHeaderMessage('Deleted!');
        setToastMessage('Invoice Deleted.');
        setToastType('secondary');
        setToastShow(true);
        break;
      case 'Invoice Not Found':
        setToastHeaderMessage('Note!');
        setToastMessage('The invoice was not found.');
        setToastType('info');
        setToastShow(true);
        break;
      case 'Billing Note Added':
        setToastHeaderMessage('Success!');
        setToastMessage('Note Saved Successfully');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Billing Note Updated':
        setToastHeaderMessage('Success!');
        setToastMessage('Note Updated Successfully');
        setToastType('success');
        setToastShow(true);
        break;
      case 'Billing Note Deleted':
        setToastHeaderMessage('Success!');
        setToastMessage('Note Deletedd Successfully');
        setToastType('secondary');
        setToastShow(true);
        break;
      default:
        setToastHeaderMessage('Failed!');
        setToastMessage('An Error Occured.');
        setToastType('danger');
        setToastShow(true);
    }
  }

  function handleSetReportModalShow() {
    if (reportModalShow === false) {
      setReportModalShow(true);
    } else {
      setReportModalShow(false);
      console.log('report modal show', reportModalShow);
    }
  }

  function handleSample(project: any) {
    // setTotalCharges(parseInt(project.billingAmountDetails));
    setTotalCharges(parseInt(project.totalCharges));
    setTotalPurchaseOrders(parseInt(project.totalPurchaseOrders));
    setProposalAmount(parseInt(project.proposalAmount));
    setProjectId(project.projectId);
    setSampleModalShow(true);
  }

  function handleSetNoteId(fromChild: any) {
    setNoteId(fromChild);
  }

  function handleConfirmModal(fromChild: any) {
    setConfirmModalShow(fromChild);
  }

  // function handleConfirmModal(fromChild: any) {
  //   setConfirmModal(fromChild);
  //   setRender(render + 1);
  // }

  function handleCommentModalShow(fromChild: boolean) {
    setCommentModalShow(fromChild);
  }

  function handleSampleClose(fromChild: any) {
    setSampleModalShow(fromChild);
  }

  function handleConfirmModalShow() {
    setConfirmModalShow(false);
  }

  function handleInvoiceConfirmModalShow(fromChild: any) {
    setInvoiceConfirmModalShow(fromChild);
  }

  function handleSampleModalShow(fromChild: any) {
    setSampleModalShow(fromChild);
  }
  // function handleNoteId(fromChild: any) {
  //   setNoteId(fromChild);
  // }

  function handleInvoiceEmail(InvoiceEmail: boolean, InvoiceAmount: number, sendEmail: boolean) {
    setInvoiceEmail(InvoiceEmail);
    setInvoiceAmount(InvoiceAmount);
    // setSendEmailState(sendEmail);
    GetInvoicedToDate();
  }

  useEffect(() => {
    if (sampleModalShow === false) {
      getSales(sessionStorage.getItem('accessToken'));
      setRender(render + 1);
      setProjectId(0);
      RequestAccessToken();
    }
  }, [confirmModal]);

  // useEffect(() => {
  //   // RequestAccessToken();
  //   getProjectList();
  //   getClientList();
  //   getSales();
  //   RequestAccessToken();
  // }, [page, recordsPerPage, totalAmountOfPages, search, sortBy, sortWith]);

  useLayoutEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: [`${process.env.REACT_APP_API_PROTECTED_RESOURCE_API_SCOPES}`]
        })
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
          sessionStorage.setItem('accessToken', accessToken);
          //setAccessToken(accessToken);
          // getProjectList(accessToken);
          // getClientList(accessToken);
          getSales(accessToken);
          setRender(render + 1);
        })
        // .then((response: any) => {
        //   setClientData(response.data.data.data);
        // })
        .catch((error: any) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              account: accounts[0],
              scopes: [`${process.env.REACT_APP_API_PROTECTED_RESOURCE_API_SCOPES}`]
            });
          }
          console.log(error);
        });
    }
  }, [
    instance,
    accounts,
    apiData,
    page,
    recordsPerPage,
    totalAmountOfPages,
    sortWith,
    sortBy,
    search,
    modalShow,
    sampleModalShow,
    sendEmailState
    //confirmModal
  ]);

  // useEffect(() => {
  //   console.warn('confirmModal', confirmModal);
  // }, [confirmModal]);

  // useEffect(() => {
  //   getSales(sessionStorage.getItem('accessToken'));
  //   setRender(render + 1);
  // }, [confirmModal]);

  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data

    return instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        getMSGraphUserList(response.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response: any) => {
          getMSGraphUserList(response.accessToken);
        });
      });
  }

  function getMSGraphUserList(token: any) {
    axios
      .get('https://graph.microsoft.com/v1.0/users', {
        headers: { Authorization: `Bearer ${token}` }
        // headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response) => {
        if (response.status === 200) {
          setEmployeeData(response.data.value);
          // handleSetEmployeeOption(response.data.value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // function RequestAccessTokenMail(projectId: number, status: boolean) {
  // function RequestAccessTokenMail(projectId: number) {
  //   const request = {
  //     ...loginRequest,
  //     account: accounts[0]
  //   };

  //   // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  //   return instance
  //     .acquireTokenSilent(request)
  //     .then((response: any) => {
  //       sendEmail(response.accessToken);
  //     })
  //     .catch((e) => {
  //       instance.acquireTokenPopup(request).then((response: any) => {
  //         sendEmail(response.accessToken);
  //       });
  //     });
  // }

  function GetInvoicedToDate() {
    axios
      .get(`${urlProjects}/GetInvoicedToDate/${projectId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        setInvoicedToDate(response.data.data);
        setSendEmailState(true);
        setInvoiceEmail(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (sendEmailState) {
      sendEmail(sessionStorage.getItem('emailToken'), projectId);
    }
    if (invoiceEmail) {
      setInvoiceEmail(false);
    }
  }, [sendEmailState]);

  // function sendEmail(token: any, projectId: number, status: boolean) {
  function sendEmail(token: any, projectId: number) {
    var matchingProject = sales.find(function (sales) {
      return sales.projectId === projectId;
    });
    let matchingEmployeeIds: any[] = [];
    for (let i = 0; i < matchingProject.projectEmployees.length; i++) {
      let p = matchingProject.projectEmployees[i];
      matchingEmployeeIds.push(p);
    }
    var employeeEmailsBody = [];
    for (let i = 0; i < matchingEmployeeIds.length; i++) {
      employeeEmailsBody.push({
        emailAddress: {
          address:
            employeeData?.find((x: any) => x.id === matchingEmployeeIds[i].employeeId) === undefined
              ? null
              : employeeData?.find((x: any) => x.id === matchingEmployeeIds[i].employeeId)
                  .userPrincipalName
        }
      });
      if (!invoiceEmail) {
        var body = {
          message: {
            subject: `${matchingProject.projectId} Billing Status Changed`,
            body: {
              contentType: 'Text',
              content: `Project: ${matchingProject.projectId} ${matchingProject.projectName} has been changed to "${valueRef.current}"`
            },
            toRecipients: employeeEmailsBody
          },
          saveToSentItems: 'true'
        };
        var payload = JSON.stringify(body);
        axios
          //.post(`https://graph.microsoft.com/v1.0/users/${defaultEmail}/sendMail`, payload, {
          .post(`https://graph.microsoft.com/v1.0/me/sendMail`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(() => {
            // setProjectId(undefined);
            setSendEmailState(false);
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
      if (invoiceEmail) {
        var productReceivedBody = {
          message: {
            subject: `${matchingProject.projectId} Project Invoiced`,
            body: {
              contentType: 'Text',
              content: `Project: ${matchingProject.projectId} ${
                matchingProject.projectName
              } has been invoiced.
              
              Invoiced Amount: ${invoiceAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
              `
            },
            toRecipients: employeeEmailsBody
          },
          saveToSentItems: 'true'
        };
        var payload = JSON.stringify(productReceivedBody);
        axios
          //.post(`https://graph.microsoft.com/v1.0/users/${defaultEmail}/sendMail`, payload, {
          .post(`https://graph.microsoft.com/v1.0/me/sendMail`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(() => {
            // setProjectId(undefined);
            setSendEmailState(false);
            setInvoiceEmail(false);
            setInvoiceAmount(0);
            setInvoicedToDate(0);
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
    }

    // var employeeEmailsBody = [];
    // var billingEmployees = sales.filter((x) => x.projectId === projectId)[0].projects
    //   .projectEmployees;
    // if (billingEmployees.length > 0) {
    //   for (let i = 0; i < billingEmployees.length; i++) {
    //     employeeEmailsBody.push({
    //       emailAddress: {
    //         address:
    //           employeeData?.find((x) => x.id === billingEmployees[i].employeeId) === undefined
    //             ? null
    //             : employeeData?.find((x) => x.id === billingEmployees[i].employeeId)
    //                 .userPrincipalName
    //       }
    //     });
    //   }
    //   var body = {
    //     message: {
    //       subject: `Billing Status Changed To ${status}`,
    //       body: {
    //         contentType: 'Text',
    //         content: `Status has been changed`
    //       },
    //       toRecipients: employeeEmailsBody
    //     },
    //     saveToSentItems: 'true'
    //   };

    //   var payload = JSON.stringify(body);
    //   axios
    //     .post(`https://graph.microsoft.com/v1.0/users/${defaultEmail}/sendMail`, payload, {
    //       headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    //         'Content-Type': 'application/json'
    //       }
    //     })
    //     .then((res: any) => {
    //       setSendEmailState(false);
    //       if (res.status === 202) {
    //         //window.location.reload();
    //       }
    //     })
    //     .catch((error: any) => {
    //       console.error(error);
    //     });
    // }
  }

  function changeStatus(projectId: any, billingStatus: any) {
    axios
      .put(
        `${urlProjects}/ChangeBillingStatus?projectId=${projectId}&BillingStatus=${billingStatus}`,
        {},
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
        }
      )
      .then((response) => {
        if (response.status === 204) {
          // getSales();
          //window.location.reload();
          getSales(sessionStorage.getItem('accessToken'));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetFilterArray(key: string, value: string) {
    if (value !== '') {
      var isexist = FilterArray.find((x: any) => x.key === key);
      if (isexist !== undefined) {
        var fa: any = FilterArray.map((x: any) => {
          if (x.key === key) {
            x.value = value;
          }
          return x;
        });
        SetFilterArray(fa);
      } else {
        var obj = {
          key: key,
          value: value
        };
        SetFilterArray([...FilterArray, obj] as any);
      }
    } else {
      SetFilterArray(FilterArray.filter((x: any) => x.key !== key));
    }
  }
  function ClearFilter() {
    SetFilterArray([]);
    SetClientFilter('');
    SetProjectManager('');
    SetBillingCenter('');
    SetProjectType('');
    SetProjectStatus('');
    SetPanelType('');
    SetOperator('OR');
    // setOpen(() => !open);
    axios
      .get(urlProjects, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        const totalAmountOfRecords = parseInt(response.headers['totalamountofrecords'], 10);
        setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
        setSales(response.data.data.data);
        setRender((count.current = count.current + 1));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function ApplyFilter() {
    getSales(sessionStorage.getItem('accessToken'));
    setOpen(() => !open);
    // SetFilterArray([]);
    // SetClientFilter('');
    // SetProjectManager('');
    // SetBillingCenter('');
    // SetProjectType('');
    // SetProjectStatus('');
    // SetPanelType('');
    // SetOperator('OR');
  }

  useEffect(() => {
    getSales(sessionStorage.getItem('accessToken'));
    SetFilterArray([]);
  }, [checked]);

  function getSales(accessToken: any) {
    var params = {
      page,
      recordsPerPage,
      filters: '',
      sortBy: 'asc',
      sortWith: '',
      search: ''
    };

    // var params = {
    //   page,
    //   recordsPerPage,
    //   filters: '',
    //   sortBy: 'asc',
    //   sortWith: '',
    //   search: ''
    // };
    if (FilterArray.length > 0) {
      params.filters = JSON.stringify(FilterArray);
    }
    if (sortWith !== '') {
      params.sortWith = sortWith;
    }
    if (search !== '') {
      params.search = search;
    }
    if (checked === false) {
      params.filters = JSON.stringify([
        { key: 'status', value: 'Completed' },
        { key: 'billing Status', value: 'No Billing Submitted' },
        { key: 'operator', value: 'and' }
      ]);
      params.sortWith = 'billingStatus';
    }

    params.sortBy = sortBy;

    axios
      .get(urlProjects, {
        params: params,
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        // console.log(`Response: ${JSON.stringify(response.data.data)}`);
        const totalAmountOfRecords = response.data.data.totalRecords;
        setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
        setSales(response.data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // function getProjectList(accessToken: any) {
  //   axios
  //     .get(`${urlProjects}/GetProjectList`, {
  //       headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
  //     })
  //     .then((response) => {
  //       setProject(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // function getClientList(accessToken: any) {
  //   axios
  //     .get(`${urlClients}/GetClientList`, {
  //       headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
  //     })
  //     .then((response) => {
  //       setClientList(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // function changeStatus(projectId: any, status: any) {
  //   axios
  //     .put(`${urlSales}/ChangeStatus?projectId=${projectId}&status=${status}`,{},
  //       {headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`}})
  //     .then((response) => {
  //       if (response.status === 204) {
  //         // getSales();
  //         RequestAccessTokenMail(projectId, status);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  function editItem(id: any) {
    setModalTitle('Edit Billing');
    setModalShow(true);
    setProjectId(id);
  }

  useEffect(() => {
    if (
      FilterArray.length > 0 ||
      clientFilter !== '' ||
      billingCenter !== '' ||
      projectManager !== '' ||
      projectType !== '' ||
      projectStatus !== '' ||
      panelType !== ''
    ) {
      SetClear(false);
    } else {
      SetClear(true);
    }
  }, [FilterArray.length, open]);

  function getColorClass(option: any) {
    switch (option) {
      case 'Billing Completed':
        return 'bg-color-green form-select color-white';
      case 'Initial Billing':
        return 'bg-color-pending form-select color-white';
      case 'No Billing Submitted':
        return 'bg-color-white form-select color-danger';
      default:
        return 'form-select';
    }
  }

  useEffect(() => {
    classNameRef.current = getColorClass(valueRef.current);
  }, []);

  return (
    <>
      <div className='col-12'>
        <div className='row m-0'>
          <div>
            <div className='container-fluid'>
              <div className='py-4 heading'>Billing </div>
              {/* <div className='col-12 py-5 d-flex justify-content-center'> */}
              {/* <div className='row'> */}
              {/* <div className='col-lg-3 col-sm-12 mx-auto'> */}
              {/* <div className='mx-auto'> */}
              {/* <div className='mb-3 col-lg-2'> */}
              {/* <Card style={{ width: '315px' }}>
                        <Card.Body>
                          <Card.Subtitle className='mb-2 text-muted'>Billing Status</Card.Subtitle>
                          <Card.Text>
                            <BillingPieChart />
                          </Card.Text>
                        </Card.Body>
                      </Card> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}

              <div className='py-4 row'>
                <div
                  className='col-md-6 col-sm-12 d-flex '
                  style={{
                    height: '32px'
                  }}
                >
                  <div className='' style={{ width: '45%', marginRight: '4%' }}>
                    <div className='position-relative'>
                      <input
                        type='text'
                        className='form-control paddingLeft border-0'
                        placeholder='Search'
                        required
                        style={{ marginTop: '3%' }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <svg
                        className='icon-input'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                      >
                        <path d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z' />
                      </svg>
                    </div>
                  </div>
                  <div
                    className=' d-flex align-items-center '
                    style={{
                      position: 'relative',
                      marginTop: '3%',
                      width: '50%'
                    }}
                  >
                    <>
                      <button
                        onClick={async () => {
                          setOpen(() => !open);
                          setSortDialogopen(() => false);
                        }}
                        aria-expanded={open}
                        style={{
                          backgroundColor: 'white',
                          padding: '2%',
                          color: 'black',
                          borderColor: 'white',
                          border: '0px'
                        }}
                      >
                        <div className='d-flex align-item-center'>
                          <img src='/assets/img/filter.svg' alt='Filter' />

                          <div>
                            <p className='px-2 mb-0'>Filters</p>
                          </div>
                          <div
                            style={{ marginLeft: '5px', marginTop: '3%' }}
                            className={open === true ? 'fa fa-caret-up' : 'fa fa-caret-down'}
                          ></div>
                        </div>
                      </button>
                      <div style={{ minHeight: '150px' }}>
                        <Collapse in={open} dimension='width'>
                          <div id='example-collapse-text'>
                            <Card
                              body
                              style={{
                                width: '900px',
                                borderRadius: '24px',
                                position: 'absolute',
                                boxShadow: '0px 0px 83px -48px black',
                                top: '100%',
                                left: '0%',
                                zIndex: '1'
                              }}
                            >
                              <>
                                <div className='container modal_form'>
                                  <div className='row' style={{ width: '100%' }}>
                                    <br />
                                    {/*---------------------------------- try new dropdown of filter ---------------------------------- */}

                                    {/* <div className='col-4'>
                                      <br />
                                      <div className='position-relative'>
                                        <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginLeft: '-30%'
                                          }}
                                        >
                                          Project Name{' '}
                                        </p>
                                        <select
                                          onChange={(e: any) => {
                                            SetOverallCategory(e.target.value);
                                            GetFilterArray('project id', e.target.value);
                                          }}
                                          className='form-select'
                                          aria-label='Default select example'
                                          value={overallCategory}
                                        >
                                          <option
                                            value=''
                                            selected
                                            style={{
                                              color: 'red'
                                            }}
                                          >
                                            None
                                          </option>
                                          {project?.map((item, index) => (
                                            <option key={index} value={item.projectId}>
                                              <div>{item.projectName} </div>
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div> */}
                                    <div className='col-4'>
                                      <br />
                                      <div className='position-relative'>
                                        <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginLeft: '-30%'
                                          }}
                                        >
                                          Client
                                        </p>
                                        <select
                                          onChange={(e: any) => {
                                            SetClientFilter(e.target.value);
                                            GetFilterArray('client', e.target.value);
                                          }}
                                          className='form-select'
                                          aria-label='Default select example'
                                          value={clientFilter}
                                        >
                                          <option
                                            value=''
                                            selected
                                            style={{
                                              color: 'red'
                                            }}
                                          >
                                            None
                                          </option>
                                          {/* {clientFilter?.map((value, index) => (
                                            <option key={index} value={value.clientId}>
                                              <div>{value.clientName}</div>
                                            </option>
                                          ))} */}
                                          {[
                                            ...new Set(
                                              clientList?.map((value: any) => value.clientName)
                                            )
                                          ]
                                            .filter((lastName) => lastName !== null)
                                            .sort((a, b) => (a || '').localeCompare(b || ''))
                                            .map((clientName, index) => (
                                              <option key={index}>
                                                <div>{[clientName]}</div>
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <br />
                                      <div className='position-relative'>
                                        <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginLeft: '-30%'
                                          }}
                                        >
                                          Project Manager
                                        </p>
                                        <select
                                          onChange={(e: any) => {
                                            SetProjectManager(e.target.value);
                                            GetFilterArray('project lead', e.target.value);
                                          }}
                                          className='form-select'
                                          aria-label='Default select example'
                                          value={projectManager}
                                        >
                                          <option
                                            value=''
                                            selected
                                            style={{
                                              color: 'red'
                                            }}
                                          >
                                            None
                                          </option>
                                          {filteredEmployeeData.map
                                            ? filteredEmployeeData.map((value, index) => (
                                                <option key={index}>
                                                  <div>{value.displayName}</div>
                                                </option>
                                              ))
                                            : null}
                                        </select>
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <br />
                                      <div className='position-relative'>
                                        <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginLeft: '-30%'
                                          }}
                                        >
                                          Billing Center
                                        </p>
                                        <select
                                          onChange={(e: any) => {
                                            SetBillingCenter(e.target.value);
                                            GetFilterArray('billing center', e.target.value);
                                          }}
                                          className='form-select'
                                          aria-label='Default select example'
                                          value={billingCenter}
                                        >
                                          <option
                                            value=''
                                            selected
                                            style={{
                                              color: 'red'
                                            }}
                                          >
                                            None
                                          </option>
                                          <option selected>Select Billing Type</option>
                                          {/* <option value='Not Selected'>Not Selected</option> */}
                                          <option value='Consumer Understanding'>
                                            Consumer Understanding
                                          </option>
                                          <option value='Michigan'>Michigan</option>
                                          <option value='North Carolina Consulting'>
                                            North Carolina Consulting
                                          </option>
                                          <option value='North Carolina Managed Services'>
                                            North Carolina Managed Services
                                          </option>
                                          <option value='Product Understanding'>
                                            Product Understanding
                                          </option>
                                          <option value='Texas'>Texas</option>
                                          <option value='Training & Education'>
                                            Training & Education
                                          </option>
                                          <option value='New York'>New York</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className='row'
                                    style={{
                                      width: '100%',
                                      marginTop: '1.5%'
                                    }}
                                  >
                                    <br />

                                    {/*---------------------------------- 2nd row dropdown of filter ---------------------------------- */}
                                    <div className='col-4'>
                                      <br />
                                      <div className='position-relative'>
                                        <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginLeft: '-30%'
                                          }}
                                        >
                                          Project Type
                                        </p>
                                        <select
                                          onChange={(e: any) => {
                                            SetProjectType(e.target.value);
                                            GetFilterArray('project type', e.target.value);
                                          }}
                                          className='form-select'
                                          aria-label='Default select example'
                                          value={projectType}
                                        >
                                          <option
                                            value=''
                                            selected
                                            style={{
                                              color: 'red'
                                            }}
                                          >
                                            None
                                          </option>
                                          <option selected>Select Project Type</option>
                                          <option value='Descriptive Analysis'>
                                            Descriptive Analysis
                                          </option>
                                          <option value='Discrimination Testing'>
                                            Discrimination Testing
                                          </option>
                                          <option value='Analysis/Reporting'>
                                            Analysis/Reporting
                                          </option>
                                          <option value='Panel Training'>Panel Training</option>
                                          <option value='Snapshot'>Snapshot</option>
                                          <option value='SpecSpeak'>SpecSpeak</option>
                                          <option value='Training'>Training</option>
                                          <option value='Facility Rental'>Facility Rental</option>
                                          <option value='SCAN'>SCAN</option>
                                          <option value='Consumer Qual'>Consumer Qual</option>
                                          <option value='Consumer Quant'>Consumer Quant.</option>
                                          <option value='Panel/Lab Management'>
                                            Panel/Lab Management
                                          </option>
                                          <option value='Other'>Other</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <br />
                                      <div className='position-relative'>
                                        <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginLeft: '-30%'
                                          }}
                                        >
                                          Status
                                        </p>
                                        <select
                                          onChange={(e: any) => {
                                            SetProjectStatus(e.target.value);
                                            GetFilterArray('billing status', e.target.value);
                                          }}
                                          className='form-select'
                                          aria-label='Default select example'
                                          value={projectStatus}
                                        >
                                          <option
                                            value=''
                                            selected
                                            style={{
                                              color: 'red'
                                            }}
                                          >
                                            None
                                          </option>
                                          <option value='No Billing Submitted'>
                                            No Billing Submitted
                                          </option>
                                          <option value='Initial Billing'>Initial Billing</option>
                                          <option value='Billing Completed'>
                                            Billing Completed
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <br />
                                      <div className='position-relative'>
                                        <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginLeft: '-30%'
                                          }}
                                        >
                                          Panel Type
                                        </p>
                                        <select
                                          onChange={(e: any) => {
                                            SetPanelType(e.target.value);
                                            GetFilterArray('panel type', e.target.value);
                                          }}
                                          className='form-select'
                                          aria-label='Default select example'
                                          value={panelType}
                                        >
                                          <option
                                            value=''
                                            selected
                                            style={{
                                              color: 'red'
                                            }}
                                          >
                                            None
                                          </option>
                                          <option selected>Select Panel Type</option>
                                          <option value='Aroma-NJ/PQM'>Aroma-NJ/PQM</option>
                                          <option value='Quaker'>Quaker</option>
                                          <option value='Consultant'>Consultant</option>
                                          <option value='Discrimination-NC,'>
                                            Discrimination-NC,
                                          </option>
                                          <option value='Food-NJ'>Food-NJ</option>
                                          <option value='PCP'>PCP</option>
                                          <option value='PQM'>PQM</option>
                                          <option value='SCAN'>SCAN</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className='row'
                                    style={{
                                      width: '100%',
                                      marginTop: '1.5%'
                                    }}
                                  >
                                    <br />

                                    {/*---------------------------------- 3rd row dropdown of filter ---------------------------------- */}

                                    <div className='col-4'>
                                      <br />
                                      <div className='position-relative'>
                                        <p
                                          style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            marginLeft: '-30%'
                                          }}
                                        >
                                          Filter Type
                                        </p>
                                        <select
                                          onChange={(e: any) => {
                                            SetOperator(e.target.value);
                                            GetFilterArray('operator', e.target.value);
                                          }}
                                          className='form-select'
                                          aria-label='Default select example'
                                          value={operator}
                                        >
                                          <option
                                            selected
                                            style={{
                                              color: 'red'
                                            }}
                                            value=''
                                          >
                                            None
                                          </option>
                                          <option value='AND'>AND</option>
                                          <option value='OR'>OR</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className='row justify-content-end'
                                  style={{ margin: '2% 1% 0 0' }}
                                >
                                  <div className=' d-flex justify-content-end'>
                                    <ToggleButton
                                      id='toggle-clear'
                                      type='checkbox'
                                      variant='outline-secondary'
                                      checked={!clear}
                                      value='1'
                                      onClick={() => {
                                        ClearFilter();
                                      }}
                                      className='btn primary-bg-color-sp-secondary px-4 mx-4'
                                    >
                                      Clear
                                    </ToggleButton>
                                    <button
                                      type='button'
                                      // onClick={() => ClearFilter()}
                                      onClick={() => setOpen(() => !open)}
                                      className='btn primary-bg-color-sp-ouline px-4 mx-4'
                                    >
                                      Close
                                    </button>
                                    <button
                                      type='button'
                                      onClick={() => ApplyFilter()}
                                      className='btn primary-bg-color-sp text-white'
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </div>
                              </>
                            </Card>
                          </div>
                        </Collapse>
                      </div>
                    </>
                    <div className='row justify-content-end' style={{ margin: '2% 1% 2% 15%' }}>
                      <div className='col-4 d-flex align-items-center justify-content-center text-nowrap'>
                        <ToggleButton
                          id='toggle-check'
                          type='checkbox'
                          variant='outline-secondary'
                          checked={!checked}
                          value='1'
                          onChange={(e) => {
                            if (
                              FilterArray.length > 0 ||
                              clientFilter !== '' ||
                              billingCenter !== '' ||
                              proposalDate !== '' ||
                              projectManager !== '' ||
                              projectType !== '' ||
                              projectStatus !== '' ||
                              panelType !== ''
                            ) {
                              ClearFilter();
                            }
                            setChecked(!checked);
                          }}
                        >
                          Unbilled
                        </ToggleButton>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='col-md-6 col-sm-12 d-flex justify-content-end'>
                  <button
                    type='button'
                    className='btn primary-bg-color-sp text-white'
                    onClick={() => setModalShow(true)}
                  >
                    Add Billing
                  </button>
                </div> */}
                <div className='col-md-12 col-sm-12'>
                  <Table responsive bordered hover size='sm' style={{ margin: '2% 0' }}>
                    <thead className='shadow-none table-design'>
                      <tr>
                        <th>PID</th>
                        <th>
                          <div className='d-flex justify-content-between'>
                            <div> Project Name </div>
                            {/* <div className='ShortButtonTH'>
                              <i
                                className='fa fa-solid fa-sort'
                                onClick={() => {
                                  SetSortWith((pre) => 'projectName');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                }}
                              ></i>
                            </div> */}
                          </div>
                        </th>
                        <th>
                          <div className='d-flex justify-content-between'>
                            <div> Client </div>
                            {/* <div className='ShortButtonTH'>
                              <i
                                className='fa fa-solid fa-sort'
                                onClick={() => {
                                  SetSortWith((pre) => 'clientName');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                }}
                              ></i>
                            </div> */}
                          </div>
                        </th>
                        {/* <th>Contact</th> */}
                        <th>
                          <div className='d-flex justify-content-between'>
                            <div> Billing Center </div>
                            {/* <div className='ShortButtonTH'>
                              <i
                                className='fa fa-solid fa-sort'
                                onClick={() => {
                                  SetSortWith((pre) => 'billingCenter');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                }}
                              ></i>
                            </div> */}
                          </div>
                        </th>
                        <th>
                          <div className='d-flex justify-content-between'>
                            <div> Project Type </div>
                            {/* <div className='ShortButtonTH'>
                              <i
                                className='fa fa-solid fa-sort'
                                onClick={() => {
                                  SetSortWith((pre) => 'projectType');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                }}
                              ></i>
                            </div> */}
                          </div>
                        </th>
                        <th>
                          <div className='d-flex justify-content-between'>
                            <div> Panel Type </div>
                            {/* <div className='ShortButtonTH'>
                              <i
                                className='fa fa-solid fa-sort'
                                onClick={() => {
                                  SetSortWith((pre) => 'panelType');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                }}
                              ></i>
                            </div> */}
                          </div>
                        </th>
                        <th>
                          <div className='text-start'>
                            <div>Project Manager </div>
                            {/* <div className='ShortButtonTH'>
                              <i
                                className='fa fa-solid fa-sort'
                                onClick={() => {
                                  SetSortWith((pre) => 'projectManager');
                                  if (sortBy === 'dec') {
                                    SetSortBy((pre) => 'asc');
                                  } else {
                                    SetSortBy((pre) => 'dec');
                                  }
                                }}
                              ></i>
                            </div> */}
                          </div>
                        </th>
                        <th className='text-start'>
                          <div>Proposal Amount</div>
                          {/* <div className='ShortButtonTH'>
                            <i
                              className='fa fa-solid fa-sort'
                              onClick={() => {
                                SetSortWith((pre) => 'billingAmountDetails');
                                if (sortBy === 'dec') {
                                  SetSortBy((pre) => 'asc');
                                } else {
                                  SetSortBy((pre) => 'dec');
                                }
                              }}
                            ></i>
                          </div> */}
                        </th>
                        <th className='text-start'>Total Paid</th>
                        <th>Status</th>

                        <th style={{ width: '6.5vw' }}>Action</th>
                      </tr>
                    </thead>
                    {/* <tbody className='table-design'> */}
                    <tbody>
                      {sales?.map((value, index) => (
                        <tr key={index} className='align-middle px-1'>
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.projectId}
                          </td>
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.projectName}
                          </td>
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.clientName}
                          </td>
                          {/* <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.contact}
                          </td> */}
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.billingCenter}
                          </td>
                          {/* <td style={{ minWidth: '203px' }}>{value.contacts}</td> */}
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.projectType}
                          </td>
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.panelType}
                          </td>
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.projectLead}
                          </td>
                          {/* <td>
                            {parseFloat(value.billingAmountDetails).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            })}
                          </td> */}
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.proposalAmount === null
                              ? ''
                              : value.proposalAmount.toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD'
                                })}
                          </td>
                          <td
                            onClick={() => {
                              handleSetReportModalShow();
                              console.log(value);
                              setRowValue(value);
                            }}
                          >
                            {value.totalCharges.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            })}
                          </td>
                          <td>
                            <select
                              className={getColorClass(value.billingStatus)}
                              // style={{ maxWidth: '150px' }}
                              // className={getColorClass(value.status)}
                              title='Select-Status'
                              id='billingStatus'
                              name='billingStatus'
                              value={value.billingStatus}
                              onChange={(e) => {
                                valueRef.current = e.target.value;
                                classNameRef.current = getColorClass(e.target.value);
                                setStatus(e.target.value);
                                changeStatus(value.projectId, e.target.value);
                                setSendEmailState(true);
                                setProjectId(value.projectId);
                              }}
                            >
                              <option selected value='No Billing Submitted'>
                                No Billing Submitted
                              </option>
                              <option value='Initial Billing'>Initial Billing</option>
                              <option value='Billing Completed'>Billing Completed</option>
                            </select>
                          </td>
                          <td
                            // style={{ minWidth: '110px', lineHeight: '47px' }}
                            style={{
                              minWidth: '110px'
                            }}
                            // className='d-flex h-100 justify-content-center align-items-center'
                            className='vertical-align-middle'
                          >
                            {/* <span data-bs-toggle='tooltip' data-bs-placement='top' title='Edit'>
                              <svg
                                role='button'
                                className='action-btn action-btn-edit'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 512 512'
                                onClick={() => editItem(value.projectId)}
                              >
                                <path d='M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z' />
                              </svg>
                            </span> */}
                            <span
                              data-bs-toggle='tooltip'
                              data-bs-placement='top'
                              title='Billing Details'
                              className='mx-2'
                            >
                              <svg
                                onClick={() => {
                                  handleSample(value);
                                  setProject(value);
                                }}
                                role='button'
                                width='18'
                                height='18'
                                fill='#2B9191'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 384 512'
                              >
                                <path d='M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z' />
                              </svg>
                            </span>
                            <span data-bs-toggle='tooltip' data-bs-placement='top' title='Notes'>
                              <svg
                                role='button'
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 13.835 13.835'
                                onClick={() => {
                                  handleComment(value);
                                  setProjectId(value.projectId);
                                }}
                              >
                                <g
                                  id='Group_702'
                                  data-name='Group 702'
                                  transform='translate(-409 -429.164)'
                                >
                                  <g
                                    id='Group_702-2'
                                    data-name='Group 702'
                                    transform='translate(2 0.576)'
                                  >
                                    <path
                                      id='comment'
                                      d='M6.918,12.106a7.881,7.881,0,0,1-1.81-.216,7.693,7.693,0,0,1-.939.939,6.011,6.011,0,0,1-.811.588,3.471,3.471,0,0,1-.642.291,3,3,0,0,1-.412.115,1.259,1.259,0,0,1-.142.013,4.687,4.687,0,0,0,.405-1.682,3.619,3.619,0,0,0-.189-1.533A6.293,6.293,0,0,1,.635,8.579,5.334,5.334,0,0,1,.547,3.7,6.092,6.092,0,0,1,2.02,1.77,7.075,7.075,0,0,1,4.229.48a7.776,7.776,0,0,1,5.377,0,7.059,7.059,0,0,1,2.209,1.29A6.1,6.1,0,0,1,13.288,3.7a5.323,5.323,0,0,1,0,4.7,6.1,6.1,0,0,1-1.473,1.932,7.071,7.071,0,0,1-2.209,1.291A7.607,7.607,0,0,1,6.918,12.106Z'
                                      transform='translate(407 428.588)'
                                      fill='#2B9191'
                                    />
                                    <path
                                      id='plus-round'
                                      d='M9.4,6.718H7.605V4.924a.444.444,0,0,0-.887,0V6.718H4.924a.444.444,0,0,0,0,.887H6.718V9.4a.444.444,0,0,0,.887,0V7.605H9.4a.444.444,0,0,0,0-.887Z'
                                      transform='translate(406.756 427.603)'
                                      fill='#fff'
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    pageSize={PageSize}
                    currentPage={page}
                    totalAmountOfPages={totalAmountOfPages}
                    onChange={(newPage) => setPage(newPage)}
                  />
                  <div className='col-12 d-flex align-items-center justify-content-end'>
                    <TableNumberSelect handleRecordsPerPage={HandleRecordsPerPage} />
                  </div>
                </div>
              </div>
            </div>
            {/* <BillingModel
              name={modalTitle}
              show={modalShow}
              accessToken={accessToken}
              sales={sales}
              projectId={projectId}
              onHide={() => {
                setModalShow(false);
                setModalTitle('Add Billing');
                setProjectId(undefined);
                //window.location.reload();
                // getSales();
              }}
            /> */}
            {commentModalShow && (
              <CommentModel
                show={commentModalShow}
                setShow={setCommentModalShow}
                projectId={projectId}
                setNoteId={handleSetNoteId}
                noteId={noteId}
                handleToast={handleToast}
                handleCommentModalShow={handleCommentModalShow}
                setConfirmModalShow={setConfirmModalShow}
                accessToken={accessToken}
                onHide={() => {
                  setCommentModalShow(false);
                  //setProjectId(undefined);
                  getSales(accessToken);
                }}
              />
            )}

            <SampleModel
              show={sampleModalShow}
              handleInvoiceConfirmModalShow={handleInvoiceConfirmModalShow}
              sampleModalShow={sampleModalShow}
              handleSampleModalShow={handleSampleModalShow}
              handleSampleClose={handleSampleClose}
              handleSample={handleSample}
              handleToast={handleToast}
              project={project}
              accessToken={accessToken}
              projectId={projectId}
              proposalAmount={proposalAmount}
              totalCharges={totalCharges}
              handleInvoiceEmail={handleInvoiceEmail}
              //totalPurchaseOrders={totalPurchaseOrders}
              onHide={() => {
                setSampleModalShow(false);
                getSales(accessToken);
              }}
            />

            <ConfirmModal
              show={confirmModalShow}
              //deleteItem={deleteItem}
              projectId={projectId}
              noteId={noteId}
              handleToast={handleToast}
              handleNoteId={handleSetNoteId}
              setShow={handleConfirmModalShow}
              setCommentModalShow={handleCommentModalShow}
              //setShowConfirm={setShowConfirm}
              handleConfirmModal={handleConfirmModal}
            />

            <ReportModal
              show={reportModalShow}
              handleReportModalClose={handleSetReportModalShow}
              value={rowValue}
            />

            <ToastContainer className='p-3' position='top-center'>
              <Toast
                bg={toastType}
                onClose={() => {
                  setToastShow(false);
                  setToastType('');
                  setToastHeaderMessage('');
                  setToastMessage('');
                }}
                show={toastShow}
                delay={3000}
                autohide
              >
                <Toast.Header closeButton={true}>
                  <strong className='me-auto'>{toastHeaderMessage}</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
              </Toast>
            </ToastContainer>
          </div>
        </div>
      </div>
    </>
  );
}
