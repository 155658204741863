import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers';
import { set } from 'react-hook-form';

function ReportModal(props: any) {
  const [show, setShow] = useState(props.show);
  const [noteList, setNoteList] = useState<any[]>([]);
  const [note, setNote] = useState({
    note: ''
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const handlePrint = () => {
  //   const printWindow = window.open('', '_blank');
  //   const modalContent = document.getElementById('modal-content');

  //   if (printWindow && modalContent) {
  //     printWindow.document.write(modalContent.innerHTML);
  //     printWindow.document.close();
  //     printWindow.print();
  //     printWindow.close();
  //   }
  // };
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const modalContent = document.getElementById('modal-content');

    if (printWindow && modalContent) {
      const printDocument = printWindow.document;

      printDocument.write(`
      <html>
        <head>
          <style>
            @media print {
              /* Hide elements not needed for printing */
              body * {
                visibility: hidden;
              }
              #modal-content,
              #modal-content * {
                visibility: visible;
              }
        
              /* Adjust layout for printing */
              #modal-content {
                position: absolute;
                left: 0;
                top: 0;
                font-weight: bold;
              }
              .col-md-6 {
                width: 100%;
                margin-bottom : 1rem;
                white-space: 'nowrap';
              }
              span {
                font-weight: normal;
              }
              .col-md-10{
                width: 10%;
              }
              .col-md-10 div{
                margin-left: 4rem;
                width: 90%;
                text-align: left;
                white-space: nowrap;
                font-weight: normal;
                

              }
            }
          </style>
        </head>
        <body>
          <div id="modal-content">
            ${modalContent.innerHTML}
          </div>
          
          <script>
            // Additional JavaScript code for the print window can be added here
          </script>
        </body>
      </html>
    `);

      printDocument.close();
      printWindow.print();
      printWindow.close();
    }
  };

  useEffect(() => {
    // cast props.value.billingNotes to any[] to avoid type error
    setNoteList(props.value.billingNotes as any[]);
  }, [props.value.billingNotes]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleReportModalClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Project: {props.value.projectName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='show-grid'>
          <Container className='p-1' fluid='true'>
            <div id='modal-content'>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>Client:</span> {props.value.clientName}
                </Col>
                <Col md={6}>
                  <span className='text-black-50'>Project Manager: </span>
                  {props.value.projectLead}
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>Project Status:</span> {props.value.status}
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>Proposal Amount:</span>{' '}
                  {props.value.proposalAmount
                    ? props.value.proposalAmount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      })
                    : ' '}
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>Proposal Date:</span>{' '}
                  {new Date(props.value.proposalDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  })}
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>PID:</span> {props.value.projectId}
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>Client Contact:</span> {props.value.contact}
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>Client Phone:</span> {props.value.phoneNumber}
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>Billing Status: </span>
                  {props.value.billingStatus}
                </Col>
              </Row>
              <Row className='mb-1'>
                <Col md={6}>
                  <span className='text-black-50'>Billing Center:</span> {props.value.billingCenter}
                </Col>
              </Row>
              <Row className='mt-4 mb-2'>
                <Col md={2}>
                  <span className='text-black-50'>Notes:</span>
                </Col>
                <Col md={10}>
                  {noteList?.map((note: any, index: any) => (
                    <div key={index}>{note.note}</div>
                  ))}
                </Col>
              </Row>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button className='primary-bg-color-sp' onClick={handlePrint}>
            Print
          </Button>
          <Button variant='secondary' onClick={props.handleReportModalClose}>
            Close
          </Button>
          {/* <Button variant='primary' className='primary-bg-color-sp' onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReportModal;
