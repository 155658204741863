// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePie } from '@nivo/pie';
import React, { useEffect, useState } from 'react';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
function PieChart(props: any) {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    data && (
      <>
        {/* <div style={{ height: '350px' }}> */}
        <ResponsivePie
          data={data}
          margin={{ top: 90, right: 90, bottom: 90, left: 90 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]]
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor='#333333'
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]]
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: 'Consumer Understanding'
              },
              id: 'lines'
            }
            // {
            //   match: {
            //     id: 'c'
            //   },
            //   id: 'dots'
            // },
            // {
            //   match: {
            //     id: 'go'
            //   },
            //   id: 'dots'
            // },
            // {
            //   match: {
            //     id: 'python'
            //   },
            //   id: 'dots'
            // },
            // {
            //   match: {
            //     id: 'scala'
            //   },
            //   id: 'lines'
            // },
            // {
            //   match: {
            //     id: 'lisp'
            //   },
            //   id: 'lines'
            // },
            // {
            //   match: {
            //     id: 'elixir'
            //   },
            //   id: 'lines'
            // },
            // {
            //   match: {
            //     id: 'javascript'
            //   },
            //   id: 'lines'
            // }
          ]}
          legends={
            [
              // {
              //   anchor: 'bottom',
              //   direction: 'row',
              //   justify: false,
              //   translateX: 0,
              //   translateY: 56,
              //   itemsSpacing: 0,
              //   itemWidth: 100,
              //   itemHeight: 18,
              //   itemTextColor: '#999',
              //   itemDirection: 'left-to-right',
              //   itemOpacity: 1,
              //   symbolSize: 18,
              //   symbolShape: 'circle',
              //   effects: [
              //     {
              //       on: 'hover',
              //       style: {
              //         itemTextColor: '#000'
              //       }
              //     }
              //   ]
              // }
            ]
          }
        />
        {/* </div> */}
      </>
    )
  );
}

export default PieChart;
