import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const UserListContext = createContext({});
const GetUserContext = createContext<any>(() => Promise.resolve());

export function useGetUserList() {
  const getMSGraphUserList = useContext(GetUserContext);
  return getMSGraphUserList;
}

export function useUserList() {
  const userList = useContext(UserListContext) as any[];
  return userList;
}

export default function UserContextProvider({ children }: any) {
  const [employeeData, setEmployeeData] = useState<any[]>([]);

  //   async function getMSGraphUserList(accessToken: any) {
  //     axios
  //       .get('https://graph.microsoft.com/v1.0/users', {
  //         headers: { Authorization: `Bearer ${sessionStorage.getItem('emailToken')}` }
  //       })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           setEmployeeData(response.data.value);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }

  async function getMSGraphUserList(accessToken: any) {
    let allUsers: any = []; // To store all users

    // Function to make a request to Microsoft Graph API
    const getUsersPage = (url: string) => {
      return axios.get(url, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('emailToken')}` }
      });
    };

    const handleResponse = async (response: any) => {
      if (response.status === 200) {
        const pageUsers = response.data.value;
        allUsers = allUsers.concat(pageUsers);

        // Check if there are more pages to fetch
        if (response.data['@odata.nextLink']) {
          // If there is a nextLink, make another request for the next page
          const nextPageUrl = response.data['@odata.nextLink'];
          const nextPageResponse = await getUsersPage(nextPageUrl);
          await handleResponse(nextPageResponse);
        } else {
          // No more pages left; all users have been fetched
          setEmployeeData(allUsers);
        }
      } else {
        console.log('Error fetching user data');
      }
    };

    try {
      const initialResponse = await getUsersPage('https://graph.microsoft.com/v1.0/users');
      await handleResponse(initialResponse);
    } catch (error) {
      console.log(error);
    }
  }

  // Usage
  //getMSGraphUserList(sessionStorage.getItem('emailToken'));

  return (
    <GetUserContext.Provider value={getMSGraphUserList}>
      <UserListContext.Provider value={employeeData}>{children}</UserListContext.Provider>
    </GetUserContext.Provider>
  );
}
