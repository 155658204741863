import {useState, useEffect} from 'react';
import { set } from 'react-hook-form';


function useHandleProjectType(props: any) {
//   const [projectType, setProjectType] = useState(null);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [projectTypePayload, setProjectTypePayload] = useState<any>(null);

    useEffect(() => {
      if (selectedOption !== null && selectedOption !== undefined && selectedOption !== ''){
        handlePayload(selectedOption);
      } else {
        ClearPayload();
      }
    }
    , [selectedOption]);


   function handleProjectType(projectType: any) {


    if (projectType !== '' && projectType !== undefined && projectType !== null) {
      //let obj = {} as any;
      let arr = [] as any;
      let projectTypeArray = projectType.split(',');

      for (let i = 0; i < projectTypeArray.length; i++) {
        let obj = {} as any;
        obj['value'] = projectTypeArray[i];
        obj['label'] = projectTypeArray[i];
        arr.push(obj);
      }
     setSelectedOption(arr);
     props.handleEditModalShow(true);
    } else {
     setSelectedOption(null);
     props.handleEditModalShow(true);
    }
    return selectedOption;
  }

  function handlePayload(selectedOption: any) {

    let payloadArray = [] as string[];
    // if (selectedOption.value !== null || selectedOption.value !== undefined) {
    if (selectedOption.length > 0) {
      for (let i = 0; i < selectedOption.length; i++) {
        //create a comma delimited string of the selected options
        // let item: string = selectedOption[i].value + ',';
        let item: string = selectedOption[i].value;
        payloadArray.push(item); 
    }
    }
    setProjectTypePayload(payloadArray.toString());
    return projectTypePayload;
  }

  function ClearPayload() {
    setProjectTypePayload(null);
    setSelectedOption(null);
  }


  return { handleProjectType, selectedOption, setSelectedOption, handlePayload, projectTypePayload, ClearPayload};
}

export default useHandleProjectType;