import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { urlProjects } from '../endpoints';
import { set } from 'react-hook-form';

// export function sendReportEmail(userId: any, projectId: any) {
//   return console.log(userId, projectId)
// }

function useSampleReportEmail(userEmail: any, projectId: any) {
  const [sampleReportList, setSampleReportList] = useState<any>([]);
  const [responseText, setResponseText] = useState<any>('');

  useEffect(() => {
    if (sampleReportList.length > 0){
    sendReportEmail(userEmail, projectId);
    }
  }
  , [sampleReportList]);

  useEffect(() => {
    return () => {
      setSampleReportList([]);
    };
  }, []);


  const sampleTable = `


<body style='font-family: Arial, sans-serif;'>
  <h1 style="display: flex; justify-content: center">Sample Report</h1>
  <br></br>
  
  <table style="border-collapse: collapse; width: 100%;">
  <thead>
    <tr>
    <th style="white-space: nowrap; border: 1px solid black; padding: 5px">Sample Id</th>
    <th style="white-space: nowrap; border: 1px solid black; padding: 5px">Date</th>
    <th style="border: 1px solid black; padding: 5px">Name Or Code</th>
    <th style="border: 1px solid black; padding: 5px">Quantity</th>
    <th style="border: 1px solid black; padding: 5px">Unit Size</th>
    <th style="border: 1px solid black; padding: 5px">Notes</th>
    </tr>
    </thead>
    <tbody>
    ${ sampleReportList ? sampleReportList.map((sample: any) => {
      return `
      <tr>
      <td style="white-space: nowrap; border: 1px solid black; font-size: 10pt; padding: 5px">${sample.sampleId}</td>
      <td style="border: 1px solid black; font-size: 10pt; padding: 5px">${sample.dateTime !== null?new Date(sample.dateTime.split('T')[0]).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }):''
    }</td>
    <td style="border: 1px solid black; font-size: 10pt; padding: 5px">${sample.nameOrCode}</td>
    <td style="border: 1px solid black; font-size: 10pt; padding: 5px">${sample.quantity}</td>
    <td style="border: 1px solid black; font-size: 10pt; padding: 5px">${sample.unitSize}</td>
    <td style="border: 1px solid black; font-size: 10pt; padding: 5px">${sample.infoNotes}</td>
    </tr>
    `;
  }).join(''):''}
    </tbody>
    </table>
    </body>
    `;

  function GetSamplesByProjectId(projectId: any) {
    axios
      .get(`${urlProjects}/GetSamplesByProjectId?projectId=${projectId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
      })
      .then((response: any) => {
        setSampleReportList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function sendReportEmail(userEmail: any, projectId: any) {
    var body = {
        message: {
          subject: `${projectId} Project Sample Report`,
          body: {
            contentType: 'HTML',
            content: `${sampleTable}`
          },
        toRecipients: [
      {
        emailAddress: {
          address: `${userEmail}`
        }
      }
    ],
  },
        saveToSentItems: 'true'
      };
      var payload = JSON.stringify(body);
      axios
        .post(`https://graph.microsoft.com/v1.0/me/sendMail`, payload, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('emailToken')}`,
            'Content-Type': 'application/json'
          }
        })
        .then((response: any) => {
          setResponseText(response.statusText);
        })
        .catch((error: any) => {
          console.error(error);
        });
  
      }
      return { GetSamplesByProjectId, sendReportEmail, responseText, setResponseText };  
  }
  
  
export default useSampleReportEmail;
