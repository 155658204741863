import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function PrivacyModal(props: any) {
  const [show, setShow] = useState(props.showModal);

  const handleClose = () => props.handleModal();

  return (
    <>
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header className='primary-bg-color-sp'>
          <Modal.Title className='text-white'>Sensory Spectrum Inc.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <h4>Sensory Spectrum Project Management Database Privacy Policy</h4>
          <br></br>
          This Privacy Policy outlines the practices and guidelines regarding the collection, use,
          and protection of your information within our project management database. Please read
          this policy carefully to understand how we handle your data.
          <br></br>
          <br></br>
          <h6>1. Information Collection</h6>
          We collect and store information within our project management database solely for the
          purpose of effective project management and communication. This information may include,
          but is not limited to:
          <br></br>
          <br></br>
          <ul>
            <li>Project-related documents and data.</li>
            <li>Client information, including names and contact details.</li>
            <li>Communications related to project details.</li>
          </ul>
          <h6>2. Use of Information</h6>
          We use the information collected in our project management database for the following
          purposes:
          <br></br>
          <br></br>
          <ul>
            <li>Facilitating project management, communication, and collaboration.</li>
            <li>Ensuring secure and authorized access to the database.</li>
            <li>Keeping project participants informed about project details and progress.</li>
            <li>Generating reports and projected income based on proposals and projects.</li>
          </ul>
          <h6>3. Data Security</h6>
          We are committed to the security of your information and have implemented
          industry-standard measures to protect it from unauthorized access, disclosure, or misuse.
          Access to the project management database is restricted to individuals currently employed
          by our company and is granted based on unique account credentials. Please do not share
          your account access information with others.
          <br></br>
          <br></br>
          <h6>4. Information Sharing</h6>
          We do not share the information stored within our project management database with any
          third parties, except as required by law or with your explicit consent. We do not use this
          information for any purposes other than those explicitly related to project management and
          communication.
          <br></br>
          <br></br>
          <h6>5. Your Responsibilities</h6>
          Protect your login credentials and account information. Do not share them with anyone not
          currently employed by our company.
          <span className='color-danger'>
            {' '}
            Notify us immediately if you suspect any unauthorized access or use of your account.
          </span>
          <br></br>
          <br></br>
          <h6>6. Changes to this Privacy Policy</h6>
          We reserve the right to make changes to this Privacy Policy at our discretion. Any updates
          or modifications will be communicated through our platform or other appropriate means.
          Your continued use of our project management database after any changes indicates your
          acceptance of the revised policy.
          <br></br>
          <br></br>
          <h6>7. Contact Us</h6>
          If you have any questions or concerns regarding this Privacy Policy or our data handling
          practices, please contact Joel Nelson or Nicole Butkiewicz.
          <br></br>
          <br></br>
          <span className='text-danger'>By using our project management database,</span> you agree
          to the terms outlined in this Privacy Policy. Your privacy and the security of our
          client’s data are of utmost importance to us, and we are committed to safeguarding your
          information.
          <br></br>
          <br></br>
          Policy written by Nicole Butkiewicz Ver 1.0: 10/24/2023 <br></br>
          <br></br>
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant='secondary' onClick={handleClose}>
            Close
          </Button> */}
          <Button variant='primary' className='primary-bg-color-sp border-0' onClick={handleClose}>
            I understand
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrivacyModal;
