import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { urlClients, urlProjects } from '../../../endpoints';
import EditProjectSample from './EditProjectSampleModal/EditProjectSample';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import EmailConfirm from '../../../components/SampleEmailConfirmModal/ConfirmModal';
import { get } from 'http';
import { useGetClients, useClientList } from '../../../context/ClientContext';
import useSampleReportEmail from '../../../hooks/useSampleReportEmail';
import { set } from 'react-hook-form';

function SampleModel(props: any) {
  const { name } = props;
  const [show, setShow] = useState(false);
  const [sampleList, setSampleList] = useState<any[]>([]);
  const [projectIdNumber, setProjectIdNumber] = useState(0);
  const [clientName, setClientName] = useState('');
  const [clientIdNumber, setClientIdNumber] = useState(0);
  //const [clientList, setClientList] = useState<any[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [projectId, setProjectId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [projects, setProjects] = useState<any[]>([]);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [sampleId, setSampleId] = useState();
  const [sampleModel, setSampleModel] = useState({
    nameOrCode: '',
    quantity: 0,
    unitSize: '',
    returnDate: '',
    infoNotes: '',
    productStatus: '',
    // sampleId: 0 || undefined,
    clientId: props.clientId,
    dateTime: '',
    enteredByOrOtherInfo: `${props.name}`,
    projectId: 0
  });

  const [response, setResponse] = useState({
    status: false,
    message: ''
  });

  const nameorCodeRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const quantityRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const unitSizeRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const returnDateRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const infoNotesRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const productStatusRef = useRef<HTMLSelectElement>(null);

  const accessToken = sessionStorage.getItem('accessToken');

  const getClients = useGetClients();
  const clientList = useClientList();
  const { GetSamplesByProjectId, sendReportEmail, responseText, setResponseText } =
    useSampleReportEmail(props.userEmail, props.projectId);

  // async function sampleReportEmail() {
  //   await GetSamplesByProjectId(props.projectId);
  //   sendReportEmail(props.userEmail, props.projectId);
  // }

  function clearProjectId() {
    props.clearProjectId();
  }

  function handleConfirmModalShow(fromChild: any) {
    setConfirmModalShow(fromChild);
  }

  function editItem(sampleId: any) {
    setSampleId(sampleId);
    setModalShow(true);
    props.handleSampleModalShow(false);
  }

  useEffect(() => {
    const x = { ...sampleModel };
    x.clientId = clientId;
    setSampleModel(x);
  }, [props.show, clientId, sampleModel]);

  useEffect(() => {
    if (responseText !== '' && responseText !== undefined && responseText === 'Accepted') {
      props.handleToast(responseText);
      setResponseText('');
    }
  }, [responseText]);

  useEffect(() => {
    if (saveDisabled)
      setTimeout(() => {
        setSaveDisabled(false);
      }, 2000);
  }, [saveDisabled]);

  useEffect(() => {
    if (props.projectId !== undefined) {
      setProjectId(props.projectId);
      const x = { ...sampleModel };
      x.projectId = projectId;
      setSampleModel(x);
    }
    if (props.clientId !== undefined) {
      setClientId(props.clientId);
      const x = { ...sampleModel };
      x.clientId = clientId;
      setSampleModel(x);
    }
  }, [props]);

  useEffect(() => {
    if (projectId !== undefined) {
      const x = { ...sampleModel };
      x.projectId = projectId;
      setSampleModel(x);
    }
    if (clientId !== undefined) {
      const x = { ...sampleModel };
      x.clientId = clientId;
      setSampleModel(x);
    }
  }, [projectId, clientId]);

  // // useEffect(() => {
  // //   if (response.status) {
  // //     setTimeout(() => {
  // //       // Clear the values of other input fields in a similar manner
  // //       if (nameorCodeRef.current) {
  // //         nameorCodeRef.current.value = '';
  // //       }
  // //     }, 2000);
  //     // Clear the values of other input fields in a similar manner
  //   }
  // }, [response.status]);

  useEffect(() => {
    // if (props.projectId !== undefined) {
    // getClientList();
    if (props.projectId !== undefined) {
      setProjectIdNumber(props.projectId);
      setClientId(props.clientId);
      getClientNameByProjectId(props.projectId);
      getSamplesByProjectId();
    }
    if (props.projects !== undefined) {
      setProjects(props.projects);
    }
    //}
  }, [props]);

  useEffect(() => {
    const x = { ...sampleModel };
    x.projectId = projectId;
    x.clientId = clientId;
    setSampleModel(x);
  }, [projectId, clientId]);

  function getClientNameByProjectId(projectId: any) {
    const project = projects.find(
      (p: { projectId: number; clientId: number }) => p.projectId === projectId
    );
    if (project) {
      setProjectIdNumber(project.projectId);

      setClientIdNumber(project.clientId);

      const client = clientList.find((c) => c.clientId === project.clientId);
      if (client) {
        setClientName(client.clientName);

        return client.clientName;
      } else {
        console.log('No client found');
        return null; // or any default value you choose
      }
    } else {
      console.log('no project found');
      return null; // or any default value you choose
    }
  }

  function getSamplesByProjectId() {
    axios
      .get(`${urlProjects}/GetSamplesByProjectId?projectId=${props.projectId}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then((response) => {
        setSampleList(response.data.data);
        const x = { ...sampleModel };
        x.projectId = projectId;
        setSampleModel(x);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // function getClientList() {
  //   axios
  //     .get(`${urlClients}/GetClientList`, {
  //       headers: { Authorization: `Bearer ${accessToken}` }
  //     })
  //     .then((response) => {
  //       setClientList(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  function handleEmployeeList() {
    let employeeList = [] as any;
    let t = [] as Array<any[]>;
    console.log(typeof t);
    t = projects.find((x: any) => x.projectId === projectIdNumber);
    (t as any)?.projectEmployees.map((x: any) => {
      employeeList.push(x.employeeId);
    });
    console.log(employeeList);

    if (employeeList.length > 0) {
      setConfirmModalShow(true);
    } else {
      submit();
    }
    console.log(employeeList);
  }

  function handleSampleEmail() {
    props.handleSampleModel(sampleModel);
    //props.setSampleEmail(true);
    // submit();
  }

  function submit() {
    const x = { ...sampleModel };
    x.projectId = projectId;
    x.clientId = props.clientId;
    // x.sampleId = sampleId;
    x.dateTime = new Date().toLocaleDateString('en-US');
    if (returnDateRef.current.value === '') {
      x.returnDate = '';
    }
    setSampleModel(x);
    // console.log(sampleModel);
    // getClientNameByProjectId(projectIdNumber);
    var payload = JSON.stringify(sampleModel);
    axios
      .post(`${urlProjects}/AddProjectSample`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
        }
      })
      .then((res) => {
        if (res.data.status) {
          cleanUp();
          ClearForm();
          getSamplesByProjectId();
          props.setSampleEmail(false);
          props.handleToast(res.data.message);
        } else {
          const c = { ...response };
          c.status = res.data.status;
          c.message = res.data.message;
          setResponse(c);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function cleanUp() {
    const c = { ...sampleModel };
    c.nameOrCode = '';
    c.quantity = 0;
    c.unitSize = '';
    c.returnDate = '';
    c.infoNotes = '';
    // c.productStatus = '';
    c.clientId = props.clientId;
    //c.sSContact = '';
    c.enteredByOrOtherInfo = `${props.name}`;
    //c.projectId = 0;
    setSampleModel(c);
  }

  function ClearForm() {
    setTimeout(() => {
      if (nameorCodeRef.current) {
        nameorCodeRef.current.value = '';
      }
      if (quantityRef.current) {
        quantityRef.current.value = '';
      }
      if (unitSizeRef.current) {
        unitSizeRef.current.value = '';
      }
      if (returnDateRef.current) {
        returnDateRef.current.value = '';
      }
      if (infoNotesRef.current) {
        infoNotesRef.current.value = '';
      }
      if (productStatusRef.current) {
        productStatusRef.current.value = '';
      }
    }, 0);
  }

  return (
    <>
      {/* {projectId === undefined ? ( */}
      <Modal
        {...props}
        backdrop='static'
        keyboard={false}
        size='xl'
        dialogClassName='width-90vw'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header className='primary-bg-color-sp text-white'>
          <Modal.Title id='contained-modal-title-vcenter'>Project Samples</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-10 col-lg-12'>
                <div className='mb-10'>
                  <Table responsive bordered hover size='sm'>
                    <thead>
                      <tr style={{ textAlign: 'center' }}>
                        {/* <th>Project Type</th> */}
                        <th>Name Or Code</th>
                        <th>Quantity</th>
                        <th>Unit Size</th>
                        <th>Destroy Date</th>
                        <th>Notes</th>
                        {/* <th>Product Status</th> */}
                        <th>Client</th>
                        {/* <th>SSContact</th> */}
                        <th>Entered By</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                            ref={nameorCodeRef}
                            title='NameOrCode'
                            type='text'
                            className='form-control'
                            id='NameOrCode'
                            name='NameOrCode'
                            // value={nameorCodeRef.current.value ? sampleModel.nameOrCode : ''}
                            onChange={(e) => {
                              const x = { ...sampleModel };
                              x.nameOrCode = e.target.value;
                              setSampleModel(x);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            ref={quantityRef}
                            title='Quantity'
                            type='number'
                            className='form-control'
                            id='Quantity'
                            name='Quantity'
                            // value={quantityRef.current.value ? sampleModel.quantity : ''}
                            onChange={(e) => {
                              const x = { ...sampleModel };
                              x.quantity = parseInt(e.target.value);
                              setSampleModel(x);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            ref={unitSizeRef}
                            title='UnitSize'
                            type='text'
                            className='form-control'
                            id='UnitSize'
                            name='UnitSize'
                            // value={unitSizeRef.current.value ? sampleModel.unitSize : ''}
                            onChange={(e) => {
                              const x = { ...sampleModel };
                              x.unitSize = e.target.value;
                              setSampleModel(x);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            ref={returnDateRef}
                            title='ReturnDate'
                            type='date'
                            className='form-control'
                            id='ReturnDate'
                            name='ReturnDate'
                            // value={returnDateRef.current.value ? sampleModel.returnDate : ''}
                            onChange={(e) => {
                              const x = { ...sampleModel };
                              x.returnDate = e.target.value;
                              setSampleModel(x);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            ref={infoNotesRef}
                            title='InfoNotes'
                            type='text'
                            className='form-control'
                            id='InfoNotes'
                            name='InfoNotes'
                            // value={infoNotesRef.current.value ? sampleModel.infoNotes : ''}
                            onChange={(e) => {
                              const x = { ...sampleModel };
                              x.infoNotes = e.target.value;
                              setSampleModel(x);
                            }}
                          />
                        </td>
                        <td className='text-center align-middle px-3'>
                          {clientName}
                          {/* {clientList?.find((x) => x.clientId === sampleModel.clientId) === undefined ? '' : clientList?.find((x) => x.clientId === sampleModel.clientId).clientName} */}
                        </td>
                        <td className='text-center align-middle px-3'>{props.name}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <div>
            <Button
              type='button'
              disabled={saveDisabled}
              // className='secondary-color-sp bg-light btn-click-cancel'
              variant='outline-secondary'
              title='Email Yourself a Sample Report'
              onClick={() => {
                setSaveDisabled(true);
                GetSamplesByProjectId(props.projectId);
                // sendReportEmail(props.userEmail, props.projectId);
              }}
            >
              Sample Report
            </Button>
          </div>
          <div>
            <Button
              // className='secondary-color-sp bg-light btn-click-cancel px-4 mx-1'
              className='mx-1 px-4'
              variant='outline-secondary'
              onClick={() => {
                cleanUp();
                props.onHide();
              }}
            >
              Cancel
            </Button>
            <Button
              type='button'
              onClick={() => {
                setSaveDisabled(true);
                // setTimeout(() => {
                //   setSaveDisabled(false);
                // }, 2000);
                handleEmployeeList();
                handleSampleEmail();
              }}
              disabled={saveDisabled}
              // className='primary-bg-color-sp btn-click px-4'
              className='btn primary-button-color-sp-outline primary-bg-color-sp px-4'
              // variant='primary'
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
        <Modal.Body>
          <div className='col-12'>
            <div className='row'>
              <div className='col-lg-12'>
                <Table responsive bordered hover size='sm'>
                  <thead>
                    <tr className='text-center'>
                      <th>Sample Id</th>
                      <th>Date</th>
                      {/* <th>Project Type</th> */}
                      <th>Name Or Code</th>
                      <th>Quantity</th>
                      <th>Unit Size</th>
                      <th>Destroy Date</th>
                      <th>Notes</th>
                      {/* <th>Product Status</th> */}
                      <th>Client Name</th>
                      {/* <th>SSContact</th> */}
                      <th>Entered By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sampleList?.map((value, index) => (
                      <tr key={index}>
                        <td>{value.sampleId}</td>
                        <td>
                          {value.dateTime
                            ? new Date(value.dateTime).toLocaleDateString('en-US')
                            : ''}
                        </td>
                        {/* <td>{value.projectType}</td> */}
                        {/* <td>{value.projectType}</td> */}
                        <td>{value.nameOrCode}</td>
                        <td>{value.quantity}</td>
                        <td>{value.unitSize}</td>
                        <td>
                          {value.returnDate
                            ? new Date(value.returnDate).toLocaleDateString('en-US')
                            : ''}
                        </td>

                        <td>{value.infoNotes}</td>
                        {/* <td>{value.productStatus}</td> */}
                        <td>{value.clientName}</td>
                        {/* <td>{value.ssContact}</td> */}
                        <td>{value.enteredByOrOtherInfo}</td>
                        <td className='text-center position-relative'>
                          <svg
                            role='button'
                            onClick={() => {
                              editItem(value.sampleId);
                            }}
                            className='action-btn action-btn-edit mx-2'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                          >
                            <path d='M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z' />
                          </svg>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ) : null} */}
      <EditProjectSample
        name={'Edit Project Sample'}
        clearProjectId={clearProjectId}
        getMSGraphUserList={props.getMSGraphUserList}
        employeeData={props.employeeData}
        show={modalShow}
        sampleId={sampleId}
        onHide={() => {
          setModalShow(false);
          setSampleId(undefined);
          // getProjectSampleList();
        }}
      />
      <EmailConfirm
        show={confirmModalShow}
        submit={submit}
        clientId={props.clientId}
        setClientId={setClientId}
        setProjectId={setProjectId}
        projectId={projectId}
        sendEmail={props.sendEmail}
        setShow={handleConfirmModalShow}
      />
    </>
  );
}

export default SampleModel;
