import axios from "axios";
import React, { Component } from "react";
import Chart from "react-apexcharts";
import { urlSales } from "../../endpoints";

class ReportingOverall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
          columnWidth: "15%",
          stacked: true,
          datalabels: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [new Date().toLocaleDateString()],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  getServiceTypeCount() {
    axios
      .get(`${urlSales}/GetOverAllCategoriesCount`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`}})
      .then((response) => {
        if (response.data.status === true) {
          this.setState({
            series: [
              {
                name: "Consulting",
                data: [parseInt(response.data.data.consulting)],
                color: "#00c875",
              },
              {
                name: "Consumer + Product",
                data: [parseInt(response.data.data.consumerProduct)],
                color: "#fdab3d",
              },
              {
                name: "Consumer Understanding",
                data: [parseInt(response.data.data.consumerUnderstanding)],
                color: "#ff0000",
              },
              {
                name: "Data Analysis Or Reporting",
                data: [parseInt(response.data.data.dataAnalysisOrReporting)],
                color: "#1B98F5",
              },
              {
                name: "Managed Services",
                data: [parseInt(response.data.data.managedServices)],
                color: "#120E43",
              },
              {
                name: "Product Understanding",
                data: [parseInt(response.data.data.productUnderstanding)],
                color: "#02B290",
              },
              {
                name: "Training",
                data: [parseInt(response.data.data.training)],
                color: "#EDBF69",
              },
              {
                name: "Other",
                data: [parseInt(response.data.data.other)],
                color: "#8D3DAF",
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getServiceTypeCount();
  }

  render() {
    return (
      <div className='app'>
        <div className='row'>
          <div id='chart' className=''>
            <Chart
              options={this.state.options}
              series={this.state.series}
              type='bar'
              height={350}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ReportingOverall;
