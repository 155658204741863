import React from 'react';
import { Col, Navbar, Row } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import './logoBar.css';


function LogoBar(props: any) {
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;
  const { instance } = useMsal();

    function handleLogout(instance: any) {
    localStorage.removeItem("msGraphToken");
    instance.logoutRedirect().catch((e: any) => {
      console.error(e);
    });
  }

  return (
    <>
      <div className='col-12'>
        <div className='row m-0'>
          <Navbar bg='white' className='shadow-sm' style={{minHeight: '7vh', position: 'fixed' }}>
            <Navbar.Brand>
              <img src='/assets/img/LOGO.png' alt='Spectrum' className='py-2 px-4 img-fluid' />
            </Navbar.Brand>
            <Navbar.Collapse className='justify-content-end'>
              <Navbar.Text>
                <div className='mx-4 d-flex align-items-center'>
                  {/* <img
                    src='https://images.unsplash.com/photo-1570295999919-56ceb5ecca61'
                    alt='User Profile'
                    width={50}
                    height={50}
                    className='rounded-circle mx-2 shadow-sm'
                  /> */}
                  <Col >
                  <Row>
                  <p className='mt-3 mb-0'>Welcome, {name}</p>
                  </Row>
                  <Row>
                  <a className='log-out d-flex justify-content-end w-100 small'
                   onClick={() => handleLogout(instance)}>Log Out</a>
                  {/* <a
                  data-bs-toggle='tooltip'
                  data-bs-placement='right'
                  title='Sign Out'
                  className='helpus'
                  onClick={() => handleLogout(instance)}
            /> */}
                  </Row>
                  </Col>
                </div>
              </Navbar.Text>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
}

export default LogoBar;
